import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { InputAdornment, TextField, MenuItem, FormControl, Select } from '@mui/material';
import url from "../../../../utils/services/urls.json"
import { getMethodWithToken, postMethodWithToken } from '../../../../utils/services/apis';
import format from 'date-fns/format';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FormInput from '../../../../Components/Inputs/FormInput/FormInput';
import ContinueButton from "../../../../Components/Buttons/ContinueButton/ContinueButton";
import GovHomeLogo from "../../../../assets/images/GovHomeLogo.png"
import calender from "../../../../assets/icons/calendar_month.png";
import Modal from "react-modal"
import "./ReListSubscription.scss";
import { navigateLogin } from '../../../../utils/services/common';
import enGB from 'date-fns/locale/en-GB';
import back from "../../../../assets/images/searchproperty/back_month.svg";
import fordward from "../../../../assets/images/searchproperty/ford_month.svg";
import Close from "../../../../assets/images/searchproperty/close.svg";
import ProgressBar from "@ramonak/react-progress-bar";
import { toast } from 'react-toastify';
import FavoriteSelect from '../../../FavoritedProperties/SelectPropertyType/FavoriteSelect/FavoriteSelect';

const ReListContainer = styled.div`
margin: 20px 50px 0 50px;
font-family: Proxima Nova;
`

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "700px",
        height: "230px",
    },
};

const ReListSubscription = () => {
    const navigate = useNavigate()
    const param = useParams()
    const today = new Date()
    const [subscriptionType, setSubscriptionType] = useState([])
    const [subscriptionoptionDetail, setSubscriptionoptionDetail] = useState([])
    const [oneTimeSubscription, setOneTimeSubscription] = useState([])
    const [inputPrice, setInputPrice] = useState("")
    const [depositAmount, setDepositAmount] = useState("");
    const [subscriptionValue, setSubscriptionValue] = useState("")
    const [subscriptionbutton, setSubscriptionbutton] = useState("")
    const [checkpoint, setCheckpoint] = useState(false);
    const storedDate = localStorage.getItem("date");
    const initialDate = storedDate ? new Date(storedDate) : null;
    const [selectedDate, setSelectedDate] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);
    const [isDateEmpty, setIsDateEmpty] = useState(false);
    const [showorganization, setShoworganization] = useState(false);
    const [ShowOneTimelist, setShowOneTimelist] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [openRentModal, setOpenRentModal] = useState(false)
    const [subscriptionDetail, setSubscriptionDetail] = useState({})
    const [listingActive, setListingActive] = useState("")
    const [totalListing, setTotalListing] = useState("")
    const [subscriptionValueId, setSubscriptionValueId] = useState("")
    const [activeStep, setActiveStep] = useState(0)

    const handleInputPriceChange = (event) => {
        setInputPrice(event.target.value.trimStart())
    }
    const handleAmountChange = (event) => {
        setDepositAmount(event.target.value);
    };

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const customStylesDate = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
            // width: "27vw",
            // height: "680px",
            transform: screenSize.width <= 800 ? 'translate(-50%, -50%)' : 'translate(-42%, -50%)',
            width: screenSize.width <= 800 ? "" : screenSize.width <= 1100 ? "" : "40vw",
            height: screenSize.width <= 800 ? "78vh" : screenSize.width <= 1100 ? "75vh" : screenSize.width <= 1700 ? "77vh" : "75vh",
            padding: '0px',
        },
    };

    useEffect(() => {
        getSubscriptionType();
        getSubscriptionDetails()
        getProfile()
        getSteps()
    }, [])

    const getProfile = async () => {
        await getMethodWithToken(url.userProfile).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setSubscriptionDetail(response?.data?.subscription_detail)
                setListingActive(response?.data?.subscription_detail?.listed_property)
                setTotalListing(response?.data?.subscription_detail?.total_list_property)
                setSubscriptionValueId(response?.data?.subscription_detail?.subscription_id)
            }
        })
    }

    const getSteps = async () => {
        await getMethodWithToken(url.selectlist + param.id + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                const allDetailStepSix = response?.data?.detail?.stepSix;
                if (Object.keys(allDetailStepSix).length > 0) {
                    setInputPrice(allDetailStepSix?.price)
                    setDepositAmount(allDetailStepSix?.deposit_amount)
                    if (allDetailStepSix?.per_month) {
                        setSubscriptionValue("Per Month")
                    } else {
                        setSubscriptionValue("Per Day")
                    }
                    setCheckpoint(allDetailStepSix?.is_negotiable)
                    setShoworganization(allDetailStepSix?.show_this_to_myorgnization)
                    setSelectedDate(allDetailStepSix && new Date(allDetailStepSix?.propertyAvailfrom))
                }
                setActiveStep(response?.data?.detail?.stepsCompleted)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }


    const getSubscriptionType = async () => {
        await getMethodWithToken(url.selectList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                response?.data.map((item) => {
                    if (item?.[1] === "subscription_term") {
                        setSubscriptionType(item?.[0])
                    }
                })
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    const getSubscriptionDetails = async (value = false) => {
        let urls
        if (value) {
            urls = url.subscriptionList + `?is_one_time_listing=True`
        } else {
            urls = url.subscriptionList
        }
        await getMethodWithToken(urls).then((response) => {
            if (response.status === 200 || response.status === 201) {
                // let changeSubscriptionList = response?.data.slice(0).reverse().map((val) => {
                //     return val;
                // }
                // );
                if (value) {
                    let changeSubscriptionList = response?.data.slice(0).reverse().map((val) => {
                        return val;
                    }
                    );
                    setOneTimeSubscription(changeSubscriptionList)
                } else {
                    setSubscriptionoptionDetail(response?.data)
                }
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const handleRentProperty = () => {
        setOpenRentModal(true)
    }
    const handleCloseRentClick = () => {
        setOpenRentModal(false)
    }

    const handleSubscriptionChange = (event) => {
        console.log(event)
        setSubscriptionValue(event?.target?.value)
    }
    const handleNegotiation = () => {
        setCheckpoint(!checkpoint);
    };
    const handleChange = (date) => {
        setSelectedDate(date);
        localStorage.setItem("date", date.toISOString());
        setShowCalendar(false);
    };
    const handleChangeDate = (date) => {
        setSelectedDate(date);
        // localStorage.setItem("date", date?.toISOString());
        setIsDateEmpty(false);
    };
    const handleBlur = () => {
        if (!selectedDate && selectedDate !== '') {
            setIsDateEmpty(true);
        } else {
            setIsDateEmpty(false);
        }
    };
    const handleMyorgnization = () => {
        setShoworganization(!showorganization);
    };
    const handlesubscription = () => {
        setShowOneTimelist((prevValue) => !prevValue);
        if (!ShowOneTimelist) {
            getSubscriptionDetails(true)
        } else {
            getSubscriptionDetails(false)
        }
    }
    const handlesubscriptionbutton = (e) => {
        setSubscriptionbutton(e.id)
    }
    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }
    const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
        const monthYear = date.toLocaleDateString(undefined, {
            month: 'long',
            year: 'numeric',
        });

        const days = ['Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat', 'Sun'];

        return (
            <div className="custom-header">
                <div className='date_month'>
                    <p className='monthyear'>{monthYear}</p>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <img src={back} onClick={decreaseMonth} alt='back' style={{ cursor: "pointer" }} />
                        <img src={fordward} onClick={increaseMonth} alt='fordward' style={{ cursor: "pointer" }} />
                    </div>

                </div>
                <div style={{ fontSize: '20px', justifyContent: 'space-evenly', display: 'flex', fontFamily: 'Proxima_nove_reg', paddingBottom: '20px' }}>
                    {days.map((day, index) => (
                        <span key={index}>{day}</span>
                    ))}
                </div>
            </div>
        );
    };

    const handleOpenModal = () => {
        setOpenModal(true)
    }
    const handleCloseBack = () => {
        setOpenModal(false)
    }
    // const handleRelist = () => {
    //     navigate("/Dashboard/MyListings/review")
    // }

    const handleRelist = async () => {
        const perMonthValue = subscriptionValue === 'Per Month' ? true : false;
        const perDayValue = subscriptionValue === 'Per Day' ? true : false
        let body = {
            "masterSubscrption": (Object.keys(subscriptionDetail).length > 0) ? subscriptionValueId : subscriptionbutton,
            "price": inputPrice,
            "per_month": perMonthValue,
            "per_day": perDayValue,
            "is_negotiable": !!checkpoint,
            "show_this_to_myorgnization": JSON.parse(showorganization),
            "expected_deposit_amount": depositAmount,
            "propertyAvailfrom": selectedDate,
            "deposit_amount": depositAmount,
        };
        if (selectedDate !== null) {
            await postMethodWithToken(url.setPrice + param.id + '/', body).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    navigate(`/Dashboard/FavoritePropertieList/Review/${response.data.propertyId}`);
                }

            });
        } else {
            toast.error("Please Select The Property Date")
        }


    }
    return (
        <>
            <ReListContainer>
                {/* <div>
                    <img src={GovHomeLogo} alt="" style={{ width: "100px", height: "75px" }} />
                </div> */}
                <Row style={{ margin: "0" }}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                        <FavoriteSelect activeStep={activeStep} value={5} />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={7} xxl={7}>
                        <div className="relist-section" >
                            <div className="price-heading"> Set price</div>
                            <div className="price-description" >You can choose to change the price for your property or continue with previously set price</div>
                            <div className="d-flex justify-content-center mt-2">
                                <div style={{ display: 'flex', borderBottom: '2px solid #EAEAEA', alignItems: 'baseline' }}>
                                    <TextField
                                        variant="standard"
                                        value={inputPrice}
                                        onChange={(e) => handleInputPriceChange(e)}
                                        InputProps={{
                                            disableUnderline: true,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <p style={{ marginTop: "16px", marginRight: "-5px", fontSize: '32px', fontWeight: '600', color: 'black' }} >$</p>
                                                </InputAdornment>
                                            ),
                                            style: { fontSize: '32px', fontWeight: '600', color: 'black' }
                                        }}
                                        sx={{ width: "150px" }}
                                    />
                                    {console.log(subscriptionValue)}
                                    <FormControl variant="standard" >
                                        <Select value={subscriptionValue} onChange={handleSubscriptionChange} disableUnderline style={{ fontSize: '20px' }}>
                                            {subscriptionType && subscriptionType.length > 0 && subscriptionType.map((item, index) => (
                                                <MenuItem key={item?.id} value={item?.name}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className=" d-flex justify-content-center mt-3">
                                <input type="checkbox" checked={checkpoint} onChange={handleNegotiation} style={{ border: '2px solid black', borderRadius: '0', width: "18px", height: "18px", marginTop: "2px" }} />
                                <label className="negotiable-heading">I want to keep this price negotiable</label>
                            </div>
                            <form style={{ marginTop: '30px', display: "flex", justifyContent: "center" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            value={depositAmount}
                                            label="Expected Deposit Amount"
                                            onChange={(e) => handleAmountChange(e)}
                                            className="DepositAmount-value"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <p style={{ marginTop: "16px", fontSize: '28px', fontWeight: '500', color: 'black' }} >$</p>
                                                    </InputAdornment>
                                                ),
                                                style: { fontSize: '20px', fontWeight: '400', color: 'black', marginTop: "10px" }
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className='DepositAmount-rent' style={{ marginTop: "-6px" }}>
                                        <TextField
                                            onClick={handleRentProperty}
                                            fullWidth
                                            variant="standard"
                                            value={selectedDate ? format(selectedDate, 'd MMM yyyy') : ''}
                                            label="Property available from"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <img src={calender} alt='calender' style={{ marginLeft: "-25px" }} />
                                                    </InputAdornment>
                                                ),
                                            }}

                                        />
                                    </Col>
                                </Row>

                                <Modal style={customStylesDate} isOpen={openRentModal} overlayClassName="myoverlay" >
                                    <div className="modal-container" >
                                        <div style={{ borderBottom: " 1px solid #EAEAEA", marginBottom: "10px" }} >
                                            <div className="modal-main-heading  calender_header" > <p style={{ fontSize: '24px', fontWeight: '600', fontFamily: 'Proxima_nova_reg', marginBottom: "0px" }}>Select date range</p>
                                                <img src={Close} onClick={handleCloseRentClick} style={{ cursor: 'pointer' }} alt='close' /></div>
                                            <div style={{ display: "flex" }}>
                                                <div className='inputField_calender'>
                                                    <FormInput
                                                        type='text' name={'Start Date'} id={'name'}
                                                        value={selectedDate ? selectedDate?.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }) : ''}
                                                        readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='main_calender'>
                                            <DatePicker selected={selectedDate} onChange={handleChangeDate} inline dateFormat="d MMM yyyy" dayClassName={(date) => {
                                                let classes = '';
                                                if (selectedDate) {
                                                    if (date.getTime() === new Date(selectedDate).getTime()) {
                                                        classes += 'start-date';
                                                    }
                                                }
                                                return classes;
                                            }} renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                <CustomHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />)}
                                                locale={enGB}
                                                formatWeekDayShort={(locale, dayOfWeek) => {
                                                    const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
                                                    return days[dayOfWeek];
                                                }} minDate={today}
                                            />
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "end", padding: "20px 32px 20px 32px ", borderTop: "1px solid #EAEAEA", alignItems: "baseline" }}>
                                            <div>
                                                <button className='continue_button' onClick={handleCloseRentClick} style={{ width: '217px', background: '#0075CA' }} >Done</button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </form>
                            <div className="checkbox mt-4 d-flex justify-content-center">
                                <input type="checkbox" id="verifyOrganization" checked={showorganization} onChange={handleMyorgnization} style={{ border: "2px solid black", borderRadius: "0", width: "18px", height: "18px", marginTop: "2px" }} />
                                <label className="negotiable-heading">Show this property only to people in my organisation</label>
                            </div>
                            {Object.keys(subscriptionDetail)?.length > 0 ?
                                <>
                                    <div className="d-flex justify-content-center mt-3" style={{ fontSize: "20px", fontWeight: 600, fontFamily: "Proxima_nova_medium" }}>Current Plan</div>
                                    <div className="d-flex justify-content-center mt-1" style={{ fontSize: "22px", fontWeight: 600, color: "#0075CA" }}>{subscriptionDetail?.Subscription_type?.length > 0 && getUpparCaseName(subscriptionDetail?.Subscription_type)}</div>

                                    <div className="d-flex justify-content-center mt-2" >
                                        <div className="d-flex justify-content-between" style={{ width: "580px" }}>
                                            <div style={{ fontSize: "16px", fontWeight: 600 }}>Listings</div>
                                            <div style={{ fontSize: "18px", fontWeight: 700 }}>{subscriptionDetail?.remaining_list}<span>/{subscriptionDetail?.total_list_property}</span></div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center mt-2" >
                                        <ProgressBar completed={listingActive / totalListing * 100} bgColor="#0075CA" isLabelVisible={false} width="580px" height="10px" />
                                    </div>
                                </>
                                :
                                <div>
                                    {ShowOneTimelist === false ?
                                        <>
                                            <div className="subscription-heading">Select a Subscription Package</div>
                                            <div className="negotiable-heading mt-2" style={{ color: "#919CA5" }}>Any unused listing will be carried over to the next month</div>
                                            <div className="row subscription-relist" >
                                                {subscriptionoptionDetail && subscriptionoptionDetail.map((item, index) => {
                                                    return (
                                                        (
                                                            <>
                                                                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 subscriptionBox">
                                                                    <div className="item-heading">{item?.name?.length > 0 && getUpparCaseName(item?.name)} <span className="item-below-description">{item?.name === "short term" ? "(Below 30 days)" : item?.name === "mid term" ? "(30+ days)" : "(Yearly)"}</span></div>
                                                                    <div className="subscribe-button" onClick={() => handlesubscriptionbutton(item)} style={{ border: subscriptionbutton === item.id ? '3px solid #0075CA' : '2px solid #505050' }}><span className='amountterm'>{item?.pricePerListing} </span><span className="listing-amount"> for listing</span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    )
                                                })}
                                            </div>
                                            <div className="footer-container">
                                                <div className="other-heading">OR</div>
                                                <div className="footer-link" onClick={handlesubscription}>See plans for One time Listing</div>
                                            </div>
                                        </>
                                        :
                                        <div>
                                            <div className="subscription-heading">Select One Time Listing Package</div>
                                            <div className="row subscription-relist-onetime" >
                                                {oneTimeSubscription && oneTimeSubscription.length > 0 && oneTimeSubscription.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 subscription-box-one-time">
                                                                <div className="item-heading">{item?.name} <span className="item-below-description">{item?.name === "One Time Short Term" ? "(Below 30 days)" : "(30+ days)"}</span></div>
                                                                <div className="subscribe-button" onClick={() => handlesubscriptionbutton(item)} style={{ border: subscriptionbutton === item.id ? '3px solid #0075CA' : '2px solid #505050' }}>
                                                                    <div className="amount-term">Free <span className="listing-amount">listing for 3 days</span></div>
                                                                    <div className="amount-term-listing"> {item?.pricePerListing}<span className="listing-amount">of daily rate</span><span className="listing-amount"> per listing</span></div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                            <div className="one-time-description">*If rental payment is processed through website, 3% to renter and 5% to lister of total price</div>
                                            <div className="footer-container">
                                                <div className="other-heading">OR</div>
                                                <div className="footer-link" onClick={handlesubscription}>See Subscription Packages</div>
                                            </div>
                                        </div>

                                    }
                                </div>
                            }

                        </div>

                    </Col>
                </Row>
            </ReListContainer>
            <div className="d-flex justify-content-between align-items-end" style={{ margin: "30px 0 20px 20px", alignItems: "center" }} >
                <div>
                    <ContinueButton className="back-list" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={() => handleOpenModal()} fontSize={"20px"} fontWeight={"400"} />
                </div>
                <div className="d-flex justify-content-end">
                    {/* <div style={{ fontSize: "20px", cursor: "pointer", paddingRight: "70px", display: "flex", alignItems: "center" }} onClick={() => handleSaveExit()}>Save & Exit</div> */}
                    <div style={{ marginRight: "30px", display: "flex", alignItems: "center" }}>
                        <ContinueButton text="Save & Exit" color={'#fff'} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                    </div>
                    <div style={{ marginRight: "50px" }}>
                        <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => handleRelist()} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                    </div>
                </div>
            </div>

            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
                <div className="d-flex justify-content-center">
                    <div className="back-heading">Are you sure you want to back?</div>
                </div>
                <div className="d-flex justify-content-center" >
                    <div style={{ marginTop: "10px" }}>All the changes you will made will be discarded</div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="d-flex" style={{ marginTop: "50px" }}>
                        <div className="stay-heading" onClick={() => handleCloseBack()}>Stay on this page</div>
                        <div>
                            <ContinueButton text="Go back" color={'#fff'} onClick={() => navigate(-1)} width={"210px"} borderRadius={"4px"} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ReListSubscription
