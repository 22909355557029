import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import Navbar from '../../../../../Components/commonComponents/navbar/Navbar';
import ArrowBack from "../../../../../assets/images/favoriteProperty/arrow-back.svg";
import BedroomImage from "../../../../../assets/images/favoriteProperty/bedroom.svg";
import BathroomImage from "../../../../../assets/images/favoriteProperty/bathroom.svg";
import ParkingImage from "../../../../../assets/images/favoriteProperty/parking.svg";
import Home from "../../../../../assets/images/favoriteProperty/home.svg";
import Clock from "../../../../../assets/images/favoriteProperty/clock.svg";
import Furnishing from "../../../../../assets/images/favoriteProperty/furnishing.svg";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import url from "../../../../../utils/services/urls.json"
import { getMethodWithToken, postMethodWithToken } from '../../../../../utils/services/apis';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { getUserEmail, navigateLogin } from '../../../../../utils/services/common';

const PaymentRentConatiiner = styled.div`
background: rgb(242, 242, 247);
height: auto;
font-family: Proxima_nova_reg;
`

const CustomImage = styled.img`
background:url('${props => props.image}');
height: 163px;
width:100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;

`

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: 163,
        display: 'block',
        maxWidth: 300,
        overflow: 'hidden',
        width: '100%',
    },
}));

const PaymentRent = () => {
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    console.log(location)
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const [carouselImages, setCarouselImages] = useState([])
    const [couponCode, setCouponCode] = useState("")
    const [subscriptionPackage, setSubscriptionPackage] = useState("")
    const [billingPrice, setBillingPrice] = useState("")
    const [subscriptionMontly, setSubscriptionMontly] = useState("")
    const [reviewDetail, setReviewDetail] = useState([])
    const maxSteps = carouselImages?.length;
    const paymentRentPrice = location?.state?.rentPrice

    const handleCouponCodeChanges = (event) => {
        setCouponCode(event.target.value)
    }
    const handlePackageChanges = (event) => {
        setSubscriptionPackage(event.target.value)
    }
    const handleBillingChanges = (event) => {
        setBillingPrice(event.target.value)
    }
    const handleSubscriptionMonthChanges = (event) => {
        setSubscriptionMontly(event.target.value)
    }
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };
    useEffect(() => {
        getRentPropertyDetail()
    }, [])
    const getRentPropertyDetail = async () => {
        await getMethodWithToken(url.rentProperty + params.id + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                setReviewDetail(response?.data?.detail)
                setCarouselImages(response?.data?.detail?.stepFive)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }

    const paypalOptions = {
        // 'client-id': 'AbysTaXRkDPWv-JqxyNvz6AFQzvCYiq-Mftc2y2x3pJ3XPq6dj5hZL2kv3tnt1ZK0oBe98OObknnagJj',
        'client-id': "AcMXdFC-3VdF1HtFOJY8nEoF0CRuIcdMoVTrajLnwaR8nQQK6ze_ueDxevuKovSAM80lWUj283mpnCCl",
        'disable-funding': 'card',
        currency: 'USD',
    };
    const handleVerifyPayment = async (verifyValue) => {
        let body = {
            "propertyMaster": params.id,
            "transaction_status": verifyValue?.status,
            "payment_id": verifyValue?.id,
            "email_address": getUserEmail(),
            "payer_id": verifyValue?.payer?.payer_id
        }
        await postMethodWithToken(url.PostRentPayment, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.success)
                if (params.search) {
                    // navigate("/Dashboard/SearchList")
                    navigate("/Dashboard/SearchList"
                        // state: {
                        //     data: location?.state?.data,
                        //     dataone: location?.state?.dataone,
                        //     datatwo: location?.state?.datatwo,
                        //     zip_code: location?.state?.zip_code,
                        //     startDate: location?.state?.startDate,
                        //     endDate: location?.state?.endDate,
                        //     minPrice: location?.state?.minPrice,
                        //     maxPrice: location?.state?.maxPrice,
                        //     per_month: (location?.state?.per_month),
                        //     per_day: (location?.state?.per_day),
                        //     short_term:(location?.state?.short_term),
                        //     mid_term:(location?.state?.mid_term),
                        //     long_term:(location?.state?.long_term)
                        // }
                    )
                    if (location?.state?.data || location?.state?.dataone || location?.state?.datatwo || location?.state?.city || location?.state?.startDate || location?.state?.endDate || location?.state?.minPrice || (location?.state?.per_month) || (location?.state?.per_day || location?.state?.short_term || location?.state?.mid_term || location?.state?.long_term)) {
                        navigate("/Dashboard/SearchList", {
                            state: {
                                data: location?.state?.data,
                                dataone: location?.state?.dataone,
                                datatwo: location?.state?.datatwo,
                                city: location?.state?.city,
                                startDate: location?.state?.startDate,
                                endDate: location?.state?.endDate,
                                minPrice: location?.state?.minPrice,
                                maxPrice: location?.state?.maxPrice,
                                per_month: (location?.state?.per_month),
                                per_day: (location?.state?.per_day),
                                short_term: (location?.state?.short_term),
                                mid_term: (location?.state?.mid_term),
                                long_term: (location?.state?.long_term)
                            }
                        })
                    }
                    else if (location?.state?.collectionSlug) {
                        navigate("/Dashboard/SearchList", {
                            state: {
                                collectionSlug: location?.state?.collectionSlug
                            }
                        })
                    }

                } else if (params.manager) {
                    navigate("/Dashboard/ListingManagers")
                } else if (params.managerDetail) {
                    navigate("/Dashboard/ListingManagers/AddManager")
                } else if (params.tenantDetail) {
                    navigate("/Dashboard/TenantManagement")
                } else if (params.payment) {
                    navigate("/Dashboard/homePage")
                } else if (location?.state?.managerDetail) {
                    navigate("/Dashboard/ListingManagers/AddManager")
                }
                else {
                    navigate("/Dashboard/FavoritedProperties")
                }
            }
        })
    }
    const [myContactModal, setMyContactModal] = useState(false)

    const handleGetOpenModal = (data) => {
        setMyContactModal(data)
    }
    console.log(myContactModal)
    const priceViewDetail = location?.state?.deposit && ((Number(location?.state?.price) + Number(location?.state?.price) * 5 / 100) + Number(location?.state?.deposit))
    return (
        <div style={{ overflowX: 'hidden', height: "100vh", background: "rgb(242, 242, 247)" }} className='searchdetail'>
            <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
                <Navbar getOpenModal={(val) => handleGetOpenModal(val)} />
            </div>
            <PaymentRentConatiiner>
                <Row style={{ padding: "80px 25px 20px 25px" }}>
                    <Col xl={1} xxl={1}>
                        <div className="arrow-box-payment" onClick={() => navigate(-1)}>
                            <img src={ArrowBack} alt="" />
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                        <div className='listing-payment'>
                            <div className="listing-detail">
                                <div className="arrow-back-payment" onClick={() => navigate(-1)}>
                                    <img src={ArrowBack} alt="" style={{ width: "27px", paddingRight: "10px", cursor: "pointer" }} />
                                    <div style={{ fontSize: "22px", fontWeight: 600 }} >Listing Details</div>
                                </div>
                                <div className="listing-main-heading" >Listing Details</div>
                                {/* <div className="d-flex" style={{ marginTop: "7px" }} >
                                    <div style={{ fontSize: "16px", marginTop: "5px" }}><span style={{ fontWeight: 600 }}>{reviewDetail?.stepTwo?.bedroomCount} Bedroom Flat</span> in <span style={{ fontWeight: 600 }}>{reviewDetail?.stepThree?.locality}</span> for <span style={{ fontWeight: 600 }}>Rent</span> in <span style={{ fontWeight: 600 }}>{reviewDetail?.stepThree?.direction}</span></div>
                                </div> */}
                                {/* <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px" }}>{reviewDetail?.stepThree?.locality}</div> */}
                                <Row>
                                    <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                                        <div className={classes.root}>
                                            <AutoPlaySwipeableViews
                                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                index={activeStep}
                                                onChangeIndex={handleStepChange}
                                                enableMouseEvents
                                            >
                                                {carouselImages.map((step, index) => {
                                                    return (
                                                        <>
                                                            <div>
                                                                {/* <img className={classes.img} src={step?.file} alt={step.label} /> */}
                                                                <CustomImage className={classes.img} src={step?.file} alt={step.label} />
                                                            </div>

                                                        </>
                                                    )
                                                })}
                                            </AutoPlaySwipeableViews>
                                            <MobileStepper
                                                position="static"
                                                variant="text"
                                                nextButton={
                                                    <Button size="small" style={{ color: "white" }} onClick={handleNext} disabled={activeStep === maxSteps - 1}>

                                                        {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                    </Button>
                                                }
                                                backButton={
                                                    <Button size="small" style={{ color: "white" }} onClick={handleBack} disabled={activeStep === 0}>
                                                        {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                    </Button>
                                                }
                                            />
                                        </div>
                                        {/* <div className="rental-heading" >Rental Duration : <span style={{ color: "black", fontWeight: 600, fontSize: "16px" }}>Long Time</span> </div> */}

                                        {/* <div class="d-flex justify-content-center rent-box">
                                            <div>
                                                <div className="rental-price">${reviewDetail?.stepSix?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{reviewDetail?.stepSix?.per_month === true ? "/month" : "/day"}</span></div>
                                                <div className="rent-heading" >Rent</div>
                                            </div>
                                            <div><hr style={{ border: "1px solid black", height: "40px", marginLeft: "10px", marginTop: "20px" }} /></div>
                                            <div>
                                                <div className="deposit-price" style={{ marginLeft: "10px" }}>$<span>{reviewDetail?.stepSix?.deposit_amount}</span></div>
                                                <div className="rent-heading" style={{ marginLeft: "25px" }}>Deposit</div>
                                            </div>
                                        </div>
                                        <div className="deposit-type">
                                            <div className="d-flex">
                                                <div className="rent-heading" >Rent : </div>
                                                <div className="rental-price">$ {reviewDetail?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{reviewDetail?.per_month === true ? "/month" : "/day"}</span></div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="rent-heading">Deposit : </div>
                                                <div className="deposit-price">$ <span>{reviewDetail?.deposit_amount}</span></div>

                                            </div>
                                        </div> */}
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                        <div className="listing-details-card">
                                            <div className="d-flex " >
                                                <div className="listing-user-heading" >${reviewDetail?.stepSix?.price}</div>
                                                <div className="listing-user-month"> / {reviewDetail?.stepSix?.per_day === true ? "day" : "month"}</div>
                                            </div>
                                            {/* <div style={{ fontSize: "14px" }}><span style={{ fontWeight: 600 }}>{reviewDetail?.stepTwo?.bedroomCount} Bedroom Flat</span> in <span style={{ fontWeight: 600 }}>{reviewDetail?.stepThree?.locality}</span> for <span style={{ fontWeight: 600 }}>Rent</span> in <span style={{ fontWeight: 600 }}>{reviewDetail?.stepThree?.direction}</span></div> */}
                                            <div style={{ color: "#707070", fontSize: "14px", fontWeight: 500, marginTop: "2px" }}>{reviewDetail?.stepThree?.locality}</div>
                                            <Row style={{ borderBottom: "1px solid #B7B7B7", paddingBottom: "5px", paddingTop: "8px" }}>
                                                <Col md={6} lg={4} xl={4} xxl={4} className='d-flex'>
                                                    <div><img src={BedroomImage} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                    <div className="categroy-heading-type">Bedroom</div>
                                                    <div className="category-value">{reviewDetail?.stepTwo?.bedroomCount}</div>
                                                </Col>
                                                <Col md={6} lg={4} xl={4} xxl={4} className='d-flex'>
                                                    <div ><img src={BathroomImage} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                    <div className="categroy-heading-type">Bathroom</div>
                                                    <div className="category-value">{reviewDetail?.stepOne?.propertyType?.name === "Room" ? reviewDetail?.stepTwo?.room_sharing?.name : reviewDetail?.stepTwo?.bathroomCount}</div>
                                                </Col>
                                                <Col md={6} lg={4} xl={4} xxl={4} className='d-flex'>
                                                    <div><img src={ParkingImage} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                    <div className="categroy-heading-type">Parking</div>
                                                    <div className="category-value">{reviewDetail?.stepTwo?.parkingType?.name?.length > 0 && getUpparCaseName(reviewDetail?.stepTwo?.parkingType?.name)}</div>
                                                </Col>
                                            </Row>
                                            <div>
                                                <Row >
                                                    <Col md={4} lg={4} xl={4} xxl={4} style={{ borderRight: "1px solid #B7B7B7" }}>
                                                        <div className="d-flex" style={{ marginTop: "10px" }}>
                                                            <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                            <div className="categroy-heading">Available from</div>
                                                        </div>
                                                        <div className="select-value-heading">{moment(reviewDetail?.stepSix?.propertyAvailfrom).format("YYYY-MM-DD")}</div>
                                                    </Col>
                                                    <Col md={4} lg={4} xl={4} xxl={4} style={{ borderRight: "1px solid #B7B7B7" }}>
                                                        <div className="d-flex" style={{ marginTop: "10px" }} >
                                                            <div><img src={Home} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                            <div className="categroy-heading">Property Type</div>
                                                        </div>
                                                        <div className="select-value-heading">{reviewDetail?.stepOne?.propertyType?.name}</div>
                                                    </Col>
                                                    <Col md={4} lg={4} xl={4} xxl={4}>
                                                        <div className="d-flex" style={{ marginTop: "10px" }} >
                                                            <div><img src={Furnishing} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                            <div className="categroy-heading">Furnishing</div>
                                                        </div>
                                                        <div className="select-value-heading">{reviewDetail?.stepTwo?.furnishType?.name?.length > 0 && getUpparCaseName(reviewDetail?.stepTwo?.furnishType?.name)}</div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div style={{ fontSize: "22px", fontWeight: 600, borderBottom: "1.8px solid #e1e3e1", marginTop: "30px" }}>Payment details</div>
                                <div className="payment-card">
                                    {/* <FormInput required={true} name={'Coupon Code'} style={{ width: "430px" }} value={couponCode} onChange={(e) => handleCouponCodeChanges(e)} showText={"Apply Coupon"} /> */}
                                    <Row>
                                        {/* <Col lg={9} className="mt-3">
                                        <FormControl sx={{ minWidth: 250 }}>
                                            <InputLabel id="demo-simple-select-label">Subscription Package</InputLabel>
                                            <Select
                                                value={subscriptionPackage}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                onChange={(e) => handlePackageChanges(e)}
                                                sx={{
                                                    boxShadow: "none",
                                                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                    {
                                                        border: 0,
                                                    },
                                                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                    {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <MenuItem value={10}>Mid to Long Term 10 listings</MenuItem>
                                                <MenuItem value={20}>Mid to Long Term 5 listings</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col lg={1}><div className="price-payment" style={{ marginTop: "30px" }}>$149.50</div></Col>
                                    <Row>
                                        <div className="d-flex">
                                            <FormControl variant="standard" sx={{ minWidth: 250 }}>
                                                <InputLabel id="demo-simple-select-standard-label">Billing Frequency</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={billingPrice}
                                                    onChange={(e) => handleBillingChanges(e)}
                                                >
                                                    <MenuItem value={"Once"}>Once</MenuItem>
                                                    <MenuItem value={"Monthly"}>Monthly</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {billingPrice === "Monthly" &&
                                                <FormControl variant="standard" sx={{ minWidth: 250, marginLeft: "15px" }}>
                                                    <InputLabel id="demo-simple-select-standard-label">Subscription Duration</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        value={subscriptionMontly}
                                                        onChange={(e) => handleSubscriptionMonthChanges(e)}
                                                    >
                                                        <MenuItem value={10}>1 Months</MenuItem>
                                                        <MenuItem value={20}>2 Months</MenuItem>
                                                        <MenuItem value={30}>3 Months</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            }
                                        </div>
                                        <p className="mt-1" style={{ fontSize: "14px", fontWeight: 400, color: "#2E2E2E" }}>{billingPrice === "" ? "" : billingPrice === "Once" ? "*You can utilise the unused listings after the subscription ends" : "*Unused listings will be carried forward to next month"}</p>

                                    </Row>
                                    <Col lg={9}><div className="payment-descrption mt-2">One time listing for Short Term (10% of Daily rental rate)</div></Col>
                                    <Col lg={1}><div className="price-payment mt-2">$5</div></Col> */}
                                        <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9}><div className="payment-descrption mt-4">Property Price</div></Col>
                                        <Col xs={12} sm={12} md={1} lg={1} xl={1} xxl={1}><div className="price-payment mt-4">${location?.state?.deposit ? (Number(location?.state?.deposit) + Number(location?.state?.price)) : (Number(reviewDetail?.stepSix?.price) + Number(reviewDetail?.stepSix?.deposit_amount))}</div></Col>
                                        <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9}><div className="payment-descrption mt-4 mb-3">Processing Fee</div></Col>
                                        <Col xs={12} sm={12} md={1} lg={1} xl={1} xxl={1}><div className="price-payment mt-4 mb-3">${location?.state?.deposit ? Number(location?.state?.price) * 5 / 100 : Number(reviewDetail?.stepSix?.price) * 5 / 100}</div></Col>
                                        <hr />
                                        <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9}><div className="payment-descrption mt-1">Total payable amount</div></Col>
                                        <Col xs={12} sm={12} md={1} lg={1} xl={1} xxl={1}><div className="price-payment mt-1">${location?.state?.deposit ? priceViewDetail : paymentRentPrice}</div></Col>
                                    </Row>
                                </div>
                            </div>

                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={5} xxl={5}>
                        <div className="payment-detail">
                            <div className="payment-heading" >Checkout</div>
                            <div className="d-flex justify-content-center">
                                <div className="method-heading mt-4">Payment Method</div>
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                                {/* <div className="card-container">
                                    <div className="d-flex justify-content-center"><img src={CardImage} /></div>
                                    <div className="d-flex justify-content-center credit-heading">Credit Card</div>
                                </div> */}

                                <div style={{ marginLeft: "10px" }}>
                                    <PayPalScriptProvider options={paypalOptions} style={{ minHeight: '40px' }}>
                                        <PayPalButtons
                                            createOrder={(data, actions) => {
                                                return actions.order.create({
                                                    "intent": "CAPTURE",
                                                    purchase_units: [
                                                        {
                                                            amount: {
                                                                value: location?.state?.deposit ? priceViewDetail : paymentRentPrice
                                                            },
                                                        },
                                                    ]
                                                });
                                            }}
                                            style={{
                                                color: "white",
                                                height: 55
                                            }}
                                            onApprove={async (data, actions) => {
                                                const order = await actions.order.capture();
                                                if (order.status === "COMPLETED") {
                                                    handleVerifyPayment(order)
                                                }
                                            }
                                            }
                                        />
                                    </PayPalScriptProvider>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </PaymentRentConatiiner>
        </div>
    )
}

export default PaymentRent
