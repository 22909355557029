import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import GovHome from '../../assets/images/GovHomeLogo.png'
import '../Registration/Registration.scss'
import badge from '../../assets/icons/badge.png'
import call from '../../assets/icons/call.png'
import mail from '../../assets/icons/mail.png'
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';
import ContinueButton from '../../Components/Buttons/ContinueButton/ContinueButton';
import FormInput from '../../Components/Inputs/FormInput/FormInput';
import BackButton from '../../Components/Buttons/BackButton/BackButton';
import { postMethod } from '../../utils/services/apis';
import { getMethod } from '../../utils/services/apis';
import url from '../../utils/services/urls.json';
import Toastmessage from '../../Components/Snackbar/Toastmessage';
import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from "../Registration/Dropdown";
import ArrowRight from "@mui/icons-material/ArrowRight";
import Button from "@material-ui/core/Button";
import dropdownicon from '../../assets/icons/arrow_drop_down.svg'

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+1 (#00) 000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props?.name, value } })}
            overwrite
        />
    );
});

TextMaskCustom.propTypes = {
    onChange: PropTypes.func.isRequired,
};

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(telephone) => {
                onChange({
                    target: {
                        name: props?.name,
                        value: telephone.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            prefix="$"
        />
    );
});

NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
const Registration = () => {
    const navigate = useNavigate();
    const [continueButton, setcontinueButton] = useState(false);
    const params = useParams();
    const [organisation, setOrganisation] = useState('');
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [dataMsg, setDataMsg] = useState({
        type: "",
        message: ""
    });
    const [organisationList, setOrganisationList] = useState([]);
    const [orgnizationSelect, setOrganisationSelect] = useState("")
    const [organisationid, setOrganisationid] = useState();

    useEffect(() => {
        getOrganisation()
    }, []);

    const getOrganisation = async () => {
        await getMethod(url.userOrganisation).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setOrganisationList(response.data.response)
            }
        })
    }

    const [telephone, setTelephone] = React.useState({
        Telephone: '',
    });
    useEffect(() => {
        console.log(typeof(params.value))
        let validationEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
        if (params.value === 'federalEmployee') {
            if (name.length && validationEmail && !error && telephone.Telephone.length == 17 && organisation.length) {
                setcontinueButton(true)
            } else {
                setcontinueButton(false)
            }
        } else if (params.value === 'propertyManager') {
            if (name.length && validationEmail && !error && telephone.Telephone.length == 17) {
                setcontinueButton(true)
            } else {
                setcontinueButton(false)
            }
        }
    }, [name, email, telephone, organisation, error])

    const getFormData = (event) => {
        event.preventDefault()
        const payloadFederal = {
            name: name,
            email: email,
            contact_number: telephone.Telephone,
            organisation: orgnizationSelect.id,
            role: "user_role_federal"
        }
        
        const payloadProperty = {
            name: name,
            email: email,
            contact_number: telephone.Telephone,
            role: "user_role_property_manager",
            companyName: "ABC",
            realEstateLicence: 1,
        };


        let validationEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
        if (params.value == 'federalEmployee') {
            if (name.length && validationEmail && !error && telephone.Telephone.length == 17 && organisation.length) {
                register(payloadFederal)
            }
        }
        else if (params.value == 'propertyManager') {
            if (name.length && validationEmail && !error && telephone.Telephone.length == 17) {
                register(payloadProperty)
            }
        }
    };
    const handleTelephone = (event) => {
        setTelephone({
            ...telephone,
            ['Telephone']: event.target.value,
        });
    };
    const handleEmail = (e) => {
        const { value } = e.target;
        setEmail(value);
    };
    const isValidName = (input) => {
        const regex = /^[a-zA-Z\s_]+$/;
        return regex.test(input);
    };
    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);
        setNameError(!isValidName(newName));
    };

    const register = async (body) => {
        await postMethod(url.registerApi, body).then((response) => {
            let federalValue = { heading: "Verification mail sent", title: "An verification link has been sent to the entered email address. Verify your email to continue the registration process" }
            let propertyValue = { heading: "Request Received", title: "We are reviewing your request. You’ll be notified when your request is approved." }
            if (response.status === 200 || response.status === 201) {
                setToastOpen(true)
                setDataMsg({ type: 'success', message: `${response?.data?.success}` })
                if (params.value == 'federalEmployee') {
                    navigate(`/${params.value}/Registration/Success`, { state: federalValue })
                } else if (params.value == 'propertyManager') {
                    navigate(`/${params.value}/Registration/Success`, { state: propertyValue })
                }
            } else {
                setToastOpen(true)
                setDataMsg({ type: 'error', message: `${response?.data?.error}` })
            }
        })
    }


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    };
    const handleDomainName = (organization, domain) => {
        setOrganisation(organization)
        setOrganisationid(domain)
    }

    const handleOrganization = (organization) => {
        setOrganisation(organization.orgnization)
        setOrganisationid(organization.id)
    }
    return (
        <>
            <div className="registration">
                <div className="middleContent " >
                    <div className="logo">
                        <img className="logoImg" src={GovHome} alt="" />
                    </div>
                    <div className="mainFields p-2">
                        <h3 className='text-center typeHeding' style={{ fontWeight: 600, }}>Account re-verification</h3>
                        <form onSubmit={getFormData} style={{ marginTop: '30px' }}>
                            <FormInput error={nameError}
                                helperText={nameError ? 'Invalid characters in name' : ''} required={true} name={'Name'} id={'name'} type={'text'} value={name} onChange={(e) => handleNameChange(e)} icon={`${badge}`}></FormInput>
                            <Row >
                                <Col sm={6} >
                                    <FormInput required={true} name={'Email'} id={'email'} type={'email'} value={email} error={error}
                                        helperText={error ? 'Please enter a valid .gov or .mil email address.' : ''} onChange={(e) => handleEmail(e)} icon={`${mail}`}></FormInput>
                                </Col>
                                <Col sm={6} >
                                    <FormInput required={true} name={'Telephone'} id={'telephone'} value={telephone.Telephone} onChange={handleTelephone} icon={`${call}`} inputComponent={TextMaskCustom}></FormInput>
                                </Col>
                            </Row>
                            <div className='federalEmployee_organization'>
                                    {organisationList && organisationList?.length > 0 &&
                                        <Dropdown trigger={
                                            <div style={{ display: 'flex' }}>
                                                <FormInput
                                                    className='bg-inputbg'
                                                    id="organisation-input"
                                                    type="text"
                                                    value={organisation} name={'Organisation'}
                                                    onChange={(e) => setOrganisation(e.target.value)} icon={`${dropdownicon}`}
                                                />
                                                <Button className='bg-inputbg' style={{ display: "none" }} disabled={false}>Organisation</Button>
                                            </div>

                                        }
                                            menu={
                                                organisationList && organisationList.length > 0 && organisationList.map((data, key) => (
                                                    <DropdownNestedMenuItem
                                                        label={data?.orgnization} style={{ padding: '10px' }}
                                                        rightIcon={data?.child_orgnization?.length > 0 && <ArrowRight />}
                                                        onClick={() => { handleOrganization(data) }}
                                                        menu={
                                                            data?.child_orgnization?.length > 0 && data?.child_orgnization.map((item, key) => (
                                                                <DropdownMenuItem style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                                                    <Button
                                                                        component="label"
                                                                        sx={{
                                                                            color: "#000",
                                                                            textTransform: "none",
                                                                            fontSize: "1rem"
                                                                        }}
                                                                        variant="text"
                                                                    >
                                                                        {item?.domainname}
                                                                        <input
                                                                            id="mdInput"
                                                                            type="text"
                                                                            accept={`.md`}
                                                                            hidden
                                                                            onClick={() => { handleDomainName(data?.orgnization, item?.id) }}
                                                                        />
                                                                    </Button>
                                                                </DropdownMenuItem>
                                                            ))
                                                        }
                                                    />
                                                ))
                                            }
                                        />
                                    }
                                </div>
                            <div style={{ position: 'absolute', bottom: '0', right: '0' }} >
                                <ContinueButton className={continueButton ? "continue" : "continueDisabled"} backgroundColor={continueButton ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} disabled={!continueButton} text={'Continue'} height={'50px'} color={'#fff'} />
                                <Toastmessage setToastOpen={toastOpen} handleClose={handleClose} dataMsg={dataMsg} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <BackButton send={-1} text={'Register Here'} backText={'Back'}></BackButton>
        </>
    )
}
export default Registration;