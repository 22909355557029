import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import "./FavoritePropertiesListing.scss";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Bedroom from "../../../assets/images/listingManager/bedroom.svg"
import Bathroom from "../../../assets/images/listingManager/bathroom.svg"
import PropertySize from "../../../assets/images/listingManager/square_foot.svg"
import Parking from "../../../assets/images/listingManager/parking.svg"
import Clock from "../../../assets/images/listingManager/clock.svg"
import Home from "../../../assets/images/listingManager/home.svg"
import Furnishing from "../../../assets/images/listingManager/furnishing.svg"
import Reply from "../../../assets/images/listingManager/reply.svg"
// import userImage from "../../../assets/images/listingManager/listing-manager-user.svg"
import FilledLikeIcon from "../../../assets/images/listingManager/filledLikedImage.svg"
import FlagIcon from "../../../assets/images/listingManager/flag.svg"
import ArrowForward from "../../../assets/images/listingManager/Arrow-property.svg"
import ContinueButton from "../../../Components/Buttons/ContinueButton/ContinueButton"
import { getMethodWithToken, postMethodWithToken } from '../../../utils/services/apis';
import url from '../../../utils/services/urls.json';
import { toast } from "react-toastify";
import close from "../../../assets/images/searchproperty/close.svg";
import back from "../../../assets/images/searchproperty/back_month.svg";
import fordward from "../../../assets/images/searchproperty/ford_month.svg";
import DatePicker from 'react-datepicker';
import Modal from "react-modal";
import FormInput from '../../../Components/Inputs/FormInput/FormInput';
import slots from '../../../assets/images/searchproperty/slots.svg';
import ArrowRight from '../../../assets/images/searchproperty/ArrowRight.svg';
import enGB from 'date-fns/locale/en-GB';
import { navigateLogin } from '../../../utils/services/common';
import { addDays, addMonths, addYears } from 'date-fns'
import DefaultImage from "../../../assets/images/default-image.svg"

const MyListingCard = styled.div`
font-family: Proxima_nova_reg;
// width:900px;
height: auto;
margin-bottom:25px ;
background: white;
padding: 10px;
margin-left: -10px; 
box-shadow: 0px 2px 8px 0px #0000001F;
`

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        // height: 220,
        display: 'block',
        maxWidth: 300,
        overflow: 'hidden',
        width: '100%',
    },
}));

const SelectListingCard = styled.div`
font-family: Proxima_nova_reg;
// width:840px;
height: auto;
background: white;
padding: 10px;
z-index:1
`;

const CustomImage = styled.img`
background:url('${props => props.image}');
height: 220px;
width:100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;

    @media (min-width:770px) and (max-width: 1199px) {
     height: 182px;
   }
      
   @media (max-width:768px) {
   height: 142px;
  } 
`

const FavoritePropertiesListing = () => {
    const navigate = useNavigate()
    const moment = require('moment-timezone');
    const today = new Date()
    const classes = useStyles();
    const theme = useTheme();
    const [favoritePropertyList, setFavoritePropertyList] = useState([])
    const [favoritePropertyListImages, setFavoritePropertyListImages] = useState([])
    const [activeStep, setActiveStep] = useState(0);
    const [rentPropertyId, setRentPropertyId] = useState('')
    const [openRentModal, setOpenRentModal] = useState(false)
    const [selectedRentDate, setSelectedRentDate] = useState();
    const [selectedRentEndDate, setSelectedRentEndDate] = useState();
    const [rentalDuration, setRentalDuration] = useState("")
    const [rentPrice, setRentPrice] = useState("")
    // const [favorite, setFavorite] = useState(false)
    const maxSteps = favoritePropertyListImages.length;

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
            // width: "27vw",
            // height: "680px",
            transform: screenSize.width <= 800 ? 'translate(-50%, -50%)' : 'translate(-42%, -50%)',
            width: screenSize.width <= 800 ? "" : screenSize.width <= 1100 ? "" : "40vw",
            height: screenSize.width <= 800 ? "78vh" : screenSize.width <= 1100 ? "75vh" : screenSize.width <= 1700 ? "77vh" : "75vh",
            padding: '0px',
        },
    };

    useEffect(() => {
        handleFavoritePropertyList()
    }, [])

    const handleFavoritePropertyList = async () => {
        await getMethodWithToken(url.favoritePropertyList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setFavoritePropertyList(response?.data);
                response?.data.map((data) =>
                    setFavoritePropertyListImages(data?.property_images)
                )
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handleFavoritePropertyDetail = (id) => {
        navigate(`/Dashboard/favourite/favouriteDetail/${id}/favourite`)
    }
    const handleChatWithManager = async (propertyMasterId, bedroom, bathroom, propertyType, price, address, per_month, propertySize, id) => {
        let perMonth = per_month === true ? "per_month" : "per_day";
        const receiverPropertyDetail = {
            Id: id,
            PropertyMasterId: propertyMasterId,
            bedroom: bedroom,
            bathroom: bathroom,
            propertyType: propertyType,
            price: price,
            address: address,
            per_month: perMonth,
            propertySize: propertySize

        }
        let body = {
            receiver: id,
            propertyMaster: propertyMasterId,
            content: JSON.stringify(receiverPropertyDetail)
        }

        await postMethodWithToken(url.sendMessage, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                localStorage.setItem('receiverPropertyDetail', response?.data?.content)
                navigate("/Dashboard/Chats", {
                    state: {
                        receiverId: response?.data?.receiver,
                        propertyMasterId: response?.data?.propertyMaster
                    }
                })
            }
        })
    }

    const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
        const monthYear = date.toLocaleDateString(undefined, {
            month: 'long',
            year: 'numeric',
        });

        const days = ['Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat', 'Sun'];

        return (
            <div className="custom-header">
                <div className='date_month'>
                    <p className='monthyear'>{monthYear}</p>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <img src={back} onClick={decreaseMonth} alt='back' style={{ cursor: "pointer" }} />
                        <img src={fordward} onClick={increaseMonth} alt='fordward' style={{ cursor: "pointer" }} />
                    </div>

                </div>
                <div style={{ fontSize: '20px', justifyContent: 'space-evenly', display: 'flex', fontFamily: 'Proxima_nove_reg', paddingBottom: '20px' }}>
                    {days.map((day, index) => (
                        <span key={index}>{day}</span>
                    ))}
                </div>
            </div>
        );
    };
    const handleMainCalenderDate = (date) => {
        if (!selectedRentDate) {
            setSelectedRentDate(date);
            if (rentalDuration === "Long term") {
                const newEndDate = new Date(date);
                newEndDate.setFullYear(newEndDate.getFullYear() + 1);
                setSelectedRentEndDate(newEndDate);
            }
        } else if (!selectedRentEndDate || date >= selectedRentEndDate) {
            setSelectedRentEndDate(date);
        } else {
            if (selectedRentDate && selectedRentEndDate) {
                setSelectedRentEndDate("")
                setSelectedRentDate("")
            }
            setSelectedRentDate(date);
            if (rentalDuration === "Long term") {
                const newEndDate = new Date(date);
                newEndDate.setFullYear(newEndDate.getFullYear() + 1);
                setSelectedRentEndDate(newEndDate);
            }
        }
    };
    const handleRentProperty = async (id, allValues) => {
        setRentalDuration(allValues?.subscription_details?.masterSubscrption)
        let api = `${url.GetRentProperty}?rentProperty_id=${id}`
        await getMethodWithToken(api).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setSelectedRentDate(new Date(response.data.start_date));
                setSelectedRentEndDate(new Date(response.data.end_date));
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            } else {
                setSelectedRentDate('');
                setSelectedRentEndDate('');
            }
        });
        setRentPropertyId(id)
        setRentPrice((Number(allValues?.subscription_details?.price) + Number(allValues?.subscription_details?.price) * 5 / 100) + Number(allValues?.subscription_details?.deposit_amount))
        setOpenRentModal(true)
    }
    const dates = addDays(selectedRentDate, 30);
    const midDate = addMonths(selectedRentDate, 10)
    const longDate = addYears(selectedRentDate, 1)
    const handleCloseClick = () => {
        setOpenRentModal(false)
    }
    const handleCloseRentClick = async (start_date, end_date) => {
        let body = {
            property_id: +rentPropertyId,
            start_date: start_date,
            end_date: end_date,
            is_rent: true
        }
        await postMethodWithToken(url.RentPropertyDates, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                navigate(`/Dashboard/FavoritePropertyList/PaymentDetail/${rentPropertyId}`, {
                    state: {
                        rentPrice: rentPrice
                    }
                })
                setOpenRentModal(false)
            } else {
                toast.error(response?.data?.success)
            }
        })

    }
    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }

    const handleAddFavouriteProperty = async (propertyId) => {

        let body = {
            "property_id": propertyId,
            "favourite": false
        }
        await postMethodWithToken(url.addFavoriteProperty, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                handleFavoritePropertyList()
            }
        })
    }

    return (

        <div className="favourite-listing">
            {favoritePropertyList && favoritePropertyList.length > 0 ?
                favoritePropertyList.map((data, index) => {
                    return (
                        <div className='listing-manager-fav'>
                            <Row style={{ margin: "0", padding: "0" }}>
                                <Col xs={12} sm={12} md={5} lg={4} xl={4} xxl={4}>
                                    <div className={classes.root}>
                                        <AutoPlaySwipeableViews
                                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                            index={activeStep}
                                            onChangeIndex={handleStepChange}
                                            enableMouseEvents
                                        >
                                            {data?.property_images.map((step, index) => {
                                                return (
                                                    <>
                                                        <div>
                                                            <CustomImage className={classes.img} src={step?.media?.file} alt={step.label} />
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </AutoPlaySwipeableViews>
                                        <MobileStepper
                                            position="static"
                                            variant="text"
                                            nextButton={
                                                <Button size="small" style={{ color: "white" }} onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                                    {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                </Button>
                                            }
                                            backButton={
                                                <Button size="small" style={{ color: "white" }} onClick={handleBack} disabled={activeStep === 0}>
                                                    {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                </Button>
                                            }
                                        />
                                    </div>
                                    <div class="d-flex justify-content-center rent-box">
                                        <div>
                                            <div className="rental-price">${data?.subscription_details?.price}<span style={{ fontSize: "16px", color: "#505050", fontWeight: 400, }} >{data?.subscription_details?.per_month ? '/ month' : '/ day'}</span></div>
                                            <div className="rent-heading" >Rent</div>
                                        </div>
                                        <div><hr style={{ border: "1px solid black", height: "40px", marginLeft: "10px", marginTop: "20px" }} /></div>
                                        <div>
                                            <div className="deposit-price" style={{ marginLeft: "10px" }}>$<span>{data?.subscription_details?.deposit_amount}</span></div>
                                            <div className="rent-heading" style={{ marginLeft: "25px" }}>Deposit</div>
                                        </div>
                                    </div>
                                    <div className="deposit-type">
                                        <div className="d-flex">
                                            <div className="rent-heading" >Rent : </div>
                                            <div className="rental-price">${data?.subscription_details?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{data?.subscription_details?.per_month ? '/ month' : '/ day'}</span></div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="rent-heading">Deposit : </div>
                                            <div className="deposit-price">$<span>{data?.subscription_details?.deposit_amount}</span></div>

                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={7} lg={8} xl={8} xxl={8}>
                                    <div className="listing-card-detail" >
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex" >
                                                <div>
                                                    <img src={data?.Property_owner?.[0]?.profilePic ? data?.Property_owner?.[0]?.profilePic : DefaultImage} alt="" className="profile-value" style={{ width: "50px", height: "50px", borderRadius: "50%", cursor: "pointer" }} />
                                                </div>
                                                <div >
                                                    <div className="d-none d-lg-flex" style={{ paddingLeft: "7px" }}>
                                                        <div className="user-heading">{data?.Property_owner?.[0]?.name}</div>
                                                        <div className="user-dot">.</div>
                                                        <div className="property-owner">Property Owner</div>
                                                    </div>
                                                    <div className="d-lg-none" style={{ paddingLeft: "7px" }}>
                                                        <div className="user-heading">{data?.Property_owner?.[0]?.name}</div>
                                                        <div className="property-owner">Property Owner</div>
                                                    </div>
                                                    {/* <div style={{ color: "#707070", paddingLeft: "5px", fontSize: "14px" }}>{data?.Property_owner?.[0]?.orgnization}</div> */}
                                                </div>

                                            </div>
                                            <div>
                                                <div className="d-flex">
                                                    <div><img src={FilledLikeIcon} alt="" style={{ width: "20px", height: "20px", cursor: "pointer" }} onClick={() => handleAddFavouriteProperty(data?.propertyMaster_id)} /></div>
                                                    <div><img src={FlagIcon} alt="" style={{ width: "20px", height: "20px", marginLeft: "10px" }} /></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{data?.apartmentName} {data?.location?.locality}</div>
                                        <Row style={{ borderBottom: "1px solid #B7B7B7", paddingBottom: "5px" }}>
                                            <Col md={6} lg={6} xl={6} xxl={6} className='d-flex'>
                                                <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                <div className="categroy-heading-type ">Bedroom</div>
                                                <div className="category-value">{data?.bedroomCount}</div>
                                            </Col>
                                            <Col md={6} lg={6} xl={6} xxl={6} className='d-flex'>
                                                <div ><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                <div className="categroy-heading-type ">Bathroom</div>
                                                <div className="category-value">{data?.propertyType?.name === "Room" ? data?.room_sharing?.name : data?.bathroomCount}</div>
                                            </Col>
                                            <Col md={6} lg={6} xl={6} xxl={6} className='d-flex'>
                                                <div><img src={Parking} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                <div className="categroy-heading-type ">Parking</div>
                                                <div className="category-value">{data?.parkingType?.name?.length > 0 && getUpparCaseName(data?.parkingType?.name)} </div>
                                            </Col>
                                            <Col md={6} lg={6} xl={6} xxl={6} className='d-flex'>
                                                <div><img src={PropertySize} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                <div className="category-value">{data?.propertySize} sqft</div>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Row >
                                                <Col md={4} lg={4} xl={4} xxl={4} style={{ borderRight: "1px solid #B7B7B7" }}>
                                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                                        <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading">Available from</div>
                                                    </div>
                                                    <div className="select-value-heading">{data?.subscription_details?.propertyAvailfrom !== null && moment?.tz(data?.subscription_details?.propertyAvailfrom?.[0], data?.subscription_details?.propertyAvailfrom?.[0]).format("YYYY-MM-DD")}</div>
                                                </Col>
                                                <Col md={4} lg={4} xl={4} xxl={4} style={{ borderRight: "1px solid #B7B7B7" }}>
                                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                                        <div><img src={Home} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading">Property Type</div>
                                                    </div>
                                                    <div className="select-value-heading">{data?.propertyType?.name?.length > 0 && getUpparCaseName(data?.propertyType?.name)}</div>
                                                </Col>
                                                <Col md={4} lg={4} xl={4} xxl={4}>
                                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                                        <div><img src={Furnishing} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading">Furnishing</div>
                                                    </div>
                                                    <div className="select-value-heading">{data?.furnishType?.name?.length > 0 && getUpparCaseName(data?.furnishType?.name)}</div>
                                                </Col>
                                            </Row>
                                            <Row className="g-2" style={{ marginTop: "17px" }}>
                                                <Col md={5} lg={4} xl={4} xxl={4}>
                                                    <ContinueButton text={'Rent Property'} color={'#fff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={"100%"} borderRadius={"4px"} fontSize={"12px"} fontWeight={500} onClick={() => handleRentProperty(data?.propertyMaster_id, data)} />
                                                </Col>
                                                <Col md={5} lg={4} xl={4} xxl={4}>
                                                    <ContinueButton text={'Chat with Manager'} color={'#2F86D1'} backgroundColor={'#fff'} border={'2px solid #2F86D1'} borderRadius={"4px"} fontSize={"12px"} fontWeight={500} width={"100%"} onClick={() => handleChatWithManager(data?.propertyMaster_id, data?.bedroomCount, data?.bathroomCount, data?.propertyType?.name, data?.subscription_details?.price, data?.location?.locality, data?.subscription_details?.per_month, data?.propertySize, data?.Property_owner?.[0]?.id)} />
                                                </Col>

                                                <Col md={2} lg={4} xl={4} xxl={4} class="d-flex align-items-center">
                                                    <div className="d-flex align-items-center mt-2" style={{ cursor: "pointer", marginLeft: "15px" }}>
                                                        <div className="view-heading" onClick={() => handleFavoritePropertyDetail(data?.propertyMaster_id)}>View Details</div>
                                                        <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} onClick={() => handleFavoritePropertyDetail(data?.propertyMaster_id)} /></div>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </div>
                                        <Modal style={customStyles} isOpen={openRentModal} overlayClassName="myoverlay" >
                                            <div className="modal-container" >
                                                <div style={{ borderBottom: " 1px solid #EAEAEA", marginBottom: "10px" }} >
                                                    <div className="modal-main-heading  calender_header" > <p style={{ fontSize: '24px', fontWeight: '600', fontFamily: 'Proxima_nova_reg', marginBottom: "0px" }}>Select date range</p>
                                                        <img src={close} onClick={handleCloseClick} style={{ cursor: 'pointer' }} alt='close' /></div>
                                                    <div style={{ display: "flex" }}>
                                                        <div className='inputField_calender'>
                                                            <FormInput
                                                                type='text' name={'Start Date'} id={'name'}
                                                                value={selectedRentDate ? selectedRentDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }) : ''}
                                                                readOnly />
                                                        </div>
                                                        <img src={ArrowRight} alt='right' />
                                                        <div className='inputField_calender' style={{ position: "relative" }}>
                                                            <FormInput
                                                                type='text' name={'End Date'} id={'name'}
                                                                value={selectedRentEndDate ? selectedRentEndDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }) : ''}
                                                                readOnly={false} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='main_calender'>
                                                    <DatePicker onChange={handleMainCalenderDate} inline dateFormat="MMMM yyyy"
                                                        dayClassName={(date) => {
                                                            let classes = '';
                                                            if (selectedRentDate && selectedRentEndDate) {
                                                                if (date.getTime() === new Date(selectedRentDate).getTime()) {
                                                                    classes += 'start-date';
                                                                } else if (date.getTime() === new Date(selectedRentEndDate).getTime()) {
                                                                    classes += 'end-date';
                                                                } else if (date > new Date(selectedRentDate) && date < new Date(selectedRentEndDate)) {
                                                                    classes += 'range-selected';
                                                                }
                                                            }
                                                            return classes;
                                                        }}
                                                        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                            <CustomHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />)}
                                                        locale={enGB}
                                                        formatWeekDayShort={(locale, dayOfWeek) => {
                                                            const days = ['Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat', 'Sun'];
                                                            return days[dayOfWeek];
                                                        }}
                                                        minDate={today}
                                                        maxDate={rentalDuration === "short term" ? dates : rentalDuration === "mid term" ? midDate : longDate}
                                                    />
                                                </div>
                                                <div className="d-flex" style={{ justifyContent: "space-between", padding: "20px 32px 20px 32px ", borderTop: "1px solid #EAEAEA", alignItems: "baseline" }}>
                                                    <div className='calender_footer'>
                                                        <img src={slots} alt='Like' />
                                                        <p className='footer_para'>Available Slots</p>
                                                    </div>
                                                    <div>
                                                        <button className={selectedRentEndDate ? 'continue_button' : 'Disablecontine'} onClick={() => handleCloseRentClick(selectedRentDate, selectedRentEndDate)}>Continue to checkout</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )
                })
                :
                <div className="empty-fav-list">
                    <div className="empty-heading" >No favourite List</div>
                </div>
            }
        </div>

    )
}
export default FavoritePropertiesListing

