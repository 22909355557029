import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import FavoriteSelect from "../FavoriteSelect/FavoriteSelect";
import Box from '@mui/material/Box';
import RoomIcon from "../../../../assets/images/favoriteProperty/cil_room.png"
import Home from "../../../../assets/images/favoriteProperty/home.svg"
import Town from "../../../../assets/images/favoriteProperty/town.svg"
import Condo from "../../../../assets/images/favoriteProperty/condo.svg"
import "./FavoritePropertyList.scss"
import ContinueButton from "../../../../Components/Buttons/ContinueButton/ContinueButton"
import { getMethodWithToken, postMethodWithToken } from '../../../../utils/services/apis';
import url from "../../../../utils/services/urls.json";
import { toast } from "react-toastify";
import Modal from "react-modal"
import { navigateLogin } from '../../../../utils/services/common';

const ContainerSection = styled.div`
font-family: Proxima Nova;
`
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "700px",
        height: "230px",
    },
};

const FavoritePropertyList = () => {
    const navigate = useNavigate()
    const [selectList, setSelectList] = useState([])
    const [proId, setProId] = useState(localStorage.getItem("PropertyType") !== null ? localStorage.getItem("PropertyType") : "");
    const [uesrId, setUserId] = useState(localStorage.getItem("Id"))
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        getPropertyDetail()
        // selectpropertyList()
    }, [])

    // const selectpropertyList = async ()=>{
    //     await getMethodWithToken(url.selectlist +)
    // }
    const getPropertyDetail = async () => {
        await getMethodWithToken(url.selectList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                response?.data.map(function (item) {
                    if (item?.[1] === "property_type") {
                        setSelectList(item?.[0])
                    }
                })
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const handleSelect = (value) => {
        setProId(value);
        localStorage.setItem("PropertyType", value);
    }

    const handleSubmitSelect = async () => {
        let body = {
            // "masterUser": uesrId,
            "propertyType": proId
        }
        await postMethodWithToken(url.postSelectList, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                navigate(`/Dashboard/FavoritePropertieList/SelectBasic/${response.data.propertyId}/${proId}`)
            }
            else {
                toast.error(response?.data?.error?.propertyType?.[0])
            }
        })
    }

    const handleSaveExit = async () => {
        // let body = {
        //     "masterUser": uesrId,
        //     "propertyType": proId,
        // }
        // await postMethodWithToken(url.postSelectList, body).then((response) => {
        //     if (response.status === 200 || response.status === 201) {
        //         navigate("/Dashboard/homePage");
        //     } else {
        //         toast.error("Please Select one field")
        //     }
        // })
        navigate("/Dashboard/homePage");
    }

    const handleOpenBack = () => {
        setOpenModal(true)
    }
    const handleCloseBack = () => {
        setOpenModal(false)
    }

    return (
        <>
            <ContainerSection>
                <Row style={{ margin: 0 }}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                        <FavoriteSelect value={0} />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={7} xxl={7} className="select-list">
                        <div>
                            {selectList && selectList.length > 0 && selectList.map((item, index) => {
                                return (
                                    <>
                                        <div className='value-select'>
                                            <div className="d-flex room-value" style={{ visibility: +proId === item.id ? 'visible' : 'hidden' }} >Is your place a</div>
                                            <div className="room-select" onClick={() => handleSelect(item.id)} style={{ border: proId == item.id ? "2px solid #2F86D1" : "1px solid #C7C7C7" }}  >
                                                <div>
                                                    <img src={index === 0 ? RoomIcon : index === 1 ? Home : index === 2 ? Town : Condo} alt="" style={{ width: "46px", height: "46px" }} />
                                                </div>
                                                <div className="room-heading" style={{ color: proId === item.id ? "#2F86D1" : "rgb(136 132 132)" }} >{item.name}</div>
                                            </div>

                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-between" style={{ margin: "20px 0 20px 30px", alignItems: "center" }} >
                            <div>
                                <ContinueButton className="back-list" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={handleOpenBack} fontSize={"20px"} fontWeight={"400"} />
                            </div>
                            <div className="d-flex justify-content-end">
                                <div style={{ marginRight: "50px", display: "flex", alignItems: "center" }}>
                                    <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                                <div style={{ marginRight: "50px", fontFamily: "Proxima_nova_reg" }}>
                                    <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => handleSubmitSelect()} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </ContainerSection>
            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
                <div className="d-flex justify-content-center">
                    <div className="back-heading">Are you sure you want to back?</div>
                </div>
                <div className="d-flex justify-content-center" >
                    <div style={{ marginTop: "10px" }}>All the changes you will made will be discarded</div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="d-flex" style={{ marginTop: "50px" }}>
                        <div className="stay-heading" onClick={handleCloseBack}>Stay on this page</div>
                        <div>
                            <ContinueButton text="Go back" color={'#fff'} onClick={() => navigate(-1)} width={"210px"} borderRadius={"4px"} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default FavoritePropertyList
