import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const FullScreenLoader = (props) => {
    const handleClose = () => {
      props.showLoader=false
    };
    // const handleOpen = () => {
    // //   setOpen(true);
    //   props.showLoader=true
    // };
    return (
        <div>
        {/* <Button onClick={handleOpen}>Show backdrop</Button> */}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={props.showLoader}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      );
}

export default FullScreenLoader
