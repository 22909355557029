import React, { useState } from 'react'
import styled from 'styled-components';
import Navbar from '../../../Components/commonComponents/navbar/Navbar';
import ArrowBack from "../../../assets/images/favoriteProperty/arrow-back.svg"
import { useNavigate } from 'react-router-dom';
import RequestUserProfile from "../../../assets/images/request-user.svg"
import Bedroom from "../../../assets/images/searchproperty/Bed.svg"
import Bathroom from "../../../assets/images/searchproperty/bath-chat.svg"
import FilledInput from '@material-ui/core/FilledInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import SendIcon from "../../../assets/images/searchproperty/send.svg"
import "./ListingCardDetail.scss"

const SelectListingRequest = styled.div`
height: auto;
background: #F2F2F7;
padding: 20px;
`;

const ListingCardDetail = () => {
    const navigate = useNavigate()
    const [count, setCount] = useState("")
    const handleChange = (event) => {
        setCount(event.target.value)
    }
    const array = [1, 2, 3, 4, 5]
    return (
        <div>
            <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
                <Navbar />
            </div>
            <SelectListingRequest>
                <div>
                    <div className="d-flex" style={{ marginTop: "60px" }} >
                        <div className="arrow-box" onClick={() => navigate(-1)}>
                            <img src={ArrowBack} alt="" />
                        </div>
                        <div style={{ fontSize: "20px", marginLeft: "10px" }}><span style={{ fontWeight: 600 }}>2 Bedroom Flat</span> in <span style={{ fontWeight: 600 }}>Eastend Apartment</span> for <span style={{ fontWeight: 600 }}>Rent</span> in <span style={{ fontWeight: 600 }}>Woodland Hits</span></div>
                    </div>

                    <div className="d-flex" >
                        <div>
                            <div className="update-heading">Update</div>
                            {array.map(data =>
                                <div className="update-lising-container">
                                    <div className="d-flex justify-content-between" >
                                        <div className="d-flex" >
                                            <div>
                                                <img src={RequestUserProfile} alt="" />
                                            </div>
                                            <div className="users-heading" >
                                                <div className="user-main-heading">Interestes User</div>
                                                <div className="user-heading">Organisation</div>
                                            </div>
                                        </div>
                                        <div className="request-profile">1</div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div>
                            <div className="chat-heading">Chats</div>
                            <div className="chat-contact-main">
                                <div className="chat-container">
                                    <div className="chat-contact-left" >
                                        <div className="d-flex" >
                                            <div className="listing-user-heading" >$1,500/</div>
                                            <div className="listing-user-month">month</div>
                                        </div>
                                        <div className="chat-description">105 Jerry Dove Drive, Florence, SC 29501</div>
                                        <div className="d-flex" style={{ paddingTop: "7px" }}>
                                            <div className="d-flex">
                                                <div>
                                                    <img src={Bedroom} alt="" />
                                                </div>
                                                <div className="chat-bed-heading">2 Bedrooms</div>
                                            </div>
                                            <div className="d-flex justify-content-end" style={{ paddingLeft: "15px" }}>
                                                <div>
                                                    <img src={Bathroom} alt="" />
                                                </div>
                                                <div className="chat-bed-heading">2 Bedrooms</div>
                                            </div>
                                        </div>
                                        <div className="d-flex" style={{ paddingTop: "5px" }}>
                                            <div className="chat-sqare">800 <span>sq/ft</span></div>
                                            <div className="chat-sqare" style={{ paddingLeft: "30px" }}>Condominium</div>
                                        </div>
                                    </div>
                                    <div className="chat-contact-left-2" >
                                        <div className="chat-contact-heading">Hi Edgar, I am interested in your property</div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <div className="chat-contact-right" >
                                            <div className="chat-contact-right-heading">Thats Awesome!</div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "25px" }}>
                                        <FormControl fullWidth variant="outlined">
                                            <FilledInput
                                                id="filled-adornment-weight"
                                                value={count}
                                                onChange={(e) => handleChange(e)}
                                                endAdornment={<InputAdornment position="end">
                                                    <img src={SendIcon} alt="" style={{ cursor: "pointer", paddingRight: "10px" }} />
                                                </InputAdornment>}
                                                aria-describedby="filled-weight-helper-text"
                                                inputProps={{
                                                    'aria-label': 'weight',
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SelectListingRequest>
        </div>
    )
}

export default ListingCardDetail
