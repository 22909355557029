import React, { useState } from 'react';
import styled from 'styled-components';
import "./ReListReview.scss";
import GovHomeLogo from "../../../../assets/images/GovHomeLogo.png"
import { Col, Row } from 'react-bootstrap';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import userImage from "../../../../assets/images/Ellipse 29.png";
import Reply from "../../../../assets/images/reply.png";
import Bedroom from "../../../../assets/images/favoriteProperty/bedroom.svg"
import Bathroom from "../../../../assets/images/favoriteProperty/bathroom.svg"
import Parking from "../../../../assets/images/favoriteProperty/parking.svg"
import Clock from "../../../../assets/images/favoriteProperty/clock.svg"
import Home from "../../../../assets/images/favoriteProperty/home.svg"
import Furnishing from "../../../../assets/images/favoriteProperty/furnishing.svg"
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import FormControl from '@mui/material/FormControl';
import { InputLabel, MenuItem, Select } from '@mui/material'
import url from "../../../../utils/services/urls.json"
import Modal from "react-modal"
import { getMethodWithToken } from '../../../../utils/services/apis';
import ContinueButton from '../../../../Components/Buttons/ContinueButton/ContinueButton';
import { navigateLogin } from '../../../../utils/services/common';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "700px",
        height: "230px",
    },
};

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const tutorialSteps = [
    {
        label: 'San Francisco – Oakland Bay Bridge, United States',
        imgPath:
            'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
    },
    {
        label: 'Bird',
        imgPath:
            'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
    },
    {
        label: 'Bali, Indonesia',
        imgPath:
            'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
    },
    {
        label: 'Goč, Serbia',
        imgPath:
            'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
    },
];
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: 210,
        display: 'block',
        maxWidth: 600,
        overflow: 'hidden',
        width: '100%',
    },
}));

const ReListReviewContainer = styled.div`
margin: 20px 0 0 50px;
font-family: Proxima Nova;
`

const ReListReview = () => {
    const navigate = useNavigate()
    const classes = useStyles();
    const theme = useTheme();
    const [featurePropertyList, setFeaturePropertyList] = useState([])
    const [activeStep, setActiveStep] = useState(0);
    const [collectinProperty, setCollectionProperty] = useState("")
    const [checked, setChecked] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const maxSteps = tutorialSteps.length;
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleStepChange = (step) => {
        setActiveStep(step);
    };
    const handleCheck = (event) => {
        setChecked(event.target.checked)
        getFeaturePropertyDropdown()
    }
    const getFeaturePropertyDropdown = async () => {
        await getMethodWithToken(url.featurePropertyDropdown).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setFeaturePropertyList(response?.data)
            } else if (response.status===401){
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    const handleCollectionChange = (event) => {
        setCollectionProperty(event.target.value)
    }
    const handleOpenBack = () => {
        setOpenModal(true)
    }
    const handleCloseBack = () => {
        setOpenModal(false)
    }
    const handleReview = () => {
        navigate("/Dashboard/FavoritePropertieList/PaymentDetail/836")
    }
    return (
        <>
            <ReListReviewContainer>
                <div>
                    <img src={GovHomeLogo} alt="" style={{ width: "100px", height: "75px" }} />
                </div>
                <Row style={{ margin: "0" }}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <div className="review-section-container">
                            <div className="review-heading">Review your property listing</div>
                            <div className="review-description">See if you want to change something</div>
                            <div className="review-section">
                                <div className="review-value-item">
                                    <Row>
                                        <Col lg={4}>
                                            <div className={classes.rootValue}>
                                                <AutoPlaySwipeableViews
                                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                    index={activeStep}
                                                    onChangeIndex={handleStepChange}
                                                    enableMouseEvents
                                                >
                                                    {tutorialSteps.map((step, index) => {
                                                        return (
                                                            <>
                                                                <div>
                                                                    <img className={classes.img} src={step.imgPath} alt={step.label} />
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </AutoPlaySwipeableViews>
                                                <MobileStepper
                                                    position="static"
                                                    variant="text"
                                                    nextButton={
                                                        <Button size="small" style={{ color: "white" }} onClick={handleNext} disabled={activeStep === maxSteps - 1}>

                                                            {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                        </Button>
                                                    }
                                                    backButton={
                                                        <Button size="small" style={{ color: "white" }} onClick={handleBack} disabled={activeStep === 0}>
                                                            {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                        </Button>
                                                    }
                                                />
                                            </div>
                                            <div className="seach-heading" >Rental Duration : <span style={{ color: "black", fontWeight: 600, fontSize: "16px" }}>Long Time</span></div>
                                            <div class="d-flex justify-content-center">
                                                <div>
                                                    <div className="rental-price">$16000<span style={{ fontSize: "16px", color: "#505050" }} >/month</span></div>
                                                    <div className="rent-heading" >Rent</div>
                                                </div>
                                                <div><hr style={{ border: "1px solid black", height: "40px", marginLeft: "10px", marginTop: "20px" }} /></div>
                                                <div>
                                                    <div className="deposit-price" style={{ marginLeft: "10px" }}>$4000<span></span></div>
                                                    <div className="rent-heading" style={{ marginLeft: "25px" }}>Deposit</div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={8}>
                                            <div className="detail-conatiner">
                                                <div className="d-flex">
                                                    <div>
                                                        <img src={userImage} alt="" style={{ width: "50px", height: "50px" }} />
                                                    </div>
                                                    <div>
                                                        <div className="d-flex" style={{ paddingLeft: "7px" }}>
                                                            <div className="user-heading">User Name</div>
                                                            <div className="user-dot">.</div>
                                                            <div className="property-owner">Property Owner</div>
                                                        </div>
                                                        <div style={{ color: "#707070", paddingLeft: "7px", fontSize: "16px", textAlign: "start" }}>Organisation Name</div>
                                                    </div>
                                                </div>
                                                <div className="d-flex" style={{ marginTop: "10px" }} >
                                                    <div style={{ fontSize: "17px" }}><span style={{ fontWeight: 600 }}><span>2</span> Bedroom Flat</span> in <span style={{ fontWeight: 600 }}>Eastend Apartments</span> for <span style={{ fontWeight: 600 }}>Rent</span> in <span style={{ fontWeight: 600 }}>Woodland Hills</span></div>
                                                    <div style={{ marginLeft: "10px" }}><img src={Reply} alt="" /></div>
                                                </div>
                                                <div style={{ color: "#707070", fontSize: "18px", fontWeight: 500, marginTop: "5px", textAlign: "start" }}>Eastend Apartment 199 oakway Lane, Woodland Hills,  20...</div>
                                                <div className='d-flex' style={{ marginTop: "10px", borderBottom: "1px solid #B7B7B7", paddingBottom: "5px" }} >
                                                    <div className='d-flex'>
                                                        <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading">Bedroom</div>
                                                        <div className="category-value">2</div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <div style={{ marginLeft: "20px" }} ><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading">Bathroom</div>
                                                        <div className="category-value">2</div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <div style={{ marginLeft: "20px" }}><img src={Parking} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading">Parking</div>
                                                        <div className="category-value">2/4 Wheeler</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Row >
                                                        <Col lg={3} style={{ borderRight: "1px solid #B7B7B7", height: "100px" }}>
                                                            <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                <div className="categroy-heading">Available from</div>
                                                            </div>
                                                            <div className="select-value-heading">Immediately Available</div>
                                                        </Col>
                                                        <Col lg={4} style={{ borderRight: "1px solid #B7B7B7", height: "100px", marginLeft: "10px" }}>
                                                            <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                <div><img src={Home} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                <div className="categroy-heading">Property Type</div>
                                                            </div>
                                                            <div className="select-value-heading">Apartment </div>
                                                        </Col>
                                                        <Col lg={4} style={{ marginLeft: "10px" }}>
                                                            <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                <div><img src={Furnishing} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                <div className="categroy-heading">Furnishing</div>
                                                            </div>
                                                            <div className="select-value-heading">Semi-furnished</div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="edit-property">Edit Property Details</div>
                            </div>
                            {/* <div >
                            <hr style={{ width: "950px",marginLeft:"148px"}} />
                        </div> */}
                            <div className="update-container" >
                                <div className="update-heading" >Upgrade your property to featured listing</div>
                                <div className="update-des">
                                    <div className="update-des-value" >Allow your property to be featured on the front page,advertisement and stand out on the map</div>
                                </div>
                                <div className="update-check">
                                    <div>
                                        <FormCheckInput type="checkbox" name="verifyFed" id="verifyFed" style={{ width: "25px", height: "25px" }} onChange={handleCheck} />
                                        <label className="ps-2 check-list text-start" htmlFor="verifyFed">I want to add my property to the featured list</label>
                                    </div>
                                </div>
                            </div>
                            {checked &&
                                <FormControl className='formControl' variant="standard" sx={{ m: 1, width: "350px" }} >
                                    <InputLabel id="demo-simple-select-label" >Choose Collection for your property</InputLabel>
                                    <Select
                                        required={true}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={collectinProperty}
                                        label="Age"
                                        onChange={(e) => handleCollectionChange(e)}
                                    >
                                        {featurePropertyList && featurePropertyList.length > 0 && featurePropertyList.map((data) => (
                                            <MenuItem value={data?.id}>{data?.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                            <div className="changes-des">
                                <div className="changes-des-value">Changes:$10 for every property you choose to display in your featured list</div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between" style={{ margin: "20px 0 20px 30px", alignItems: "center" }} >
                            <div style={{ fontSize: "24px", cursor: "pointer" }} onClick={() => handleOpenBack()} >Back</div>
                            <div className="d-flex justify-content-end">
                                <div style={{ fontSize: "20px", cursor: "pointer", paddingRight: "70px", display: "flex", alignItems: "center" }}>Save & Exit</div>
                                <div style={{ marginRight: "50px" }}>
                                    <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => handleReview()} />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </ReListReviewContainer>
            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
                <div className="d-flex justify-content-center">
                    <div className="back-heading">Are you sure you want to back?</div>
                </div>
                <div className="d-flex justify-content-center" >
                    <div style={{ marginTop: "10px" }}>All the changes you will made will be discarded</div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="d-flex" style={{ marginTop: "50px" }}>
                        <div className="stay-heading" onClick={() => handleCloseBack()}>Stay on this page</div>
                        <div>
                            <ContinueButton text="Go back" color={'#fff'} onClick={() => navigate(-1)} width={"210px"} borderRadius={"4px"} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ReListReview
