import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import FavoriteSelect from '../FavoriteSelect/FavoriteSelect';
import './FavoriteUploadIamge.scss'
import ContinueButton from "../../../../Components/Buttons/ContinueButton/ContinueButton"
import { useDropzone } from "react-dropzone"
import uploadIamgeIcon from "../../../../assets/images/uploadImage.png"
import url from "../../../../utils/services/urls.json"
import { getMethodWithToken, postMethodWithToken } from '../../../../utils/services/apis';
import Add from '../../../../assets/images/add.svg';
import Cross from '../../../../assets/images/favoriteProperty/cross.svg';
import { toast } from 'react-toastify';
import Modal from "react-modal"
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import imageCompression from "browser-image-compression";
import { navigateLogin } from '../../../../utils/services/common';

const ContainerSection = styled.div`
font-family: Proxima Nova;
`
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "700px",
        height: "230px",
    },
};
const imageModalStyle = {
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-36%, -50%)',
        backgroundColor: "white",
        border: "1px #ccc",
        borderRadius: "4px",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        width: "70vw",
        height: "80vh",
        outline: "none",
        padding: "15px",
    },
};
const FavoriteUploadIamge = () => {
    const navigate = useNavigate()
    const param = useParams()
    const [uploadImageId, setUploadImageId] = useState([])
    const [file, setFile] = useState([])
    const [uploadImageData, setUploadImageData] = useState([])
    const [openImageCrop, setOpenImageCrop] = useState(false);
    const [imageCrop, setImageCrop] = useState();
    const [pickerImageIndex, setpickerImageIndex] = useState(0);
    const [openModal, setOpenModal] = useState(false)
    const [completedCrop, setCompletedCrop] = useState(null);
    const [activeStep, setActiveStep] = useState(0)
    const [cropProfile, setCropProfile] = useState({
        unit: '%',
        x: 25,
        y: 25,
        width: 50,
        height: 50
    });
    const [cropFlag, setCropFlag] = useState(true)
    const imgRefProfile = useRef(null);
    const handleRemoveImage = (index, event) => {
        event.stopPropagation();
        const newUploadImageData = [...uploadImageData];
        newUploadImageData.splice(index, 1);
        uploadImageId.splice(index, 1)
        setUploadImageData(newUploadImageData);
    }

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, } = useDropzone({
        accept: '.jpg, .png, .jpeg',
        onDrop: (acceptedFile) => {
            let formData = new FormData();
            acceptedFile.map((file, index) => {
                formData.append("file", file);

            });
            postMethodWithToken(url.profileImageUpload, formData).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    let imageListIds = [...uploadImageId];
                    let imageListData = [...uploadImageData]
                    imageListIds.push(response.data.id)
                    imageListData.push({
                        file: response.data.file
                    })
                    setUploadImageId(imageListIds)
                    setUploadImageData(imageListData)
                }
            })
        }
    });

    useEffect(() => {
        getSteps()
    }, [])

    const getSteps = async () => {
        await getMethodWithToken(url.selectlist + param.id + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                const allDetailStepFive = response?.data?.detail?.stepFive;
                const newIds = allDetailStepFive.map(value => value.id);
                const newFiles = allDetailStepFive.map(item => item);
                setUploadImageId(prevState => [...prevState, ...newIds]);
                setUploadImageData(prevState => [...prevState, ...newFiles])
                setActiveStep(response?.data?.detail?.stepsCompleted)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const handleUploadSubmit = async () => {
        let body = {
            "media": uploadImageId
        }
        await postMethodWithToken(url.uploadMedia + param.id + "/", body).then((response) => {
            if (uploadImageData.length >= 5 && (response.status === 200 || response.status === 201)) {
                navigate(`/Dashboard/FavoritePropertieList/Payment/${response.data.propertyId}`, {
                    state: {
                        paymentMethod: "paymentMethod"
                    }
                })
            }
            else if (uploadImageData.length < 5) {
                toast.error("Please add minimum 5 photos")
            }
        })
    }
    const handleOpenBack = () => {
        setOpenModal(true)
    }
    const handleCloseBack = () => {
        setOpenModal(false)
    }
    const getCroppedImg = (image, crop, fileName) => {

        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        ctx.imageSmoothingQuality = "high";
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        var uri = canvas.toDataURL("image/jpeg", 1);
        return new Promise((resolve, reject) => {
            canvas.toBlob(
                function (blob) {
                    if (!blob) {
                        console.log("Canvas is empty");
                        return;
                    }
                    blob.name = fileName;
                    blob.src = uri;
                    resolve(blob);
                },
                "image/jpeg",
                1
            );
        });
    };
    const onSelectFileProfile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => setImageCrop(reader.result));
            reader.readAsDataURL(e.target.files[0]);
            setOpenImageCrop(true);
        } else {
            toast.error("ImageSizeError");
        }
    };
    const onImageLoad = (img) => {
        imgRefProfile.current = img.currentTarget
    }

    const imageCropConversionProfile = async () => {
        const cropedImage = await getCroppedImg(
            imgRefProfile.current,
            cropProfile,
            // "newFile.jpeg"
        );
        setCompletedCrop(cropedImage);
        setCropFlag(true)
    };
    const handleProfile = async (e) => {
        const imageFile = e;
        if (imageFile.size < 10000000) {
            const options = {
                maxSizeMB: 100,
                maxWidthOrHeight: 800,
                useWebWorker: true,
            };
            const compressedFile = await imageCompression(imageFile, options);
            let formData = new FormData();
            formData.append("file", compressedFile, compressedFile.name);

            postMethodWithToken(url.profileImageUpload, formData).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    let imageListIds = [...uploadImageId];
                    let imageListData = [...uploadImageData]
                    imageListIds.push(response.data.id)
                    imageListData.push({
                        file: response.data.file
                    })
                    setUploadImageId(imageListIds)
                    setUploadImageData(imageListData)
                }
            });
        }
    }
    const handleSaveExit = () => {
        navigate(`/Dashboard/homePage`)
    }
    return (
        <>
            <ContainerSection>
                <Row style={{ margin: "0" }} >
                    {/* <div className={uploadImageData.length > 0 ? 'col-lg-3' : 'col-lg-4'}>
                        <FavoriteSelect />
                    </div> */}
                    <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                        <FavoriteSelect activeStep={activeStep} value={4} />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                        <div className="upload-container">
                            <div className="upload-heading">Add some photos of your place</div>
                            <div className="upload-description">
                                <div className="upload-description-value" >Add minimum 5 photos. You can add or remove photos after the listing get published.</div>
                            </div>
                            <div className="storeImageBlock">
                                <div className="imageUpload" {...getRootProps()}>
                                    <input {...getInputProps()} onChange={(e) => onSelectFileProfile(e)} />
                                    {isDragActive ? (
                                        <p>Drop the files here ...</p>
                                    ) : (
                                        <>
                                            <div className="uploadPhoto" style={uploadImageData.length > 0 ? { display: 'flex', flexWrap: 'wrap', gap: '18px', border: 'none', justifyContent: "center" } : {}}>
                                                {uploadImageData && uploadImageData.length > 0 && uploadImageData.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div key={index} style={{ position: 'relative' }}>
                                                                <img src={item?.file} alt="" className="update-pick" />
                                                                <img src={Cross} alt="Remove" style={{ position: 'absolute', top: '5px', right: '5px', cursor: 'pointer' }} onClick={(event) => handleRemoveImage(index, event)} />
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                                {uploadImageData.length == 0 &&
                                                    <div style={{ cursor: "pointer" }} className="uploadInfo">

                                                        <div>
                                                            <div className="uploadImage"><img width="80" height="80" src={uploadIamgeIcon} /></div>
                                                        </div>
                                                        <div>
                                                            <div className="uploadText">Drag & drop your photo here</div>
                                                        </div>
                                                        <div>
                                                            <div className="uploadTextDes">Add minimum of 5 photos</div>
                                                        </div>
                                                        <div>
                                                            <div className="uploadTextDes" style={{ marginTop: "70px" }}>OR</div>
                                                        </div>
                                                        <div>
                                                            <div className="uploadTextDesLast">Browser photos from your device</div>
                                                        </div>
                                                    </div>}
                                                {uploadImageData.length > 0 &&
                                                    <div className="uploadInfotwo" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        <div>
                                                            <div className="uploadImagetwo"><img width="24" height="24" src={Add} /></div>
                                                        </div>
                                                        <div>
                                                            <div className="uploadTexttwo">Add more images</div>
                                                        </div>
                                                    </div>}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-between" style={{ margin: "20px 0 20px 30px", alignItems: "center" }} >
                            <div>
                                <ContinueButton className="back-list" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={handleOpenBack} fontSize={"20px"} fontWeight={"400"} />
                            </div>
                            <div className="d-flex justify-content-end">
                                <div style={{ paddingRight: "50px", display: "flex", alignItems: "center" }}>
                                    <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                                <div style={{ marginRight: "50px" }}>
                                    <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => handleUploadSubmit()} widht={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </ContainerSection>
            <Modal isOpen={openImageCrop} style={imageModalStyle} overlayClassName="myoverlay" effect="fadeInUp" onClickAway={() => setOpenImageCrop(false)}>
                <div style={imageModalStyle} overlayClassName="myoverlay">
                    <div style={{ marginTop: 20, marginBottom: 10, display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", }} >
                        <p style={{ marginTop: 0, fontSize: 25, fontWeight: "bold", textAlign: "center", alignItems: "center", display: "flex" }}>cropImage</p>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <div onClick={() => setOpenImageCrop(false)} style={{ width: 173, height: 50, background: "#E73D3B", display: "flex", justifyContent: "center", alignItems: "center", color: "#ffffff", fontWeight: "bold", cursor: "pointer", marginRight: 20 }}>Cancel
                            </div>

                            {completedCrop && cropFlag ? (
                                <div className="button_center">
                                    <div onClick={() => { setOpenImageCrop(false); handleProfile(completedCrop); }} style={{ width: 173, height: 50, background: "#E73D3B", display: "flex", justifyContent: "center", alignItems: "center", color: "#ffffff", fontWeight: "bold", cursor: "pointer" }}>Save</div>
                                </div>
                            ) : (
                                <div className="button_center">
                                    <div style={{
                                        width: 173, height: 50, background: "#a3a3a3", display: "flex", justifyContent: "center", alignItems: "center", color: "#ffffff", fontWeight: "bold", cursor: "pointer"
                                    }}>Save</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <ReactCrop crop={cropProfile} onChange={(c) => setCropProfile(c)} onComplete={(c) => imageCropConversionProfile(c)}>
                        <img src={imageCrop} onLoad={onImageLoad} />
                    </ReactCrop>

                </div>
            </Modal>
            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
                <div className="d-flex justify-content-center">
                    <div className="back-heading">Are you sure you want to back?</div>
                </div>
                <div className="d-flex justify-content-center" >
                    <div style={{ marginTop: "10px" }}>All the changes you will made will be discarded</div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="d-flex" style={{ marginTop: "50px" }}>
                        <div className="stay-heading" onClick={handleCloseBack}>Stay on this page</div>
                        <div>
                            <ContinueButton text="Go back" color={'#fff'} onClick={() => navigate(-1)} width={"210px"} borderRadius={"4px"} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default FavoriteUploadIamge
