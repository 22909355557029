import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import SuccessScreen from '../Components/SuccessScreen/SuccessScreen';
import CreatePassword from '../screens/CreatePassword/CreatePassword';
import ForgotPassword from '../screens/ForgotPassword/ForgotPassword';
import ResetPassword from '../screens/ResetPassword/ResetPassword';
import Dashboard from '../screens/Dashboard/Dashboard';
import Home from '../screens/Home/Home';
import Login from '../screens/Login/Login';
import Registration from '../screens/Registration/Registration';
import { getToken } from '../utils/services/common';
import FavoriteHome from '../screens/FavoritedProperties/SelectPropertyType/FavoriteHome/FavoriteHome';
import FavoritePropertyList from '../screens/FavoritedProperties/SelectPropertyType/FavoritePropertyList/FavoritePropertyList';
import FavoriteSelectBasic from '../screens/FavoritedProperties/SelectPropertyType/FavoriteSelectBasic/FavoriteSelectBasic';
import FavoriteProperrtyLocate from '../screens/FavoritedProperties/SelectPropertyType/FavoritePropertyLocate/FavoriteProperrtyLocate';
import FavoriteAmenities from '../screens/FavoritedProperties/SelectPropertyType/FavoriteAmenitie/FavoriteAmenities';
import FinalStep from '../screens/FavoritedProperties/SelectPropertyType/FinalStep/FinalStep';
import FavoriteSelect from '../screens/FavoritedProperties/SelectPropertyType/FavoriteSelect/FavoriteSelect';
import FavoriteUploadIamge from '../screens/FavoritedProperties/SelectPropertyType/FavoriteUploadImage/FavoriteUploadIamge';
import FavoriteReview from '../screens/FavoritedProperties/SelectPropertyType/FavoriteReview/FavoriteReview';
import SearchHome from "../screens/SearchList/SearchHome/SearchHome"
import HeadingSection from '../screens/LandingPage/HeadingSection/HeadingSection';
import SearchHomeDetails from "../screens/SearchList/SearchHomeDetail/SearchHomeDetails"
import ListingCardDetail from '../screens/MyListings/ListingCardDetail/ListingCardDetail';
import AddManager from '../screens/ListingManagers/AddManagers/AddManager/AddManager';
import PaymentDetail from '../screens/FavoritedProperties/SelectPropertyType/Paymethod/PaymentDetail/PaymentDetail';
import VerifyManager from "../screens/ReVerify/ReVerification";
import ReListSubscription from '../screens/MyListings/ReList/ReListSubscription/ReListSubscription';
import ReListReview from '../screens/MyListings/ReList/ReListReview/ReListReview';
import TenantProfile from '../screens/TenantManager/TenantProfile/TenantProfile';
import ChangePlan from "../screens/ManageSubscription/ChangePlan/ChangePlan"
import AddPayment from '../screens/ManageSubscription/AddPayment/AddPayment';
import FooterHomepage from "../screens/LandingPage/FooterSection/FooterSection"
import PaymentRent from '../screens/FavoritedProperties/SelectPropertyType/Paymethod/PaymentRent/PaymentRent';
import ListNameDetail from '../screens/ListingManagers/ListingManager/ListNameDetail/ListNameDetail';
import SearchMap from '../screens/SearchList/SearchMap/SearchMap';


export default function Rout() {
  const publicRoutes = [
    {
      path: "/",
      element: <Home />
    },
    {
      path: "/:value",
      element: <Home />
    },
    {
      path: "/:value/Registration",
      element: <Registration />
    },
    {
      path: "/CreatePassword",
      element: <CreatePassword />
    },
    {
      path: "/:value/ForgotPassword",
      element: <ForgotPassword />
    },
    {
      path: "/ResetPassword",
      element: <ResetPassword />
    },
    {
      path: "/:value/:childValue/Success",
      element: <SuccessScreen />
    },
    {
      path: "/:value/Login",
      element: <Login />
    },
  ]
  function PublicRoute({ element }) {
    const isLoggedIn = getToken();
    return isLoggedIn ? <Navigate to="/Dashboard/homePage" /> : element;
  }

  const privateRoutes = [
    {
      path: "/Dashboard",
      element: <Dashboard />,
      isPrivate: true,
    },
    {
      path: "/Dashboard/:value",
      element: <Dashboard />,
      isPrivate: true,
    },
    {
      path: "/Dashboard/homePage",
      element: <HeadingSection />
    },
    {
      path: "/Dashboard/SearchList",
      element: <SearchHome />
    },
    {
      path: "/Dashboard/SearchList/SearchDetail/:id",
      element: <SearchHomeDetails />
    },
    {
      path: "/Dashboard/FavoritePropertyList/PaymentDetail/:id/",
      element: <PaymentRent />
    },
    {
      path: "/Dashboard/search/PaymentDetail/:id/:search",
      element: <PaymentRent />
    },
    {
      path: "/Dashboard/list/manager/PaymentDetail/:id/:manager",
      element: <PaymentRent />
    },
    {
      path: "/Dashboard/manager/PaymentDetail/:id/:managerDetail",
      element: <PaymentRent />
    },
    {
      path: "/Dashboard/featureList/:id/:feature",
      element: <SearchHomeDetails />
    },
    {
      path: "/Dashboard/searchlist/map",
      element: <SearchMap />
    },
    {
      path: "/Dashboard/favourite/favouriteDetail/:id/:favourite",
      element: <SearchHomeDetails />
    },
    {
      path: "/Dashboard/feature/:id/:payment",
      element: <PaymentRent />
    },
    {
      path: "/Dashboard/tenant/PaymentDetail/:id/:tenantDetail",
      element: <PaymentRent />
    },
    {
      path: "/Dashboard/add/manager/details/:id/:manage",
      element: <SearchHomeDetails />
    },
    {
      path: "/Dashboard/FavoritePropertieList",
      element: <FavoriteHome />
    }
    , {
      path: "/Dashboard/FavoritePropertieList/SelectList",
      element: <FavoritePropertyList />
    },
    {
      path: "/Dashboard/FavoritePropertieList/SelectBasic/:id/:uid",
      element: <FavoriteSelectBasic />
    },
    {
      path: "/Dashboard/FavoritePropertieList/property/:id",
      element: <FavoriteProperrtyLocate />
    },
    {
      path: "/Dashboard/FavoritePropertieList/amenities/:id",
      element: <FavoriteAmenities />
    },
    {
      path: "/Dashboard/FavoritePropertieList/upload/images/:id",
      element: <FavoriteUploadIamge />
    },
    {
      path: "/Dashboard/FavoritePropertieList/Payment/:id",
      element: <FinalStep />
    },
    {
      path: "/Dashboard/FavoritePropertieList/PaymentDetail/:id",
      element: <PaymentDetail />
    },
    {
      path: "/Dashboard/FavoritePropertieList/Review/:id",
      element: <FavoriteReview />
    },
    {
      path: "/Dashboard/FavoritedProperties/Select",
      element: <FavoriteSelect />
    },
    {
      path: "Dashboard/MyListings/listingRequest",
      element: <ListingCardDetail />
    },
    {
      path: "Dashboard/ListingManagers/AddManager",
      element: <AddManager />
    },
    {
      path: "Dashboard/MyListings/relist/:id",
      element: <ReListSubscription />
    },
    {
      path: "Dashboard/MyListings/review/:id",
      element: <ReListReview />
    },
    {
      path: "Dashboard/ManageSubscription/PaymentDetail",
      element: <AddPayment />
    },
    {
      path: "Dashboard/ManageSubscription/change/plan",
      element: <ChangePlan />
    },
    {
      path: "/Dashboard/userProfile/federalEmployee/re-verify",
      element: <VerifyManager />
    },
    {
      path: "/Dashboard/TenantManagement/TenantProfile/:id",
      element: <TenantProfile />
    },
    {
      path: "/Dashboard/ListingManagers/list/detail/:id",
      element: <ListNameDetail />
    }
  ]
  function PrivateRoute({ element }) {
    const isLoggedIn = getToken();

    return isLoggedIn ? element : <Navigate to="/" />;
  }

  return (
    <>
      <Router>
        <Routes>
          {publicRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={<PublicRoute element={route.element} />} />
          ))}
          {privateRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<PrivateRoute element={route.element} />}
            />
          ))}
        </Routes>
      </Router>
    </>
  );
};
