import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import "../TenantManagerList/TenantManagerList.scss"
import { getMethodWithToken } from '../../../utils/services/apis';
import url from '../../../utils/services/urls.json';
import TenantManagerTabDetail from "../TenantManagerTabDetail/TenantManagerTabDetail";
import { navigateLogin } from '../../../utils/services/common';
import { Col, Row } from 'react-bootstrap';
import PhoneIcon from "../../../assets/images/listingManager/call.svg"
import Mail from "../../../assets/images/listingManager/mail.svg"
import Message from "../../../assets/images/listingManager/sms.svg"
import ArrowForward from "../../../assets/images/listingManager/Arrow-property.svg"
import DefaultImage from "../../../assets/images/default-image.svg"

const TenantList = styled.div`
margin-top: 30px;
width:100%;
`

const TenantManagerList = () => {
    const navigate = useNavigate()
    const [tenantDetail, setTenantDetail] = useState(false)
    const [tenantId, setTenantId] = useState('')
    const [tenantArray, setTenantArray] = useState([])
    const [tenetProfileDetail, SetTenetProfileDetail] = useState([])
    const handleListdetail = (value) => {
        setTenantDetail(true)

        let arr = [...tenantArray]
        arr.map((item, index) => (
            arr[index].isSelect = false
        ))
        const index = arr.findIndex(item => item.id === value.id);
        arr[index].isSelect = true
        setTenantArray(arr)
        setTenantId(value?.id)

    }
    const handleTenantList = async () => {
        await getMethodWithToken(url.TenantManagerList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setTenantArray(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    useEffect(() => {
        handleTenantList()
    }, [])

    useEffect(() => {
        if (tenantId) {
            handleTenantDetail()
        }
    }, [tenantId])


    const handleTenantDetail = async () => {
        await getMethodWithToken(url.TenantManagerDetail + tenantId + '/').then((response) => {
            if (response.status == 200 || response.status == 201) {
                SetTenetProfileDetail(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const handleTenantProfile = (tenantId) => {
        navigate(`/Dashboard/TenantManagement/TenantProfile/${tenantId}`)
    }

    return (
        <>
            <div className="property-manager" >
                <Row>
                    <Col xs={12} sm={12} md={7} lg={4} xl={4} xxl={4} className="manager-list-card">
                        <div className="d-flex justify-content-between manager-list">
                            <div className="manager-heading">Tenants</div>
                        </div>
                        <div className="manager-card" style={{ boxShadow: "0px 2px 8px 0px #0000001F" }}>
                            {tenantArray && tenantArray.length > 0 ?
                                tenantArray.map((data, i) => {
                                    return (
                                        <div style={{ borderBottom: data.isSelect ? "0.5px solid #2F86D1" : "0.5px solid #D9D9D9", paddingBottom: "5px", cursor: "pointer", background: data.isSelect ? "#D9EFFF" : "" }} onClick={() => handleListdetail(data)} >
                                            <div className="d-flex" style={{ paddingLeft: "15px", paddingTop: "10px" }}>
                                                <div>
                                                    <img src={data?.profilePic ? data?.profilePic : DefaultImage} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                                </div>
                                                <div className="users-heading" >
                                                    <div className="user-main-heading">{data?.name ? data?.name : data?.email}</div>
                                                    <div className="user-heading">{data?.companyName}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                )
                                :
                                <div style={{ height: "400px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "18px", fontWeight: "500" }}>No Tenants List Found</div>
                            }
                        </div>
                    </Col >
                    <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={7}>
                        {!tenantDetail ?
                            <div className="listing-manager-detail" >
                                <div className="lising-managed-heading">Tenant Details</div>
                                <div className="manager-card">
                                    <div className="select-dec">
                                        <div className="select-des-value">Select a Tenant to see their details</div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="listing-detail-data">
                                    <div className="d-flex justify-content-center listing-data" >
                                        <img src={tenetProfileDetail?.tenant_profile?.tenant?.profilePic ? tenetProfileDetail?.tenant_profile?.tenant?.profilePic : DefaultImage} alt="" className='tenantProfile' />
                                    </div>
                                    <div className="d-flex justify-content-center ">
                                        <div className="listing-data-heading">{tenetProfileDetail?.tenant_profile?.tenant?.name}</div>
                                    </div>
                                    <div className="phone-container" style={{ marginTop: "13px" }}>
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{tenetProfileDetail?.tenant_profile?.tenant?.contact_number}</div>
                                        <div><hr style={{ border: "1px solid black", height: "20px", marginLeft: "10px", marginTop: "2px" }} /></div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{tenetProfileDetail?.tenant_profile?.tenant?.email}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{tenetProfileDetail?.tenant_profile?.tenant?.contact_number}</div>
                                    </div>
                                    <div className="email-conatiner">
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{tenetProfileDetail?.tenant_profile?.tenant?.email}</div>
                                    </div>
                                    <div className="d-flex justify-content-center" style={{ marginTop: "15px", alignItems: 'center' }}>
                                        {/* <div className="msg-box" style={{ border: "1px solid #707070", background: "#ffffff", color: "#2F86D1", fontSize: "16px", fontWeight: 500, marginLeft: "15px",cursor:'pointer' }} onClick={()=>handleChatWithTenant()}>
                                <div style={{ marginRight: "10px" }}>
                                    <img src={Message} alt="" />
                                </div>
                                <div style={{ fontSize: "16px", fontWeight: 500, color: "#707070" }}>Message</div>
                            </div> */}
                                        <div className="view-heading" onClick={() => handleTenantProfile(tenantId, tenetProfileDetail?.tenant_profile?.tenant)}>View Full Profile</div>
                                        <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                    </div>
                                </div>
                                <div className="responsive-find-list">
                                    <TenantManagerTabDetail tenantId={tenantId} data={tenetProfileDetail} />
                                </div>

                            </div>
                        }
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={7} xxl={7}>
                        {tenantDetail &&
                            <div className="responsive-find-main">
                                <TenantManagerTabDetail tenantId={tenantId} data={tenetProfileDetail} />
                            </div>
                        }
                    </Col>
                </Row>
            </div>

        </>

    )
}
export default TenantManagerList