import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import GovHome from '../../assets/images/GovHomeLogo.png'
import './Registration.scss'
import FormControl from '@mui/material/FormControl';
import badge from '../../assets/icons/badge.png'
import call from '../../assets/icons/call.png'
import mail from '../../assets/icons/mail.png'
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';
import uploadImg from '../../assets/icons/upload_file.png';
import ContinueButton from '../../Components/Buttons/ContinueButton/ContinueButton';
import FormInput from '../../Components/Inputs/FormInput/FormInput';
import ChipInput from '../../Components/Inputs/ChipInput/ChipInput';
import BackButton from '../../Components/Buttons/BackButton/BackButton';
import { postMethod } from '../../utils/services/apis';
import { useDropzone } from "react-dropzone";
import { getMethod } from '../../utils/services/apis';
import UploadPhotoIcon from "../../assets/images/upload.svg"
import { toast } from 'react-toastify';
import url from '../../utils/services/urls.json';
import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from "./Dropdown";
import ArrowRight from "@mui/icons-material/ArrowRight";
import Button from "@material-ui/core/Button";
import dropdownicon from '../../assets/icons/arrow_drop_down.svg'
import { navigateLogin } from '../../utils/services/common';
import ArrowBack from "../../assets/images/arrow_back-ipad.svg"

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+1 (#00) 000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props?.name, value } })}
            overwrite
        />
    );
});

TextMaskCustom.propTypes = {
    onChange: PropTypes.func.isRequired,
};

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(telephone) => {
                onChange({
                    target: {
                        name: props?.name,
                        value: telephone.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            prefix="$"
        />
    );
});

NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
const Registration = () => {
    const navigate = useNavigate();
    const [continueButton, setcontinueButton] = useState(false);
    const params = useParams();
    const [organisation, setOrganisation] = useState('');
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [organisationList, setOrganisationList] = useState([]);
    const [orgnizationSelect, setOrganisationSelect] = useState("")
    const [profileImageUpload, setProfileImageUpload] = useState([])
    const [profileUploadId, setProfileUploadId] = useState("")
    const [showDropdown, setShowDropdown] = useState(false);
    const [organisationid, setOrganisationid] = useState();


    useEffect(() => {
        if (params.value == "federalEmployee") {
            getOrganisation()
        }
    }, []);

    const getOrganisation = async () => {
        await getMethod(url.userOrganisation).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setOrganisationList(response.data.response)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const [telephone, setTelephone] = React.useState({
        Telephone: '',
    });

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, } = useDropzone({
        accept: '.jpg, .png, .jpeg',
        onDrop: (acceptedFile) => {
            let formData = new FormData();
            acceptedFile.map((file, index) => {
                formData.append("file", file);
            });
            postMethod(url.profileImageUpload, formData).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setProfileImageUpload(response?.data?.file)
                    setProfileUploadId(response?.data?.id)

                }
            })
        }
    });

    const handleDomainName = (organization, domain) => {
        setOrganisation(organization)
        setOrganisationid(domain)
    }

    const handleOrganization = (organization) => {
        setOrganisation(organization.orgnization)
        setOrganisationid(organization.id)
    }

    const handleChange = (event) => {
        setOrganisation(event.target.value);
        const selectedValue = event.target.value;
        const selectedItem = organisationList.find((item) => item.orgnization === selectedValue);
        setOrganisationSelect(selectedItem)
    }

    const handledomainname = (key, data) => {
        setOrganisationid(data?.id)
        setOrganisation(data.orgnization)
        setShowDropdown(false);
    }
    const [tags, setTags] = useState([])

    const sendData = (data, file) => {
        setTags(data)
    }
    useEffect(() => {
        let validationEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
        if (params.value == 'federalEmployee') {

            if (name.length && validationEmail && !error && telephone.Telephone.length == 17 && organisationid) {
                setcontinueButton(true)
            } else {
                setcontinueButton(false)
            }
        } else if (params.value == 'propertyManager') {
            if (name.length && !error && telephone.Telephone.length == 17) {
                setcontinueButton(true)
            } else {
                setcontinueButton(false)
            }
        }
    }, [name, email, telephone, organisation, error])

    const getFormData = (event) => {
        event.preventDefault()
        const payloadFederal = {
            name: name,
            email: email,
            contact_number: telephone.Telephone,
            orgnization: organisationid,
            role: "user_role_federal",
            profilePic: profileUploadId
        }
        const payloadProperty = {
            name: name,
            email: email,
            contact_number: telephone.Telephone,
            role: "user_role_property_manager",
            companyName: "ABC",
            realEstateLicence: 1,
        };
        let validationEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
        if (params.value == 'federalEmployee') {
            if (name.length && validationEmail && !error && telephone.Telephone.length == 17 && organisation.length) {
                register(payloadFederal)
            }
        }
        else if (params.value == 'propertyManager') {
            if (name.length && validationEmail && !error && telephone.Telephone.length == 17) {
                register(payloadProperty)
            }
        }
    };
    const handleTelephone = (event) => {
        setTelephone({
            ...telephone,
            ['Telephone']: event.target.value,
        });
    };

    const isGovMilEmail = (value) => {
        if (!value) {
            return true;
        }
        const lowerCaseEmail = value.toLowerCase();
        // Check if the email ends with .gov or .mil
        // if (!lowerCaseEmail.endsWith('.gov') && !lowerCaseEmail.endsWith('.mil')) {
        //     return false; // Validation error if not .gov or .mil
        // }
        return true;
    };
    const handleEmail = (e) => {
        const { value } = e.target;
        setEmail(value);
    };

    const isValidName = (input) => {
        const regex = /^[a-zA-Z\s_]+$/;
        return regex.test(input);
    };

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);
        setNameError(!isValidName(newName));
    };

    const register = async (body) => {
        await postMethod(url.registerApi, body).then((response) => {
            let federalValue = { heading: "Verification mail sent", title: "An verification link has been sent to the entered email address. Verify your email to continue the registration process" }
            let propertyValue = { heading: "Request Received", title: "We are reviewing your request. You’ll be notified when your request is approved." }
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.success)
                if (params.value == 'federalEmployee') {
                    let keysToRemove = ["bedroom", "zipcode", "bathroom", "Id", "furnishing", "amenities", "shortItem", "city", "furnishingid", "name", "balcony", "otherpet", "pets", "selectedpets", "parkingname", "parkingid", "searchvalue", "direction", "petid", "state", "propertysize", "locality", "floorno", "PropertyType", "totalfloor", "totalfloorid"]
                    keysToRemove.forEach(k =>
                        localStorage.removeItem(k))
                    navigate(`/${params.value}/Registration/Success`, { state: federalValue })
                } else if (params.value == 'propertyManager') {
                    navigate(`/${params.value}/Registration/Success`, { state: propertyValue })
                }
            } else if (profileUploadId == '') {
                toast.error("Please Upload Profile Image")
            }
            else {
                toast.error(response?.data?.message)
            }
        })
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    return (
        <>
            <div className="registration">
                <div className="middleContent " >
                    <div>
                        <img className="arrow-back" src={ArrowBack} alt="" onClick={() => navigate(-1)} style={{ cursor: "pointer" }} />
                    </div>
                    <div className="logo">
                        <img className="logoImg" src={GovHome} alt="" />
                    </div>
                    <div className="mainFields p-2">
                        <h3 className='text-center typeHeding' style={{ fontWeight: 600, }}>Register Here</h3>
                        <div className='text-center' style={{ marginTop: "20px" }} >
                            <div>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {isDragActive ? (
                                        <p>Drop the files here ...</p>
                                    )
                                        :
                                        (
                                            <>
                                                {profileImageUpload && profileImageUpload.length > 0 ?
                                                    (
                                                        <div>
                                                            <img style={{ width: "127px", height: "127px", border: "1px solid black", boxShadow: ' 1px 4px #bdbbbb', borderRadius: "50%" }} src={profileImageUpload} alt="" />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <div className="uploadPhoto">
                                                            <div className="uploadInfo">
                                                                <div style={{ marginTop: "30px" }}><img src={UploadPhotoIcon} /></div>
                                                                <div className="uploadText">Upload Image</div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <form onSubmit={getFormData} style={{ maxWidth: "797px" }}>
                            <FormInput error={nameError}
                                helperText={nameError ? 'Invalid characters in name' : ''} required={true} name={'Name'} id={'name'} type={'text'} value={name} onChange={(e) => handleNameChange(e)} icon={`${badge}`} ></FormInput>
                            <Row >
                                <Col sm={6} >
                                    <FormInput required={true} name={'Email'} id={'email'} type={'email'} value={email} error={error}
                                        helperText={error ? 'Please enter a valid .gov or .mil email address.' : ''} onChange={(e) => handleEmail(e)} icon={`${mail}`}></FormInput>
                                </Col>
                                <Col sm={6} >
                                    <FormInput required={true} name={'Telephone'} id={'telephone'} value={telephone.Telephone} onChange={handleTelephone} icon={`${call}`} inputComponent={TextMaskCustom}></FormInput>
                                </Col>
                            </Row>
                            <div className='federalEmployee_organization'>
                                {organisationList && organisationList?.length > 0 ?
                                    <Dropdown trigger={
                                        <div style={{ display: 'flex' }}>
                                            <FormInput
                                                className='bg-inputbg'
                                                id="organisation-input"
                                                type="text"
                                                value={organisation}
                                                name={'Organization'}
                                                onChange={(e) => setOrganisation(e.target.value)} icon={`${dropdownicon}`}
                                            />
                                            <Button className='bg-inputbg' style={{ display: "none" }} disabled={false}>Organisation</Button>
                                        </div>

                                    }
                                        menu={
                                            organisationList && organisationList.length > 0 && organisationList.map((data, key) => (
                                                <DropdownNestedMenuItem
                                                    label={data?.orgnization} style={{ padding: '10px' }}
                                                    rightIcon={data?.child_orgnization?.length > 0 && <ArrowRight />}
                                                    onClick={() => { handleOrganization(data) }}
                                                    menu={
                                                        data?.child_orgnization?.length > 0 && data?.child_orgnization.map((item, key) => (
                                                            <DropdownMenuItem style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                                                <Button
                                                                    component="label"
                                                                    sx={{
                                                                        color: "#000",
                                                                        textTransform: "none",
                                                                        fontSize: "1rem"
                                                                    }}
                                                                    variant="text"
                                                                >
                                                                    {item?.domainname}
                                                                    <input
                                                                        id="mdInput"
                                                                        type="text"
                                                                        accept={`.md`}
                                                                        hidden
                                                                        onClick={() => { handleDomainName(data?.orgnization, item?.id) }}
                                                                    />
                                                                </Button>
                                                            </DropdownMenuItem>
                                                        ))
                                                    }
                                                />
                                            ))
                                        }
                                    />
                                    :
                                    <>
                                        <FormInput
                                            className='bg-inputbg'
                                            id="organisation-input"
                                            type="text"
                                            value={organisation}
                                            name={'Organization'}
                                            onChange={(e) => setOrganisation(e.target.value)} icon={`${dropdownicon}`}
                                        />
                                        <Button className='bg-inputbg' style={{ display: "none" }} disabled={false}>Organisation</Button>
                                    </>
                                }
                            </div>
                            <div >
                                <ContinueButton className={continueButton && profileImageUpload && profileImageUpload.length > 0 ? "continue" : "continueDisabled"} backgroundColor={continueButton && profileImageUpload && profileImageUpload.length > 0 ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} disabled={!continueButton} text={'Continue'} height={'50px'} color={'#fff'} width={"200px"} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <BackButton send={-1} text={'Register Here'} backText={'Back'}></BackButton> */}
            <BackButton className="back" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={() => navigate(-1)} />
        </>
    )
}
export default Registration;





