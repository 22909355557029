import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { InputAdornment, } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import "./FindPeopleTab.scss";
import { getMethodWithToken, postMethodWithToken, postMethodWithTokenWithBody } from '../../../utils/services/apis';
import url from "../../../utils/services/urls.json"
import FindPeopleList from '../FindPeopleList/FindPeopleList';
import MyFriendList from '../MyFriendList/MyFriendList';
import ReceiveList from '../ReceiveList/ReceiveList';
import SendRequest from '../SendRequest/SendRequest';
import PhoneIcon from "../../../assets/images/listingManager/call.svg"
import Mail from "../../../assets/images/listingManager/mail.svg"
import Message from "../../../assets/images/listingManager/add-manager.svg"
import MessageBox from "../../../assets/images/listingManager/sms.svg"
import requestSend from "../../../assets/images/listingManager/request-send.svg"
import Reject from "../../../assets/images/listingManager/reject.svg"
import Accept from "../../../assets/images/listingManager/accept.svg"
import { toast } from 'react-toastify';
import DefaultImage from "../../../assets/images/default-image.svg"

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ marginTop: "-5px" }}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const FindPeopleTab = ({ myContactModal }) => {
    const [value, setValue] = useState(0);
    const [searchInput, setSearchInput] = useState("")
    const [searchInputMyFriend, setSearchInputMyFriend] = useState("")
    const [checked, setChecked] = useState(false)
    const [searchListPeople, setSearchListPeople] = useState([])
    const [myFirendList, setMyFriendList] = useState([])
    const [receivedList, setReceivedList] = useState([])
    const [sendRequest, setSendRequest] = useState([])
    const [searchId, setSearchId] = useState("")
    const [searchMyListId, setSearchMyListId] = useState("")
    const [listDetail, setListDetail] = useState(false)
    const [findDetailList, setFindDetailList] = useState([])
    const [modalShow, setModalShow] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setListDetail(false)
        setSearchId("")
        setSearchMyListId("")
    }

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value)
    }

    const handleCheck = (event) => {
        setChecked(event.target.checked)
    }
    useEffect(() => {
        if (value === 0) {
            getFindPeopleList()
        }
    }, [checked, value])

    const getFindPeopleList = async () => {
        let urls;
        if (checked) {
            urls = url.findPeopleList + "?is_organization=yes" + "&search=" + searchInput;
        } else {
            urls = url.findPeopleList + "?is_organization=" + "&search=" + searchInput;
        }
        await getMethodWithToken(urls).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setSearchListPeople(response?.data)
            }
        })
    }
    const handleSearchId = (data) => {
        setSearchId(data?.id)
        setListDetail(true)
    }
    const handleSearchValue = () => {
        getFindPeopleList()
    }

    useEffect(() => {
        if (searchId) {
            getFindPeopleListDetail()
        }
    }, [searchId])

    const getFindPeopleListDetail = async () => {
        await getMethodWithToken(url.findPeopleListData + searchId + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                setFindDetailList(response?.data)
            }
        })
    }

    useEffect(() => {
        if (value === 1) {
            getMyFrinedList()
        } else if (value === 2) {
            getReceivedList()
        } else if (value === 3) {
            getSendRequestList()
        }
    }, [value])

    const getMyFrinedList = async () => {
        await getMethodWithToken(url.myFirnedList + "?search=" + searchInputMyFriend).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMyFriendList(response?.data)
            }
        })
    }

    const getReceivedList = async () => {
        await getMethodWithToken(url.receivedList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setReceivedList(response?.data)
            }
        })
    }

    const getSendRequestList = async () => {
        await getMethodWithToken(url.sendRequestList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setSendRequest(response?.data)
            }
        })
    }

    const handleSearchInputMyFriendChange = (event) => {
        setSearchInputMyFriend(event?.target?.value)
    }
    const handleSearchMyFriendValue = () => {
        getMyFrinedList()
    }
    const handleSearchMyListId = (data) => {
        setSearchMyListId(data?.id)
        setListDetail(true)
    }

    const handleRequestSend = async () => {
        let body = {
            "receiver": findDetailList?.id
        }
        await postMethodWithToken(url.findPeopleListData, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.message)
                getFindPeopleListDetail()
            } else {
                toast.error(response?.data?.message)
            }
        })
    }

    const handleAcceptRequest = async () => {
        await postMethodWithTokenWithBody(url.postAcceptRequest + "request/" + findDetailList?.friend_request?.friend_request_id + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.message)
                // getReceivedList()
                // setSearchId("")
            }
        })
    }
    const handleModalValue = (val) => {
        setModalShow(val)
    }

    return (
        <>
            {/* <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}> */}
            <div className="find-people-tab" style={{ background: (!modalShow && !myContactModal) ? "rgb(242, 242, 247)" : "", zIndex: (!modalShow && !myContactModal) ? "1" : "" }}>
                <div style={{ marginTop: "20px", marginBottom: "25px" }}>
                    <Tabs value={value} onChange={handleChange}  >
                        <Tab label="Find People" {...a11yProps(0)} />
                        <Tab label="My Friends" {...a11yProps(1)} />
                        <Tab label="Received Requests" {...a11yProps(2)} />
                        <Tab label="Sent Requests" {...a11yProps(3)} />
                    </Tabs>
                </div>
            </div>
            {/* </Col>
            </Row> */}
            <TabPanel value={value} index={0}>
                <Row>
                    <Col xs={12} sm={12} md={7} lg={4} xl={4} xxl={4}>
                        <div className="find-people-search">
                            <div>
                                <TextField
                                    fullWidth
                                    name="search"
                                    id="outlined-basic"
                                    label="Search..."
                                    variant="outlined"
                                    value={searchInput}
                                    onChange={(e) => handleSearchInputChange(e)}
                                    style={{ background: "#ffffff", zIndex: "0" }}
                                    InputLabelProps={{
                                        style: { color: "#909090" }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <div className="search-btn" onClick={() => handleSearchValue()}>
                                                        <div>
                                                            <SearchIcon style={{ color: "white", width: "20px", height: "20px" }} />
                                                        </div>
                                                        <div style={{ color: "white", fontSize: "16px", fontWeight: 500, paddingLeft: "5px" }} >Search</div>
                                                    </div>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                            <div className="d-flex mt-4 search-check">
                                <FormCheckInput type="checkbox" name="verifyFed" id="verifyFed" style={{ width: "18px", height: "18px", border: " 1px solid #505050" }} onChange={handleCheck} />
                                <label className="ps-2 check-list text-start" htmlFor="verifyFed">Search for people only in my organization</label>
                            </div>
                            <Row>
                                <Col >
                                    <div className={searchListPeople && searchListPeople.length > 0 ? "search-find-list" : "manager-card-without-result"}>
                                        {searchListPeople && searchListPeople.length > 0 ?
                                            searchListPeople.map((data, index) => {
                                                return (
                                                    <div className="d-flex" style={{ paddingLeft: "15px", paddingTop: "10px", paddingBottom: "10px", background: searchId === data.id ? "#D9EFFF" : "", cursor: "pointer", borderBottom: searchId === data.id && "1px solid #40A0E5" }} onClick={() => handleSearchId(data)}>
                                                        <div>
                                                            <img src={data?.profilePic?.file ? data?.profilePic?.file : DefaultImage} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                                        </div>
                                                        <div className="find-heading" >
                                                            <div className="find-main-heading">{data?.name}</div>
                                                            <div className="find-description">{data?.orgnization}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="result-heading" >No results found</div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={7} className="p-sm-0 p-md-0">
                        {listDetail &&
                            <div>
                                <div className="find-people-listing-data">
                                    <div className="d-flex justify-content-center listing-data" >
                                        <img src={findDetailList?.profilePic?.file ? findDetailList?.profilePic?.file : DefaultImage} alt="profilePic" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                                    </div>
                                    <div className="d-flex justify-content-center ">
                                        <div className="listing-data-heading">{findDetailList?.name}</div>
                                    </div>
                                    <div className="phone-container" style={{ marginTop: "13px" }}>
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.contact_number}</div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.email}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.contact_number}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.email}</div>
                                    </div>

                                    <div className="d-flex justify-content-center" style={{ marginTop: "20px" }}>
                                        {findDetailList?.friend_request === null ?
                                            <div className="msg-box" onClick={() => handleRequestSend()}>
                                                <div style={{ marginRight: "10px" }}>
                                                    <img src={Message} alt="" />
                                                </div>
                                                <div style={{ fontSize: "16px", fontWeight: 500, color: "#ffffff" }}>Add as friend</div>
                                            </div>
                                            :
                                            findDetailList?.friend_request?.accepted === true ?
                                                <div className="msg-request-box">
                                                    <div style={{ marginRight: "10px" }}>
                                                        <img src={requestSend} alt="" />
                                                    </div>
                                                    <div style={{ fontSize: "16px", fontWeight: 500, color: "#0075CA" }}>Added as Friend</div>
                                                </div>
                                                :
                                                <div className="msg-request-box">
                                                    <div style={{ marginRight: "10px" }}>
                                                        <img src={requestSend} alt="" />
                                                    </div>
                                                    <div style={{ fontSize: "16px", fontWeight: 500, color: "#0075CA" }}>Request Sent</div>
                                                </div>

                                        }
                                        <div className="msg-box" style={{ border: "1px solid #E0E0E0", background: "#ffffff", color: "#2F86D1", fontSize: "16px", fontWeight: 500, marginLeft: "25px" }} >
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={MessageBox} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#707070" }}>Message</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="responsive-find-list">
                                    <FindPeopleList searchId={searchId} data={findDetailList} modalValue={(val) => handleModalValue(val)} />
                                </div>
                            </div>

                        }
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
                        {listDetail &&
                            <div className="responsive-find-main">
                                <FindPeopleList searchId={searchId} data={findDetailList} modalValue={(val) => handleModalValue(val)} />
                            </div>
                        }
                    </Col>
                </Row>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Row>
                    <Col xs={12} sm={12} md={7} lg={4} xl={4} xxl={4}>
                        <div className="find-people-search">
                            <div>
                                <TextField
                                    fullWidth
                                    name="search"
                                    id="outlined-basic"
                                    label="Search in friends list..."
                                    variant="outlined"
                                    value={searchInputMyFriend}
                                    onChange={(e) => handleSearchInputMyFriendChange(e)}
                                    style={{ background: "#ffffff", zIndex: "0" }}
                                    InputLabelProps={{
                                        style: { color: "#909090" }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <div className="search-btn" onClick={() => handleSearchMyFriendValue()}>
                                                        <div>
                                                            <SearchIcon style={{ color: "white", width: "20px", height: "20px" }} />
                                                        </div>
                                                        <div style={{ color: "white", fontSize: "16px", fontWeight: 500, paddingLeft: "5px" }} >Search</div>
                                                    </div>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                            <Row>
                                <Col >
                                    <div className={myFirendList && myFirendList.length > 0 ? "search-my-list" : "manager-card-without-result"}>
                                        {myFirendList && myFirendList.length > 0 ?
                                            myFirendList.map((data, index) => {
                                                return (
                                                    <div className="d-flex" style={{ paddingLeft: "15px", paddingTop: "10px", paddingBottom: "10px", background: searchId === data.id ? "#D9EFFF" : "", cursor: "pointer", borderBottom: searchId === data.id && "1px solid #40A0E5" }} onClick={() => handleSearchId(data)}>
                                                        <div>
                                                            <img src={data?.profilePic?.file ? data?.profilePic?.file : DefaultImage} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                                        </div>
                                                        <div className="find-heading" >
                                                            <div className="find-main-heading">{data?.name}</div>
                                                            <div className="find-description">{data?.orgnization}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="result-heading" >No results found</div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={7} className="p-sm-0 p-md-0">
                        {listDetail &&
                            <div>
                                <div className="find-people-listing-data">
                                    <div className="d-flex justify-content-center listing-data" >
                                        <img src={findDetailList?.profilePic?.file ? findDetailList?.profilePic?.file : DefaultImage} alt="profilePic" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                                    </div>
                                    <div className="d-flex justify-content-center ">
                                        <div className="listing-data-heading">{findDetailList?.name}</div>
                                    </div>
                                    <div className="phone-container" style={{ marginTop: "13px" }}>
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.contact_number}</div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.email}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.contact_number}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.email}</div>
                                    </div>
                                    <div className="phone-container" style={{ marginTop: "20px" }}>
                                        <div className="msg-request-box">
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={requestSend} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#0075CA" }}>Added as Friend</div>
                                        </div>
                                        <div className="msg-box" style={{ border: "1px solid #E0E0E0", background: "#ffffff", color: "#2F86D1", fontSize: "16px", fontWeight: 500, marginLeft: "15px" }} >
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={MessageBox} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#707070" }}>Message</div>
                                        </div>
                                    </div>
                                    <div className="email-conatiner mt-3">
                                        <div className="msg-request-box">
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={requestSend} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#0075CA" }}>Added as Friend</div>
                                        </div>
                                    </div>
                                    <div className="email-conatiner mt-3">
                                        <div className="msg-box" style={{ border: "1px solid #E0E0E0", background: "#ffffff", color: "#2F86D1", fontSize: "16px", fontWeight: 500 }} >
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={MessageBox} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#707070" }}>Message</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="responsive-find-list">

                                    <MyFriendList searchId={searchId} data={findDetailList} modalValue={(val) => handleModalValue(val)} />
                                </div>

                            </div>
                        }
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
                        {listDetail &&
                            <div className="responsive-find-main">
                                <MyFriendList searchId={searchId} data={findDetailList} modalValue={(val) => handleModalValue(val)} />
                            </div>
                        }
                    </Col>
                </Row>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Row>
                    <Col xs={12} sm={12} md={7} lg={4} xl={4} xxl={4}>
                        <div className="find-people-search">
                            <Row>
                                <Col >
                                    <div className={receivedList && receivedList.length > 0 ? "search-find-list" : "manager-card-without-result"}>
                                        {receivedList && receivedList.length > 0 ?
                                            receivedList.map((data, index) => {
                                                return (
                                                    <div className="d-flex" style={{ paddingLeft: "15px", paddingTop: "10px", paddingBottom: "10px", background: searchId === data.id ? "#D9EFFF" : "", cursor: "pointer" }} onClick={() => handleSearchId(data)}>
                                                        <div>
                                                            <img src={data?.profilePic?.file ? data?.profilePic?.file : DefaultImage} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                                        </div>
                                                        <div className="find-heading" >
                                                            <div className="find-main-heading">{data?.name}</div>
                                                            <div className="find-description">{data?.orgnization}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="result-heading" >No results found</div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={7} className="p-sm-0 p-md-0">
                        {listDetail &&
                            <div>
                                <div className="find-people-listing-data">
                                    <div className="d-flex justify-content-center listing-data" >
                                        <img src={findDetailList?.profilePic?.file ? findDetailList?.profilePic?.file : DefaultImage} alt="profilePic" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                                    </div>
                                    <div className="d-flex justify-content-center ">
                                        <div className="listing-data-heading">{findDetailList?.name}</div>
                                    </div>
                                    <div className="phone-container" style={{ marginTop: "13px" }}>
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.contact_number}</div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.email}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.contact_number}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.email}</div>
                                    </div>
                                    <div className="phone-container" style={{ marginTop: "20px" }}>
                                        <div className="msg-request-box-receive" onClick={() => handleAcceptRequest()}>
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={Accept} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#ffffff" }}>Accept Request</div>
                                        </div>
                                        <div className="msg-box" style={{ border: "1px solid #E0E0E0", background: "#ffffff", color: "#2F86D1", fontSize: "16px", fontWeight: 500, marginLeft: "15px" }} >
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={Reject} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#707070" }}>Reject Request</div>
                                        </div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div className="msg-request-box-receive" onClick={() => handleAcceptRequest()}>
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={Accept} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#ffffff" }}>Accept Request</div>
                                        </div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div className="msg-box" style={{ border: "1px solid #E0E0E0", background: "#ffffff", color: "#2F86D1", fontSize: "16px", fontWeight: 500, marginLeft: "15px" }} >
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={Reject} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#707070" }}>Reject Request</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="responsive-find-list">
                                    <ReceiveList searchId={searchId} data={findDetailList} modalValue={(val) => handleModalValue(val)} />
                                </div>
                                {/* <ReceiveList searchId={searchId} /> */}
                            </div>
                        }
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        {listDetail &&
                            <div className="responsive-find-main">
                                <ReceiveList searchId={searchId} data={findDetailList} modalValue={(val) => handleModalValue(val)} />
                            </div>
                        }
                    </Col>
                </Row>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Row>
                    <Col xs={12} sm={12} md={7} lg={4} xl={4} xxl={4}>
                        <div className="find-people-search">
                            <Row>
                                <Col >
                                    <div className={sendRequest && sendRequest.length > 0 ? "search-receive-list" : "manager-card-without-result"}>
                                        {sendRequest && sendRequest.length > 0 ?
                                            sendRequest.map((data, index) => {
                                                return (
                                                    <div className="d-flex" style={{ paddingLeft: "15px", paddingTop: "10px", paddingBottom: "10px", background: searchId === data.id ? "#D9EFFF" : "", cursor: "pointer" }} onClick={() => handleSearchId(data)}>
                                                        <div>
                                                            <img src={data?.profilePic?.file ? data?.profilePic?.file : DefaultImage} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                                        </div>
                                                        <div className="find-heading" >
                                                            <div className="find-main-heading">{data?.name}</div>
                                                            <div className="find-description">{data?.orgnization}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="result-heading" >No results found</div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={7} className="p-sm-0 p-md-0">
                        {listDetail &&
                            <div>
                                <div className="find-people-listing-data">
                                    <div className="d-flex justify-content-center listing-data" >
                                        <img src={findDetailList?.profilePic?.file ? findDetailList?.profilePic?.file : DefaultImage} alt="profilePic" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                                    </div>
                                    <div className="d-flex justify-content-center ">
                                        <div className="listing-data-heading">{findDetailList?.name}</div>
                                    </div>
                                    <div className="phone-container" style={{ marginTop: "13px" }}>
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.contact_number}</div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.email}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.contact_number}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{findDetailList?.email}</div>
                                    </div>
                                    <div className="phone-container" style={{ marginTop: "20px" }}>
                                        <div className="msg-request-box">
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={requestSend} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#0075CA" }}>Request Sent</div>
                                        </div>
                                        <div className="msg-box" style={{ border: "1px solid #E0E0E0", background: "#ffffff", color: "#2F86D1", fontSize: "16px", fontWeight: 500, marginLeft: "15px" }} >
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={MessageBox} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#707070" }}>Message</div>
                                        </div>
                                    </div>
                                    <div className="email-conatiner mt-3">
                                        <div className="msg-request-box">
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={requestSend} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#0075CA" }}>Request Sent</div>
                                        </div>
                                    </div>
                                    <div className="email-conatiner mt-3">
                                        <div className="msg-box" style={{ border: "1px solid #E0E0E0", background: "#ffffff", color: "#2F86D1", fontSize: "16px", fontWeight: 500, }} >
                                            <div style={{ marginRight: "10px" }}>
                                                <img src={MessageBox} alt="" />
                                            </div>
                                            <div style={{ fontSize: "16px", fontWeight: 500, color: "#707070" }}>Message</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="responsive-find-list">
                                    <SendRequest searchId={searchId} data={findDetailList} modalValue={(val) => handleModalValue(val)} />
                                </div>
                                {/* <SendRequest searchId={searchId} /> */}
                            </div>
                        }
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        {listDetail &&
                            <div className="responsive-find-main">
                                <SendRequest searchId={searchId} data={findDetailList} modalValue={(val) => handleModalValue(val)} />
                            </div>
                        }
                    </Col>
                </Row>
            </TabPanel>

        </>
    )
}

export default FindPeopleTab
