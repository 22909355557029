import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import GovHome from '../../assets/images/GovHomeLogo.png';
import '../Registration/Registration.scss';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import ContinueButton from '../../Components/Buttons/ContinueButton/ContinueButton';
import FormInput from '../../Components/Inputs/FormInput/FormInput';
import url from '../../utils/services/urls.json';
import { postMethod } from '../../utils/services/apis';
import '../ForgotPassword/ForgotPassword';
import { getParam } from '../../utils/services/common';
import { toast } from 'react-toastify';
import Modal from "react-modal";
import CloseModal from "../../assets/images/searchproperty/close.svg"

const ResetPassword = () => {
    const navigate = useNavigate();
    const [continueButton, setcontinueButton] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [openModal, setOpenModal] = useState(false)
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const paramValue = getParam();
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };
    const handleCloseModal = () => {
        setOpenModal(false)
    }
    const handleOkayModal = () => {
        navigate(`/${paramValue}/ForgotPassword`)
    }
    const handleCancelModal = () => {
        navigate(`/${paramValue}/Login`)
    }
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    const getFormData = (event) => {
        event.preventDefault()
        const payload = {
            password: password,
            token: token,
        }
        resetPaaword(payload)

    };

    const customStyles = {
        content: {
            top: '25%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: "25vw",
            height: "225px",
            fontFamily: 'Proxima_nova_reg',
        },
    };
    const resetPaaword = async (body) => {
        const result = await postMethod(url.setforgotpassword, body)
        let successValue = { heading: "New Password Set", title: "Your new password has been set successfully." }
        if ((password.length >= 6 && confirmPassword.length >= 6) && password == confirmPassword) {
            if (result.status == 200 || result.status == 201) {
                navigate(`/${paramValue}/ResetPassword/Success`, { state: successValue })
                toast.success(result.data.success)
            } else if (result.status == 409) {
                setOpenModal(true)
            }
        }
    }
    useEffect(() => {
        if ((password.length >= 6 && confirmPassword.length >= 6) && password == confirmPassword) {
            setcontinueButton(true)
        } else {
            setcontinueButton(false)
        }
    }, [password, confirmPassword])

    return (
        <>
            <div className="registration">
                <div className="middleContent " >
                    <div className="logo">
                        <img className="logoImg" src={GovHome} alt="" />
                    </div>
                    <div className="mainFields p-2">
                        <div>
                            <Modal style={customStyles} isOpen={openModal} overlayClassName="myoverlay"  >
                                <div className="modal-container">
                                    <div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', paddingBottom: '10px' }}>
                                            <h2>Sorry</h2>
                                            <img src={CloseModal} alt='cross' onClick={handleCloseModal} />
                                        </div>
                                        <p style={{ fontSize: '20px' }}>The link for reset password has expired. Would you like to regenerate the link?</p>
                                        <div style={{ display: 'flex', gap: '10px', float: 'right' }}>
                                            <button onClick={handleOkayModal} className='buttonModal' style={{ padding: "5px 20px 5px 20px", border: 'none', borderRadius: '20px', color: 'white', background: '#1888d8' }}>Okay</button>
                                            <button onClick={handleCancelModal} className='buttonModal' style={{ padding: "5px 20px 5px 20px", border: 'none', borderRadius: '20px', color: 'white', background: '#dc1c1c' }}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                        <h3 className='text-center typeHeding' style={{ fontWeight: 600 }}>Reset Password</h3>
                        <form onSubmit={getFormData} style={{ marginTop: '30px' }}>
                            <FormInput required={true} name={'New Password'} id={'password'} type={showPassword ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} showIcon={showPassword ? <VisibilityOff /> : <Visibility />}
                                onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} ></FormInput>
                            <FormInput required={true} name={'Confirm New Password'} id={'confirmPassword'} type={showConfirmPassword ? 'text' : 'password'} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} showIcon={showPassword ? <VisibilityOff /> : <Visibility />}
                                onClick={handleClickShowConfirmPassword} onMouseDown={handleMouseDownConfirmPassword} ></FormInput>
                            <div  >
                                <ContinueButton className={continueButton ? "continue" : "continueDisabled"} backgroundColor={continueButton ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} disabled={!continueButton} text={'Register'} height={'50px'} color={'#fff'} width={"200px"} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ResetPassword
