// import { MenuItem, Select } from '@mui/base'
import { InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'

const SelectOption = (props) => {
    return (
        <>
            <InputLabel id={props.id}>{props.name}</InputLabel>
            <Select
                labelId="demo-simple-select-standard-label"
                id={props.id}
                value={props.value}
                required={props.required}
                disabled={props.disabled}
                onChange={props.onChange}
                label={props.name}

                style={{ color: props.color , fontWeight:props.fontWeight}}
                
            >
                {!props?.options?.length > 0 &&
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                }
                {props.options.map((data,key) => (
                    <MenuItem data-key={data.id} value={data.orgnization} >{data.orgnization}</MenuItem>
                ))}
            </Select>
        </>
    )
}

export default SelectOption
