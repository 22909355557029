import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useParams, useNavigate, json } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import FormControl from '@mui/material/FormControl';
// import { TextField } from '@mui/material';
import TextField from '@mui/material/TextField';
import { InputLabel, MenuItem, Select } from '@mui/material'
// import SelectOption from '../../../../Components/Inputs/SelectOption/SelectOption';
import FavoriteSelect from "../FavoriteSelect/FavoriteSelect"
import './FavoriteSelectBasic.scss'
import FormInput from "../../../../Components/Inputs/FormInput/FormInput"
// import AddIcon from '@mui/icons-material/Add';
// import RemoveIcon from '@mui/icons-material/Remove';
import { getMethodWithToken, postMethodWithToken } from '../../../../utils/services/apis';
import url from "../../../../utils/services/urls.json"
import ContinueButton from "../../../../Components/Buttons/ContinueButton/ContinueButton";
import Modal from "react-modal"
import { toast } from 'react-toastify';
import { navigateLogin } from '../../../../utils/services/common';
import { makeStyles } from '@material-ui/core/styles';

const ContainerSection = styled.div`
font-family: Proxima Nova;

`

const useStyles = makeStyles((theme) => ({
    textField: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#AAAAAA",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#AAAAAA",
                borderWidth: "1px"
            },
            "&:hover fieldset": {
                borderColor: "#AAAAAA",
            },
        },
    },
}))
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "700px",
        height: "230px",
    },
};

// const checkEmptyKeys = () => {
//     const emptyKeys = [
//         "name",
//         "bedroomCount",
//         "bathroomCount",
//         "balconyCount",
//         "propertySize",
//         "furnishing",
//         "parking",
//         "floorNo",
//         "totalFloor",
//         "storiesName"

//     ];
//     const emptyKeysErrors = [
//         'Name',
//         'BedRoom',
//         'BathRoom',
//         'Balcony',
//         'Property Size',
//         'Furnishing',
//         'Parking',
//         'Floor No',
//         'Total Floor',
//     ];
//     for (let i = 0; i < emptyKeys.length; i++) {
//         if (emptyKeys[i] === 'bedroomCount') {
//             return `Please Fill In ${emptyKeysErrors[i]}`;
//         }
//         if (emptyKeys[i] === 'bathroomCount') {
//             return `Please Fill In ${emptyKeysErrors[i]}`;
//         }
//         if (emptyKeys[i] === 'balconyCount') {
//             return `Please Fill In ${emptyKeysErrors[i]}`;
//         }
//         if (emptyKeys[i] === 'propertySize') {
//             return `Please Fill In ${emptyKeysErrors[i]}`;
//         }
//         if (emptyKeys[i] === 'furnishing') {
//             return `Please Fill In ${emptyKeysErrors[i]}`;
//         }
//         if (emptyKeys[i] === 'floorNo') {
//             return `Please Fill In ${emptyKeysErrors[i]}`;
//         }
//     }
// }

const FavoriteSelectBasic = () => {
    const navigate = useNavigate()
    const param = useParams()
    const classes = useStyles();
    const [name, setName] = useState("");
    const [propertySize, setPropertySize] = useState("");
    const [furnishing, setFurnishing] = useState("");
    const [furnishingId, setFurnishingId] = useState("");
    const [furnishingType, setFurnishingType] = useState([])
    const [parking, setParking] = useState("");
    const [parkingId, setParkingId] = useState("")
    const [parkingType, setParkingType] = useState([])
    const [floorNo, setFloorNo] = useState("")
    const [floorId, setFloorId] = useState("")
    const [stories, setStories] = useState([])
    const [storiesName, setStoriesName] = useState('')
    const [floorNoType, setFloorNoType] = useState([])
    const [totalFloor, setTotalFloor] = useState("")
    const [pets, setPets] = useState([]);

    const [petsid, setPetsid] = useState([]);
    const [bedroomCount, setBedroomCount] = useState("");
    const [bathroomCount, setBathroomCount] = useState("");
    const [bathroomSelect, setBathroomSelect] = useState("")
    const [bathroomId, setBathroomId] = useState("");
    const [balconyCount, setBalconyCount] = useState(parseInt(localStorage.getItem("balcony")) || "");
    const [openModal, setOpenModal] = useState(false);
    const [isPropertySize, setIsPropertySizeEmpty] = useState(false);
    const [isNumberOfStore, setIsNumberOfStore] = useState(false)
    const [isParkingEmpty, setIsParkingEmpty] = useState(false);
    const [isBedroomCountChanged, setIsBedroomCountChanged] = useState(false);
    const [isBathroomCountChanged, setIsBathroomCountChanged] = useState(false);
    const [isBalconyChange, setIsBalconyChange] = useState(false)
    const [isFurnishingEmpty, setisFurnishingEmpty] = useState(false);
    const [isFloorEmpty, setisFloorEmpty] = useState(false);
    const [isTotalFloorEmpty, setisTotalFloorEmpty] = useState(false);
    const [Allowed, setAllowed] = useState(false);
    const [otherpet, setOtherpet] = useState(localStorage.getItem("otherpet") !== null ? localStorage.getItem("otherpet") : "")
    const [isotherpet, setIsOtherpet] = useState(false)
    const [addComment, setAddComment] = useState("")
    const [validationMessage, setValidationMessage] = useState('');
    const [shared, setShared] = useState([])
    const [activeStep, setActiveStep] = useState(0)
    const [errorValue, setErrorValue] = useState(false)
    const [errorBathroom, setErrorBathroom] = useState(false)
    const [errorBalcony, setErrorBalcony] = useState(false)
    const [errorProperty, setErrorProperty] = useState(false)
    const [errorFurnshing, setErrorFurnshing] = useState(false)
    const [errorParking, setErrorParking] = useState(false)
    const [errorNumberStorie, setErrorNumberStorie] = useState(false)
    const [erroFloor, setErrorFloor] = useState(false)
    const [errorTotal, setErrorTotal] = useState(false)

    useEffect(() => {
        getBasicData()
        // localStorage.getItem("furnishing")
    }, [])

    const getBasicData = async () => {
        await getMethodWithToken(url.selectList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                response?.data.map(function (item) {
                    if (item?.[1] == "furnished_type") {
                        setFurnishingType(item?.[0])
                    }
                    if (item?.[1] == "parking_type") {
                        setParkingType(item?.[0])
                    }
                    if (item?.[1] == "floor_type") {
                        setFloorNoType(item?.[0])
                    }
                    if (item?.[1] == "property_pets") {
                        setPets(item?.[0])
                    }
                    if (item?.[1] === "room_sharing") {
                        setShared(item?.[0])
                    }
                })

            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    useEffect(() => {
        getSteps()
    }, [])

    const getSteps = async () => {
        await getMethodWithToken(url.selectlist + param.id + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                const allDetail = response?.data?.detail;
                setName(allDetail?.stepTwo?.apartmentName)
                setBedroomCount(allDetail?.stepTwo?.bedroomCount)
                setBalconyCount(allDetail?.stepTwo?.balconyCount)
                if (param.uid === "14") {
                    setBathroomCount(allDetail?.stepTwo?.room_sharing?.name)
                } else {
                    setBathroomCount(allDetail?.stepTwo?.bathroomCount)
                }
                setPropertySize(allDetail?.stepTwo?.propertySize)
                setFurnishing(allDetail?.stepTwo?.furnishType?.name)
                setFurnishingId(allDetail?.stepTwo?.furnishType?.id)
                setParking(allDetail?.stepTwo?.parkingType?.name)
                setParkingId(allDetail?.stepTwo?.parkingType?.id)
                setStoriesName(allDetail?.stepTwo?.num_stories)
                setFloorNo(allDetail?.stepTwo?.floorNumber)
                setTotalFloor(allDetail?.stepTwo?.totalFloor)
                setAddComment(allDetail?.stepTwo?.Comments)
                const newIds = allDetail?.pets.map(value => value.id);
                setPetsid(prevState => [...prevState, ...newIds]);
                setActiveStep(response?.data?.detail?.stepsCompleted)
                setBathroomId(allDetail?.stepTwo?.room_sharing?.id)
            }
            else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    const handleNameChange = (event) => {
        const newName = event.target.value;
        setName(newName);
    };

    const handleAddBedroomChange = () => {
        if (param.uid == 14) {
            setBedroomCount(1)
        }
        else {
            setBedroomCount(prevCount => prevCount + 1);
            setIsBedroomCountChanged(true);
        }
    };

    const handleBedroomChange = (event) => {
        setBedroomCount(event?.target?.value)
        setIsBedroomCountChanged(true);
        setErrorValue(false)
    }

    const handleBathroomChange = (event, key) => {
        if (param?.uid == "14") {
            setBathroomCount(event?.target?.value)
            setBathroomId(key.key.substring(2))
        }
        else {
            setBathroomCount(event?.target?.value)
        }
        setIsBathroomCountChanged(true);
        setErrorBathroom(false)
    }

    const handleBalconyChange = (event) => {
        setBalconyCount(event?.target?.value)
        setIsBalconyChange(true)
        setErrorBalcony(false)
    }
    const handlePropertySize = (event) => {
        const newProSize = event.target.value
        setPropertySize(newProSize);
        setIsPropertySizeEmpty(newProSize?.trim() === '');
        setErrorProperty(false)
    }

    const handleFurnishChange = (event, key) => {
        const selectedFurnishing = event.target.value;
        let id = +key.key * 10;
        let finalid = id.toString()
        setFurnishingId(key.key.substring(2))
        setFurnishing(selectedFurnishing);
        setisFurnishingEmpty(false);
        setErrorFurnshing(false)

    };
    const handleParkingChanges = (event, key) => {
        setParking(event?.target?.value)
        setParkingId(+ key.key.substring(2))
        setIsParkingEmpty(false)
        setErrorParking(false)
    }
    const handleFloorChange = (event, key) => {
        if (param?.uid == 2) {
            setFloorNo(event?.target?.value)
            setisFloorEmpty(event?.target?.value?.trim() === '')
            // setFloorId(+ key?.props?.item_key)
            setErrorFloor(false)
        }
    }

    const handleTotoalFloorChange = (event, key) => {
        if (param?.uid == 2) {
            setTotalFloor(event?.target?.value)
            setisTotalFloorEmpty(event?.target?.value?.trim() === '')
            setErrorTotal(false)
        }

    }
    const handleStoriesChange = (e, key) => {
        setStoriesName(e?.target?.value)
        setIsNumberOfStore(e?.target?.value?.trim() === '')
        setErrorNumberStorie(false)
    }

    const handlePetProperty = (e) => {
        let arrpets = [...petsid]
        if (arrpets.includes(e?.id)) {
            let index = arrpets.findIndex(item => item == e.id);
            arrpets.splice(index, 1)
            setPetsid(arrpets)
        } else {
            arrpets.push(e?.id)
            setPetsid(arrpets)
        }
    }

    const handleinputpet = (e) => {
        setIsOtherpet(true)
        setOtherpet(e.target.value)
        localStorage.setItem("otherpet", e.target.value)
    }
    const handleClick = () => {
        setAllowed(!Allowed)
    }

    const handleCommentChange = (event) => {
        if (event?.target?.value.length >= 500) {
            setValidationMessage('Maximum 500 characters allowed');
        } else {
            setValidationMessage('');
        }
        setAddComment(event?.target?.value.trimStart())
    }
    console.log(typeof (param.uid), petsid)

    const handleSelectBasic = async () => {
        let pets = {
            "selectedpets": petsid
        }
        await postMethodWithToken(url.selectedpets + param.id + '/', pets).then((response) => {
            if (response.status === 200 || response.status === 201) {

            }
        })
        let body = {
            "bedroomCount": bedroomCount,
            "bathroomCount": bathroomCount,
            "balconyCount": balconyCount,
            "propertySize": +propertySize,
            "furnishType": +furnishingId,
            "parkingType": +parkingId,
            "floorNumber": floorNo,
            "totalFloor": totalFloor,
            "num_stories": storiesName,
            "Comments": addComment,
            "room_sharing": bathroomId
        }
        if (param.uid === "14") {
            delete body["bathroomCount"];
        } else {
            body["bathroomCount"] = bathroomCount;
        }

        let otherpetbody = {
            "pet": otherpet
        }
        if (isotherpet) {
            await postMethodWithToken(url.otherpets + param.id + '/', otherpetbody).then((response) => {
                if (response.status === 200 || response.status === 201) {

                }
            })
        }
        if (param.uid === "1" || param.uid === "3") {
            if (bedroomCount !== "" && bathroomCount !== "" && balconyCount !== "" && propertySize !== null && furnishingId !== null && parkingId !== null && storiesName !== null) {
                await postMethodWithToken(url.postSelectBasic + param.id + '/', body).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        navigate(`/Dashboard/FavoritePropertieList/property/${response.data.propertyId}`)
                    }
                })
            } else {
                toast.error("Please fill requried field")
                setErrorValue(true)
                setErrorBathroom(true)
                setErrorBalcony(true)
                setErrorProperty(true)
                setErrorFurnshing(true)
                setErrorParking(true)
                setErrorNumberStorie(true)
            }
        } else if (param.uid === "2") {
            if (bedroomCount !== "" && bathroomCount !== "" && balconyCount !== "" && propertySize !== null && furnishingId !== null && parkingId !== null && floorNo !== null && totalFloor !== null) {
                await postMethodWithToken(url.postSelectBasic + param.id + '/', body).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        navigate(`/Dashboard/FavoritePropertieList/property/${response.data.propertyId}`)

                    }
                })
            } else {
                toast.error("Please fill requried field")
                setErrorValue(true)
                setErrorBathroom(true)
                setErrorBalcony(true)
                setErrorProperty(true)
                setErrorFurnshing(true)
                setErrorParking(true)
                setErrorFloor(true)
                setErrorTotal(true)
            }
        } else if (param.uid === "14") {
            if (balconyCount !== "" && furnishingId !== null && parkingId !== null) {
                await postMethodWithToken(url.postSelectBasic + param.id + '/', body).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        navigate(`/Dashboard/FavoritePropertieList/property/${response.data.propertyId}`)
                    }
                })
            } else {
                toast.error("Please fill requried field")
                setErrorBalcony(true)
                setErrorFurnshing(true)
                setErrorParking(true)

            }
        }
        // const error = checkEmptyKeys();

        // if (error) {
        //     toast.error(error)
        // } else {
        //     await postMethodWithToken(url.postSelectBasic + param.id + '/', body).then((response) => {
        //         if (response.status === 200 || response.status === 201) {
        //             navigate(`/Dashboard/FavoritePropertieList/property/${response.data.propertyId}`)
        //         }
        //     })
        // }
    }

    const handleSaveExit = async () => {
        navigate(`/Dashboard/homePage`)
    }
    const handleOpenBack = () => {
        setOpenModal(true)
    }
    const handleCloseBack = () => {
        setOpenModal(false)
    }

    return (
        <>
            <ContainerSection>
                <Row style={{ margin: 0 }}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                        <FavoriteSelect activeStep={activeStep} value={1} />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={5} xxl={5}>
                        <div className="basic-select" >
                            <div className="basic-heading">Let 's start with basics</div>
                            <div className='basic-Name'>
                                {param.uid == 2 && (<FormInput required={true} name={'Building Name'} id={'name'} type={'text'} value={name} onChange={(e) => handleNameChange(e)} />)}
                                {param.uid == 1 || param.uid == 3 ? (<FormInput required={true} name={'Subdivision Name'} id={'name'} type={'text'} value={name} onChange={(e) => handleNameChange(e)} />) : <></>}
                            </div>
                            {param.uid != 14 &&
                                <div className="select-count" >
                                    <div className="count-heading" >Bedrooms</div>
                                    <div className="count-show" style={{ marginTop: "-13px", width: "70px" }}>
                                        {/* <FormInput required={true} id={'name'} className={classes.textField} type={'number'} value={bedroomCount} onChange={(e) => handleBedroomChange(e)} style={{ borderBottom: (errorValue && (bedroomCount === "" || bedroomCount === 0)) ? "2px solid red" : "0.1px solid black" }} /> */}
                                        <FormControl fullWidth className='formControl' sx={{ m: 1 }} variant="standard">
                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                type={'number'}
                                                value={bedroomCount}
                                                onChange={(e) => handleBedroomChange(e)}
                                                sx={{
                                                    '& .MuiInput-underline:before': {
                                                        borderBottomColor: (errorValue && (bedroomCount === "" || bedroomCount === 0)) ? "red" : "black",
                                                    },
                                                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                                        borderBottomColor: (errorValue && (bedroomCount === "" || bedroomCount === 0)) ? "red" : "black",
                                                    },
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: (errorValue && (bedroomCount === "" || bedroomCount === 0)) ? "red" : "black",
                                                    },
                                                }}
                                            />

                                        </FormControl>
                                    </div>
                                </div>
                            }
                            {(isBedroomCountChanged && bedroomCount === "") && (
                                <p style={{ color: 'red', marginTop: '8px', marginLeft: '15px' }}>This field is required.</p>
                            )}
                            {param.uid !== "14" ?
                                <div className="select-count" style={{ marginTop: "35px" }} >
                                    <div className="count-heading">Bathrooms</div>
                                    <div className="count-show" style={{ marginTop: "-13px", width: "70px" }}>
                                        {/* <FormInput required={true} id={'name'} type={'number'} value={bathroomCount} onChange={handleBathroomChange} style={{ borderBottom: (errorBathroom && (bathroomCount === "" || bathroomCount === 0)) ? "2px solid red" : "0.1px solid black" }} /> */}
                                        <FormControl fullWidth className='formControl' sx={{ m: 1 }} variant="standard">
                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                type={'number'}
                                                value={bathroomCount}
                                                onChange={(e) => handleBathroomChange(e)}
                                                sx={{
                                                    '& .MuiInput-underline:before': {
                                                        borderBottomColor: (errorBathroom && (bathroomCount === "" || bathroomCount === 0)) ? "red" : "black",
                                                    },
                                                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                                        borderBottomColor: (errorBathroom && (bathroomCount === "" || bathroomCount === 0)) ? "red" : "black",
                                                    },
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: (errorBathroom && (bathroomCount === "" || bathroomCount === 0)) ? "red" : "black",
                                                    },
                                                }}
                                            />

                                        </FormControl>
                                    </div>
                                </div>
                                :
                                <div className="select-count-drop" style={{ marginTop: "35px" }} >
                                    <div className="count-heading" >Bathroom</div>
                                    <div className="count-show" style={{ marginTop: "-13px" }}>
                                        <FormControl className='formControl' variant="standard" sx={{ m: 1, width: "150px" }}>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={bathroomCount}
                                                onChange={handleBathroomChange}
                                                style={{ borderBottom: (errorBathroom && (bathroomCount === "" || bathroomCount === 0)) ? "2px solid red" : "0.1px solid black" }}
                                            >
                                                {shared && shared.length > 0 && shared.map((data, key) => {
                                                    return (
                                                        <MenuItem key={data?.id} value={data?.name}>{data?.name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            }
                            {isBathroomCountChanged && bathroomCount === "" && (
                                <p style={{ color: 'red', marginTop: '8px', marginLeft: '15px' }}>This field is required.</p>
                            )}
                            {param.uid != 14 &&
                                <div className="select-count" style={{ marginTop: "35px" }}  >
                                    <div className="count-heading">Balcony</div>
                                    <div className="count-show" style={{ marginTop: "-13px", width: "70px" }}>
                                        {/* <FormInput required={true} id={'name'} type={'number'} value={balconyCount} onChange={(e) => handleBalconyChange(e)} style={{ borderBottom: (errorBalcony && (balconyCount === "" || balconyCount === 0)) ? "2px solid red" : "0.1px solid black" }} /> */}
                                        <FormControl fullWidth className='formControl' sx={{ m: 1 }} variant="standard">
                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                type={'number'}
                                                value={balconyCount}
                                                onChange={(e) => handleBalconyChange(e)}
                                                sx={{
                                                    '& .MuiInput-underline:before': {
                                                        borderBottomColor: (errorBalcony && (balconyCount === "" || balconyCount === 0)) ? "red" : "black",
                                                    },
                                                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                                        borderBottomColor: (errorBalcony && (balconyCount === "" || balconyCount === 0)) ? "red" : "black",
                                                    },
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: (errorBalcony && (balconyCount === "" || balconyCount === 0)) ? "red" : "black",
                                                    },
                                                }}
                                            />

                                        </FormControl>
                                    </div>
                                </div>}
                            {(isBalconyChange && balconyCount === "") && (
                                <p style={{ color: 'red', marginTop: '8px', marginLeft: '15px' }}>This field is required.</p>
                            )}
                            {console.log(propertySize)}
                            <Row>
                                {param.uid != 14 &&
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} style={{ marginRight: '1px' }} className='basic-Name'>
                                        <div className="count-show">
                                            {/* <FormInput required={true} name={'Property Size (in sqft)'} id={'name'} type={'number'} value={propertySize} onChange={(e) => handlePropertySize(e)} style={{ borderBottom: ((propertySize === "" || propertySize === null) && errorProperty) ? '2px solid red' : '1px solid #EAEAEA', marginTop: '8px' }} /> */}
                                            <FormControl fullWidth className='formControl' sx={{ m: 1 }} variant="standard">
                                                <TextField
                                                    fullWidth
                                                    name={'Property Size (in sqft)'}
                                                    label={'Property Size (in sqft)'}
                                                    id={'name'}
                                                    variant="standard"
                                                    type={'number'}
                                                    value={propertySize}
                                                    onChange={(e) => handlePropertySize(e)}
                                                    sx={{
                                                        '& .MuiInput-underline:before': {
                                                            borderBottomColor: ((propertySize === "" || propertySize === null) && errorProperty) ? "red" : "black",
                                                        },
                                                        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                                            borderBottomColor: ((propertySize === "" || propertySize === null) && errorProperty) ? "red" : "black",
                                                        },
                                                        '& .MuiInput-underline:after': {
                                                            borderBottomColor: ((propertySize === "" || propertySize === null) && errorProperty) ? "red" : "black",
                                                        },
                                                    }}
                                                />

                                            </FormControl>
                                            {isPropertySize && (
                                                <p style={{ color: 'red', marginLeft: '15px' }}>This field is required.</p>
                                            )}

                                        </div>
                                    </Col>
                                }
                                <Col xs={12} sm={12} md={6} lg={6} xl={5} xxl={6}>
                                    <FormControl className='formControl' variant="standard" sx={{ m: 1 }}>
                                        <InputLabel id="demo-simple-select-label" sx={{ color: ((furnishing === "" || furnishing === null) && errorFurnshing) && errorParking && 'red' }}>Furnishing Type</InputLabel>
                                        <Select
                                            required={true}
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={furnishing}
                                            label="Age"
                                            onChange={handleFurnishChange}

                                        >
                                            {furnishingType && furnishingType.length > 0 && furnishingType.map((data, key) => {
                                                const capitalizedItemName = data.name.charAt(0).toUpperCase() + data.name.slice(1);
                                                return (
                                                    <MenuItem value={data?.name} key={data?.id}>{capitalizedItemName}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    {isFurnishingEmpty && (
                                        <p style={{ color: 'red', marginLeft: '15px' }}>This field is required.</p>
                                    )}
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={5} xxl={6}>
                                    <FormControl className='formControl' variant="standard" sx={{ m: 1 }} style={{ marginTop: '8px' }}>
                                        <InputLabel id="demo-simple-select-label" sx={{ color: (parking === "" || parking === null) && errorParking && 'red' }} >Parking Type</InputLabel>
                                        <Select
                                            required={true}
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            label="Parking Type"
                                            onChange={(event, key) => handleParkingChanges(event, key)}
                                            value={parking}
                                        >
                                            {parkingType && parkingType.length > 0 && parkingType.map((item, index) => {
                                                const capitalizedItemName = item.name.charAt(0).toUpperCase() + item.name.slice(1);
                                                return (
                                                    <MenuItem value={item?.name} key={item?.id}>{capitalizedItemName}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    {isParkingEmpty && (
                                        <p style={{ color: 'red', marginLeft: '15px' }}>This field is required.</p>
                                    )}
                                </Col>
                                {param.uid == 2 && (
                                    <Col xs={12} sm={12} md={6} lg={6} xl={5} xxl={6}>
                                        <div className="count-show">
                                            {/* <FormInput required={true} name={'Floor No.'} id={'name'} type={'number'} value={floorNo} onChange={(e) => handleFloorChange(e)} style={{ borderBottom: ((floorNo === "" || floorNo === null) && erroFloor) ? '2px solid red' : '1px solid #EAEAEA', marginTop: '15px' }} /> */}
                                            <FormControl fullWidth className='formControl' sx={{ m: 1 }} variant="standard">
                                                <TextField
                                                    fullWidth
                                                    name={'Floor No.'}
                                                    label={'Floor No.'}
                                                    id={'name'}
                                                    variant="standard"
                                                    type={'number'}
                                                    value={floorNo}
                                                    onChange={(e) => handleFloorChange(e)}
                                                    sx={{
                                                        '& .MuiInput-underline:before': {
                                                            borderBottomColor: ((floorNo === "" || floorNo === null) && erroFloor) ? "red" : "black",
                                                        },
                                                        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                                            borderBottomColor: ((floorNo === "" || floorNo === null) && erroFloor) ? "red" : "black",
                                                        },
                                                        '& .MuiInput-underline:after': {
                                                            borderBottomColor: ((floorNo === "" || floorNo === null) && erroFloor) ? "red" : "black",
                                                        },
                                                    }}
                                                />

                                            </FormControl>
                                            {isFloorEmpty && (
                                                <p style={{ color: 'red', marginLeft: '15px' }}>This field is required.</p>
                                            )}
                                        </div>
                                    </Col>
                                )}
                                <Col xs={12} sm={12} md={6} lg={6} xl={5} xxl={6}>
                                    {param.uid == 2 && (
                                        <div className="count-show">
                                            {/* <FormInput required={true} name={'Total Floors'} id={'name'} type={'number'} value={totalFloor} onChange={(e) => handleTotoalFloorChange(e)} style={{ borderBottom: ((totalFloor === "" || totalFloor === null) && errorTotal) ? '2px solid red' : '1px solid #EAEAEA', marginTop: '15px' }} /> */}
                                            <FormControl fullWidth className='formControl' sx={{ m: 1 }} variant="standard">
                                                <TextField
                                                    fullWidth
                                                    name={'Total Floors'}
                                                    label={'Total Floors'}
                                                    id={'name'}
                                                    variant="standard"
                                                    type={'number'}
                                                    value={totalFloor}
                                                    onChange={(e) => handleTotoalFloorChange(e)}
                                                    sx={{
                                                        '& .MuiInput-underline:before': {
                                                            borderBottomColor: ((totalFloor === "" || totalFloor === null) && errorTotal) ? "red" : "black",
                                                        },
                                                        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                                            borderBottomColor: ((totalFloor === "" || totalFloor === null) && errorTotal) ? "red" : "black",
                                                        },
                                                        '& .MuiInput-underline:after': {
                                                            borderBottomColor: ((totalFloor === "" || totalFloor === null) && errorTotal) ? "red" : "black",
                                                        },
                                                    }}
                                                />

                                            </FormControl>
                                            {isTotalFloorEmpty && (
                                                <p style={{ color: 'red', marginLeft: '15px' }}>This field is required.</p>
                                            )}
                                        </div>
                                    )}
                                    {param.uid == 1 || param.uid == 3 ? (
                                        <div className="count-show">
                                            {/* <FormInput required={true} name={'Number of Stories'} id={'name'} type={'number'} value={storiesName} onChange={(e) => handleStoriesChange(e)} style={{ borderBottom: ((storiesName === "" || storiesName === null) && errorNumberStorie) ? '2px solid red' : '1px solid #EAEAEA', marginTop: '15px' }} /> */}
                                            <FormControl fullWidth className='formControl' sx={{ m: 1 }} variant="standard">
                                                <TextField
                                                    fullWidth
                                                    name={'Number of Stories'}
                                                    label={'Number of Stories'}
                                                    id={'name'}
                                                    variant="standard"
                                                    type={'number'}
                                                    value={storiesName}
                                                    onChange={(e) => handleStoriesChange(e)}
                                                    sx={{
                                                        '& .MuiInput-underline:before': {
                                                            borderBottomColor: ((storiesName === "" || storiesName === null) && errorNumberStorie) ? "red" : "black",
                                                        },
                                                        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                                            borderBottomColor: ((storiesName === "" || storiesName === null) && errorNumberStorie) ? "red" : "black",
                                                        },
                                                        '& .MuiInput-underline:after': {
                                                            borderBottomColor: ((storiesName === "" || storiesName === null) && errorNumberStorie) ? "red" : "black",
                                                        },
                                                    }}
                                                />

                                            </FormControl>
                                            {isNumberOfStore && (
                                                <p style={{ color: 'red', marginLeft: '15px' }}>This field is required.</p>
                                            )}
                                        </div>
                                    ) : <></>

                                    }
                                </Col>
                                {/* <Col lg={12}>
                                    <div>
                                        <div className='pets'>
                                            <p>Pets</p>
                                        </div>
                                        <div>
                                            <div>
                                                <button className='pets_button' onClick={handleClick} style={{ border: Allowed ? "2px solid #0075CA" : '1px solid #919CA5', color: Allowed ? "#0075CA" : '#919CA5' }}>Not Allowed</button>
                                                {pets && pets.length > 0 && pets.map((item, index) => {

                                                    return (
                                                        <button className='pets_button' onClick={() => handlePetProperty(item)} style={{ border: petsid.includes(item?.id) ? "2px solid #0075CA" : '1px solid #919CA5', color: petsid.includes(item?.id) ? "#0075CA" : '#919CA5' }} >{item.name}</button>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} className='otherpet'>
                                    <TextField id="standard-basic" label="Add Other Pets" variant="standard" style={{ marginTop: '10px', fontSize: '20px' }} value={otherpet} onChange={handleinputpet} />
                                </Col> */}
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div>
                                        <div className='pets'>
                                            <p>Pets</p>
                                        </div>
                                        <div>
                                            <div>
                                                <button className='pets_button' onClick={handleClick} style={{ border: Allowed ? "2px solid #0075CA" : '1px solid #919CA5', color: Allowed ? "#0075CA" : '#919CA5' }}>Not Allowed</button>
                                                {pets && pets.length > 0 && pets.map((item, index) => {
                                                    return (
                                                        <button className='pets_button' onClick={() => handlePetProperty(item)} style={{ border: petsid.includes(item?.id) ? "2px solid #0075CA" : '1px solid #919CA5', color: petsid.includes(item?.id) ? "#0075CA" : '#919CA5' }} >{item.name}</button>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} className='otherpet'>
                                    <TextField id="standard-basic" label="Add Other Pets" variant="standard" style={{ fontSize: '18px' }} value={otherpet} onChange={handleinputpet} />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}  >
                                    <textarea className="pt-2" placeholder="Add Comments..." value={addComment} onChange={(e) => handleCommentChange(e)} style={{
                                        width: '100%', marginTop: "30px", paddingLeft: "15px", fontSize: "16px", outline: 'none',
                                        border: '1px solid black',
                                        ':focus': {
                                            border: 'none',
                                        }
                                    }} rows="5" maxlength="500" />
                                    {validationMessage && (
                                        <p style={{ color: 'red' }}>{validationMessage}</p>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-between" style={{ margin: "20px 0 20px 30px", alignItems: "center" }} >
                            {/* <div style={{ fontSize: "24px", cursor: "pointer" }} onClick={handleOpenBack} >Back</div> */}
                            <div>
                                <ContinueButton className="back-list" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={handleOpenBack} fontSize={"20px"} fontWeight={"400"} />
                            </div>
                            <div className="d-flex justify-content-end">
                                {/* <div style={{ fontSize: "20px", cursor: "pointer", paddingRight: "70px", display: "flex", alignItems: "center" }} onClick={() => handleSaveExit()}>Save & Exit</div> */}
                                <div style={{ marginRight: "50px", display: "flex", alignItems: "center" }}>
                                    <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                                <div style={{ marginRight: "50px" }}>
                                    <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => handleSelectBasic()} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </ContainerSection>
            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
                <div className="d-flex justify-content-center">
                    <div className="back-heading">Are you sure you want to back?</div>
                </div>
                <div className="d-flex justify-content-center" >
                    <div style={{ marginTop: "10px" }}>All the changes you will made will be discarded</div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="d-flex" style={{ marginTop: "50px" }}>
                        <div className="stay-heading" onClick={handleCloseBack}>Stay on this page</div>
                        <div>
                            <ContinueButton text="Go back" color={'#fff'} onClick={() => navigate(-1)} width={"210px"} borderRadius={"4px"} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default FavoriteSelectBasic