import React from "react";
import Rout from "./routes/Rout";
import './styles/common.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
function App() {
  return (
    <React.Fragment>
      <ToastContainer />
      <Rout />
    </React.Fragment>
  );
}

export default App;
