import axios from "axios";
import env from '../locale/env.json'
import {getToken} from "./common";

export const getMethod = async (url) => {
    try {
        let data = await axios({
            method: "GET",
            url: env.baseUrl+url,
            headers: {
                Accept: "application/json",
            },
        });
        return data;
    } catch (error) {
        return error.response;
    }
}

export const getMethodWithToken = async (url) => {
    let token = getToken()
    try {
        let data = await axios({
            method: "GET",
            url: env.baseUrl+url,
            headers: {
                Accept: "application/json",
                Authorization: "token " + token,
            },
        });
        return data;
    } catch (error) {
        return error.response;
    }
}

export const postMethod = async (url, body) => {
    try {
        const apiUrl = env.baseUrl + url;

        let data = await axios({
            method: "POST",
            url: apiUrl,
            headers: {
                Accept: "application/json",
            },
            data: body,
        });


        return data;
    } catch (error) {
        return error.response;
    }
}
export const postMethodWithToken = async (url, body) => {
    try {
        const apiUrl = env.baseUrl + url;

        let data = await axios({
            method: "POST",
            url: apiUrl,
            headers: {
                Accept: "application/json",
                Authorization: "token " + getToken(),
            },
            data: body,
        });
        return data;
    } 
    // catch (error) {
    //     return error.response;
    // }

    catch (error) {
        console.error("Error:", error);
        if (error.response) {
            console.error("Response data:", error.response.data);
            // console.error("Response status:", error.response.status);
    
            // Handle specific status codes
            if (error.response.status === 401) {
                console.error("Unauthorized. Check your authentication credentials.");
            }
        } else if (error.request) {
            console.error("No response received:", error.request);
        } else {
            console.error("Error setting up the request:", error.message);

        }
        return error.response;  // or handle the error as needed
    }
    
}

export const postMethodWithTokenWithBody = async (url) => {
    try {
        const apiUrl = env.baseUrl + url;

        let data = await axios({
            method: "POST",
            url: apiUrl,
            headers: {
                Accept: "application/json",
                Authorization: "token " + getToken(),
            },
        });
        return data;
    } 
    // catch (error) {
    //     return error.response;
    // }

    catch (error) {
        console.error("Error:", error);
        if (error.response) {
            console.error("Response data:", error.response.data);
            // console.error("Response status:", error.response.status);
    
            // Handle specific status codes
            if (error.response.status === 401) {
                console.error("Unauthorized. Check your authentication credentials.");
            }
        } else if (error.request) {
            console.error("No response received:", error.request);
        } else {
            console.error("Error setting up the request:", error.message);

        }
        return error.response;  // or handle the error as needed
    }
    
}


export const putMethod = async (url, body) => {
    try {
        let data = await axios({
            method: "PUT",
            url: "",
            headers: {
                Accept: "application/json",
            },
            data: body,
        });
        return data;
    } catch (error) {
        return error.response;
    }
}

export const putMethodWithToken = async (url, body) => {
    try {
        const apiUrl = env.baseUrl + url;
        let data = await axios({
            method: "PUT",
            url: apiUrl,
            headers: {
                Accept: "application/json",
                Authorization: "token " + getToken(),
            },
            data: body,
        });
        return data;
    } catch (error) {
        return error.response;
    }
}

export const deleteMethod = async (url, body) => {
    try {
        let data = await axios({
            method: "DELETE",
            url: "",
            headers: {
                Accept: "application/json",
            },
            data: body,
        });
        return data;
    } catch (error) {
        return error.response;
    }
}