import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import "./PaymentCard.scss"
// import AddCard from "../../../assets/images/add-card.svg";
import PaymentPastTransaction from "../PaymentPastTransactions/PaymentPastTransactions"
import { getMethodWithToken } from '../../../utils/services/apis';
import url from '../../../utils/services/urls.json';
import { navigateLogin } from '../../../utils/services/common';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

const PaymentCardContainer = styled.div`
font-family: Proxima_nova_reg;
margin-top: 80px;
`


const PaymentCard = () => {
    useEffect(() => {
        getPaymentList()
    }, [])
    const [paymentList, setPaymentList] = useState([])
    const navigate = useNavigate()
    const getPaymentList = async () => {
        await getMethodWithToken(url.getPastTransaction).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setPaymentList(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    return (
        <PaymentCardContainer>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={5} xxl={5}>
                    <div className="payment-card-seaction">
                        <div className="payment-heading">Payment Method</div>
                        <hr />
                        <div className="payment-card-method">You haven’t saved a method yet</div>
                        {/* <div className="payment-save-card">
                        <div>
                            <img src={AddCard} alt="AddCard" style={{ cursor: "pointer" }} />
                        </div>
                        <div className="payment-save-card-heading">Save a payment method</div>
                    </div> */}
                    </div>
                </Col>
                {paymentList.length > 0 && (
                    <Col xs={12} sm={12} md={12} lg={12} xl={5} xxl={5}>
                        <PaymentPastTransaction />
                    </Col>
                )}

            </Row>
        </PaymentCardContainer>
    )
}

export default PaymentCard
