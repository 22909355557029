import React, { useEffect, useState } from 'react';
import "./PaymentPastTransactions.scss";
import FormControl from '@mui/material/FormControl';
import { MenuItem, Select } from '@mui/material';
import ArrowRight from "../../../assets/images/arrow-right.svg";
import ListImage from "../../../assets/images/Ellipse 29.png";
import AmountAdd from "../../../assets/images/amount-add.svg"
import url from "../../../utils/services/urls.json"
import { getMethodWithToken } from '../../../utils/services/apis';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { navigateLogin } from '../../../utils/services/common';

const PaymentPastTransactions = () => {
    const navigate = useNavigate()
    const [sortValue, setSortValue] = useState("")
    const [paymentList, setPaymentList] = useState([])

    useEffect(() => {
        getPaymentList()
    }, [])

    const getPaymentList = async () => {
        await getMethodWithToken(url.getPastTransaction).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setPaymentList(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }

    const handleSortChange = (event) => {
        setSortValue(event.target.value)
    }

    return (
        <div className="payment-transaction-container">
            <div className="d-flex justify-content-between" style={{ borderBottom: "1px solid #dedddc" }}>
                <div className="past-transaction-heading">Past Transactions</div>
                <div className="d-flex" style={{ paddingTop: "25px", paddingRight: "15px" }}>
                    <div>Sort by:</div>
                    <div>
                        <FormControl>
                            <Select
                                value={sortValue}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={handleSortChange}
                                sx={{
                                    boxShadow: "none",
                                    marginTop: "-15px",
                                    marginLeft: "50px",
                                    background: "white",
                                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                    {
                                        border: 0,
                                    },
                                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                        border: 0,
                                    },
                                }}
                            >
                                <MenuItem value="" className='filter_items'>All</MenuItem>
                                <MenuItem value={10} className='filter_items'>Ten</MenuItem>
                                <MenuItem value={20} className='filter_items'>Twenty</MenuItem>
                                <MenuItem value={30} className='filter_items'>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
            <div className="transaction-list">
                {paymentList && paymentList.length > 0 && paymentList.map((item, index) => {
                    return (
                        <>
                            <div className="d-flex justify-content-between pt-3" >
                                <div className="list-date">{moment(item?.created_at).format("DD MMMM YYYY")}</div>
                                <img src={ArrowRight} alt="ArrowRight" style={{ width: "10px" }} />
                            </div>
                            <div className="d-flex justify-content-between list-payment">
                                <div className="d-flex">
                                    <div><img src={item?.profilePic} alt="ListImage" style={{ width: "50px", height: "50px", borderRadius: '50%' }} /></div>
                                    <div className="listing-heading">{item?.user_name?.length > 0 && getUpparCaseName(item?.user_name)}</div>
                                </div>
                                <div>
                                    <div className="d-flex justify-content-end">
                                        {/* <img src={AmountAdd} alt="" /> */}
                                        <div className="amount-heading">{item?.price}</div>
                                    </div>
                                    {item?.transaction_status !== "COMPLETED" &&
                                        <div>
                                            <div className="show-status">Payment Pending</div>
                                        </div>
                                    }
                                </div>
                                {/* {item?.status !== "COMPLETED" &&  */}



                                {/* } */}
                            </div>

                        </>
                    )
                })}
            </div>


        </div>
    )
}

export default PaymentPastTransactions
