export const setToken = (token) => {
    localStorage.setItem("token", token);
};

export const getToken = () => {
    return localStorage.getItem("token") || null;
};


export const setParam = (param) => {
    localStorage.setItem("param", param);
};
export const getParam = () => {
    return localStorage.getItem("param") || null;
};
export const setUserId = (UserId) => {
    return localStorage.setItem("UserId", UserId)
}
export const getUserId = () => {
    return localStorage.getItem("UserId")
}
export const setUserEmail = (userEmail) => {
    return localStorage.setItem("userEmail", userEmail)
}
export const getUserEmail = () => {
    return localStorage.getItem("userEmail")
}

export const navigateLogin = () => {
    return localStorage.removeItem("token") || null;
}; 
