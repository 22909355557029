import React from 'react'
import './ContinueButton.scss'

const ContinueButton = (props) => {
  const defaultValue = {
    color: '#fff',
    backgroundColor: 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)',
    border: 'none',
    height: '50px',
    width: '250px',
    maxWidth: '',
    text: 'Click',
    borderRadius: '',
    fontWeight: '',
    fontSize: '',
    marginTop: ""
  }
  return (
    <>
      <button className={`buttonTwo ms-1  d-flex justify-content-center align-items-center ${props.className}`}
        style={{
          color: props.color ? props.color : defaultValue.color,
          background: props.backgroundColor ? props.backgroundColor : defaultValue.backgroundColor,
          border: props.border ? props.border : defaultValue.border,
          width: props.width ? props.width : defaultValue.width,
          height: props.height ? props.height : defaultValue.height,
          cursor: 'pointer',
          maxWidth: props.maxWidth ? props.maxWidth : defaultValue.maxWidth,
          borderRadius: props.borderRadius ? props.borderRadius : defaultValue.borderRadius,
          fontWeight: props.fontWeight ? props.fontWeight : defaultValue.fontWeight,
          fontSize: props.fontSize ? props.fontSize : defaultValue.fontSize,
          marginTop: props.marginTop ? props.marginTop : defaultValue.marginTop,
        }}
        disabled={props.disabled}
        onClick={props.onClick}
      >{props.text ? props.text : defaultValue.text}</button>
    </>
  )
}

export default ContinueButton
