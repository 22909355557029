import React, { useState } from 'react'
import FormControl from '@mui/material/FormControl';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import './FormInput.scss';

const FormInput = (props) => {

  const {
    value,
    onChange,
    style, // Provide a default value for style
  } = props;
  const [localValue, setLocalValue] = useState("");

  const defaultValue = {
    id: "Text",
    name: 'Text',
    type: "text",
    required: false,
    value: value,
    error: false,
    helperText: 'This is a required field',
    style: style || {},
    onChange: (e) => {
      setLocalValue(e.target.value);
      if (onChange) {
        onChange(e);
      }
    },
  }
  return (
    <>
      <FormControl className='formControl' sx={{ m: 1 }} variant="standard">
        {/* <InputLabel htmlFor={props.id?props.id:defaultValue.id}>{props.name?props.name:defaultValue.name}</InputLabel> */}
        <TextField
          label={props.name ? props.name : ""}
          placeholder={props.placeholder}
          id={props.id ? props.id : defaultValue.id}
          type={props.type ? props.type : ""}
          // required={props.required?props.required:defaultValue.required}
          disabled={props.disabled}
          width={props.width ? props.width : ""}
          value={props.value ? props.value : defaultValue.value}
          onChange={props.onChange ? props.onChange : defaultValue.onChange}
          // inputComponent={props.inputComponent}
          error={props.error ? props.error : defaultValue.error}
          helperText={props.error && (props.helperText ? props.helperText : defaultValue.helperText)}
          variant="standard"
          InputProps={{
            style: {
              ...style, // Apply the style prop
            },
            inputComponent: props.inputComponent,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton style={{ cursor: "auto" }}>
                  {props.showIcon ? props.showIcon : <img src={props.icon} alt="" />}
                  {/* <p style={{ fontSize: "16px", marginTop: "10px" }} >{props.showText ? props.showText : ""}</p> */}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </>
  )
}

export default FormInput
