import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Toastmessage = (props) => {
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={props.setToastOpen} autoHideDuration={5000} onClose={props.handleClose} anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}>
        <Alert onClose={props.handleClose} severity={props?.dataMsg?.type} sx={{ width: '100%' }}>
          {props.dataMsg.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export default Toastmessage
