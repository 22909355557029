import React, { useEffect, useState } from 'react'
import Navbar from '../../../../Components/commonComponents/navbar/Navbar'
import Sidebar from '../../../../Components/commonComponents/sidebar/Sidebar'
import ArrowBack from "../../../../assets/images/listingManager/arrow_back.svg";
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import PhoneIcon from "../../../../assets/images/listingManager/call.svg"
import Mail from "../../../../assets/images/listingManager/mail.svg"
import "./ListNameDetail.scss";
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getMethodWithToken, postMethodWithToken } from '../../../../utils/services/apis';
import url from "../../../../utils/services/urls.json"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Bedroom from "../../../../assets/images/listingManager/bedroom.svg"
import Bathroom from "../../../../assets/images/listingManager/bathroom.svg"
import PropertySize from "../../../../assets/images/listingManager/square_foot.svg"
import Parking from "../../../../assets/images/listingManager/parking.svg"
import Clock from "../../../../assets/images/listingManager/clock.svg"
import Home from "../../../../assets/images/listingManager/home.svg"
import Furnishing from "../../../../assets/images/listingManager/furnishing.svg"
import Reply from "../../../../assets/images/listingManager/reply.svg"
import userImage from "../../../../assets/images/listingManager/listing-manager-user.svg"
import LikeIcon from "../../../../assets/images/listingManager/like-property.svg"
import FilledLikeIcon from "../../../../assets/images/listingManager/filledLikedImage.svg"
import FlagIcon from "../../../../assets/images/listingManager/flag.svg"
import ArrowForward from "../../../../assets/images/listingManager/Arrow-property.svg"
import ContinueButton from "../../../../Components/Buttons/ContinueButton/ContinueButton"
import close from "../../../../assets/images/searchproperty/close.svg";
import back from "../../../../assets/images/searchproperty/back_month.svg";
import fordward from "../../../../assets/images/searchproperty/ford_month.svg";
import calender from "../../../../assets/icons/calendar_month.svg"
import DatePicker from 'react-datepicker';
import Modal from "react-modal";
import FormInput from '../../../../Components/Inputs/FormInput/FormInput';
import slots from '../../../../assets/images/searchproperty/slots.svg';
import ArrowRight from '../../../../assets/images/searchproperty/ArrowRight.svg';
import enGB from 'date-fns/locale/en-GB';
import { toast } from 'react-toastify';
import { navigateLogin } from '../../../../utils/services/common';
import { addDays, addMonths, addYears } from 'date-fns'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
    {
        imgPath: 'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
    },
    {
        imgPath: 'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
    },
    {
        imgPath: 'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
    },
    {
        imgPath: 'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
    },
];

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    rootValue: {
        maxWidth: 500,
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: 210,
        display: 'block',
        maxWidth: 400,
        overflow: 'hidden',
        width: '100%',
    },
}));

const ListNameDetail = () => {
    const navigate = useNavigate();
    const moment = require('moment-timezone');
    const params = useParams();
    const today = new Date()
    const theme = useTheme();
    const classes = useStyles();
    const [userAllList, setUserAllList] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [selectedRentDate, setSelectedRentDate] = useState();
    const [selectedRentEndDate, setSelectedRentEndDate] = useState();
    const [rentalDuration, setRentalDuration] = useState("")
    const [rentPropertyId, setRentPropertyId] = useState('')
    const [rentPrice, setRentPrice] = useState("")
    const [openRentModal, setOpenRentModal] = useState(false)
    const maxSteps = images.length;

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
            // width: "27vw",
            // height: "680px",
            transform: screenSize.width <= 800 ? 'translate(-50%, -50%)' : 'translate(-42%, -50%)',
            width: screenSize.width <= 800 ? "" : screenSize.width <= 1100 ? "" : "40vw",
            height: screenSize.width <= 800 ? "68vh" : "68vh",
            padding: '0px',
        },
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    useEffect(() => {
        getAllUserList()
    }, [])

    const getAllUserList = async () => {
        await getMethodWithToken(url.userList + params?.id + "/").then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setUserAllList(response?.data)
            } else {

            }
        })
    }

    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }

    const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
        const monthYear = date.toLocaleDateString(undefined, {
            month: 'long',
            year: 'numeric',
        });

        const days = ['Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat', 'Sun'];

        return (
            <div className="custom-header">
                <div className='date_month'>
                    <p className='monthyear'>{monthYear}</p>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <img src={back} onClick={decreaseMonth} alt='back' style={{ cursor: "pointer" }} />
                        <img src={fordward} onClick={increaseMonth} alt='fordward' style={{ cursor: "pointer" }} />
                    </div>

                </div>
                <div style={{ fontSize: '20px', justifyContent: 'space-evenly', display: 'flex', fontFamily: 'Proxima_nove_reg', paddingBottom: '20px' }}>
                    {days.map((day, index) => (
                        <span key={index}>{day}</span>
                    ))}
                </div>
            </div>
        );
    };

    const handleMainCalenderDate = (date) => {
        if (!selectedRentDate) {
            setSelectedRentDate(date);
            if (rentalDuration === "Long term") {
                const newEndDate = new Date(date);
                newEndDate.setFullYear(newEndDate.getFullYear() + 1);
                setSelectedRentEndDate(newEndDate);
            }
        } else if (!selectedRentEndDate || date > selectedRentEndDate) {
            setSelectedRentEndDate(date);
        } else {
            setSelectedRentDate(date);
            if (rentalDuration === "Long term") {
                const newEndDate = new Date(date);
                newEndDate.setFullYear(newEndDate.getFullYear() + 1);
                setSelectedRentEndDate(newEndDate);
            }
        }
    };

    const handleRentProperty = async (id, allValues) => {
        let api = `${url.GetRentProperty}?rentProperty_id=${id}`;
        await getMethodWithToken(api).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setSelectedRentDate(new Date(response.data.start_date));
                setSelectedRentEndDate(new Date(response.data.end_date));
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            } else {
                setSelectedRentDate('');
                setSelectedRentEndDate('');
            }
        });
        setRentPropertyId(id);
        setRentPrice((Number(allValues?.subscription_details?.price) + Number(allValues?.subscription_details?.price) * 5 / 100) + Number(allValues?.subscription_details?.deposit_amount))
        setRentalDuration(allValues?.subscription_details?.masterSubscrption)
        setOpenRentModal(true);
    };

    const dates = addDays(selectedRentDate, 30);
    const midDate = addMonths(selectedRentDate, 10)
    const longDate = addYears(selectedRentDate, 1)

    const handleCloseClick = () => {
        setOpenRentModal(false)
    }
    const handleCloseRentClick = async (start_date, end_date) => {
        let body = {
            property_id: +rentPropertyId,
            start_date: start_date,
            end_date: end_date,
            is_rent: true
        }
        await postMethodWithToken(url.RentPropertyDates, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                navigate(`/Dashboard/list/manager/PaymentDetail/${rentPropertyId}/manager`, {
                    state: {
                        rentPrice: rentPrice
                    }
                })
                setOpenRentModal(false)
            } else {
                toast.error(response?.data?.success)
            }
        })
    }

    const handleChatWithManager = async (propertyMasterId, bedroom, bathroom, propertyType, price, address, per_month, propertySize) => {
        let perMonth = per_month === true ? "per_month" : "per_day";
        const receiverPropertyDetail = {
            // Id: mangerid,
            PropertyMasterId: propertyMasterId,
            bedroom: bedroom,
            bathroom: bathroom,
            propertyType: propertyType,
            price: price,
            address: address,
            per_month: perMonth,
            propertySize: propertySize

        }
        let body = {
            // receiver: mangerid,
            propertyMaster: propertyMasterId,
            content: JSON.stringify(receiverPropertyDetail)
        }

        await postMethodWithToken(url.sendMessage, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                localStorage.setItem('receiverPropertyDetail', response?.data?.content)
                navigate("/Dashboard/Chats")
            }
        })
    }

    const handleSearchDetail = (propertyId) => {
        navigate(`/Dashboard/SearchList/SearchDetail/${propertyId}`)
    }

    return (
        <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
            <Navbar />
            <div className="d-flex">
                <div className="Sidebar_control side-resposne">
                    <Sidebar />
                </div>
                <div className="Sidebar_control side-value-list">
                    <div style={{ marginTop: '80px', marginBottom: '5.5vh' }}>
                        <div className="list-profile">
                            <div className="d-none d-lg-flex">
                                <div className="arrow-box" onClick={() => navigate(-1)}>
                                    <img src={ArrowBack} alt="" />
                                </div>
                            </div>
                            <Row>
                                <Col xs={12} sm={12} md={9} lg={7} xl={7} xxl={5}>
                                    <div className="list-profile-section mt-2">
                                        <div className="d-flex d-lg-none">
                                            <div className="arrow-box" onClick={() => navigate(-1)}>
                                                <img src={ArrowBack} alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <img src={userAllList?.profilePic?.file} alt='' className='tenantProfilePic'></img>
                                        </div>
                                        <div className='tenantName'>{userAllList?.name}</div>
                                        <div className="d-flex justify-content-center" style={{ marginTop: "13px", marginBottom: '13px' }}>
                                            <div>
                                                <img src={PhoneIcon} alt="" />
                                            </div>

                                            <div className='tenantCompanyName' style={{ paddingLeft: '10px' }}>{userAllList?.contact_number}</div>

                                            <div style={{ marginLeft: "10px" }}>
                                                <img src={Mail} alt="" />
                                            </div>
                                            <div className='tenantCompanyName' style={{ paddingLeft: '10px' }}>{userAllList?.email}</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={7} >
                                    <div className="user-profile-list-container">
                                        {userAllList?.property_listing && userAllList?.property_listing.length > 0 && userAllList?.property_listing.map((data, index) => {
                                            return (
                                                <div className="user-profile-list">
                                                    <Row>
                                                        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                                                            <div className={classes.rootValue}>
                                                                <AutoPlaySwipeableViews
                                                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                                    index={activeStep}
                                                                    onChangeIndex={handleStepChange}
                                                                    enableMouseEvents
                                                                >
                                                                    {data?.property_images && data?.property_images.length > 0 && data?.property_images.map((step, index) => {
                                                                        return (
                                                                            <>
                                                                                <div>
                                                                                    <img className={classes.img} src={step?.media?.file} alt={step.label} />
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </AutoPlaySwipeableViews>
                                                                <MobileStepper
                                                                    position="static"
                                                                    variant="text"
                                                                    nextButton={
                                                                        <Button size="small" style={{ color: "white" }} onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                                                            {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                                        </Button>
                                                                    }
                                                                    backButton={
                                                                        <Button size="small" style={{ color: "white" }} onClick={handleBack} disabled={activeStep === 0}>
                                                                            {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                                        </Button>
                                                                    }
                                                                />
                                                                <div style={{ display: "flex", paddingTop: '36%', justifyContent: 'center', alignContent: 'flex-start', gap: '10px' }}>
                                                                    <div>
                                                                        <div className="search-price" style={{ fontSize: '18px', fontWeight: '600' }}>${data?.subscription_details?.price} <span style={{ fontSize: "14px", color: "#505050", fontWeight: '400' }} >/{data?.subscription_details?.per_day ? "day" : "month"}</span></div>
                                                                        <div className="rent-heading" style={{ paddingTop: '10px' }}>Rent</div>
                                                                    </div>
                                                                    <div><hr style={{ border: "1px solid black", height: "50px", marginLeft: "10px" }} /></div>
                                                                    <div>
                                                                        <div className="deposit-price" style={{ fontSize: '18px', fontWeight: '600' }}>${data?.subscription_details?.price}</div>
                                                                        <div className="rent-heading" style={{ paddingLeft: "7px" }}>Deposit</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                                            <div className="listing-card-detail" >
                                                                <div className="d-flex justify-content-between">
                                                                    {/* <div className="d-flex" >
                                                                    <div>
                                                                        <img src={data?.Property_owner?.[0]?.profilePic} alt="" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
                                                                    </div>
                                                                    <div >
                                                                        <div className="d-flex" style={{ paddingLeft: "7px" }}>
                                                                            <div className="user-heading">{data?.Property_owner?.[0]?.name}</div>
                                                                            <div className="user-dot">.</div>
                                                                            <div className="property-owner">Property Owner</div>
                                                                        </div>
                                                                        <div style={{ color: "#707070", paddingLeft: "7px", fontSize: "15px" }}>{data?.Property_owner?.[0]?.orgnization}</div>
                                                                    </div>

                                                                </div> */}
                                                                    <div>
                                                                        {/* <div className="d-flex">
                                                                        <div><img src={arrayPropertyId.includes(data?.propertyMaster_id) ? FilledLikeIcon : LikeIcon} alt="" style={{ width: "20px", height: "20px", cursor: "pointer" }} onClick={() => handleAddFavouriteProperty(data?.propertyMaster_id)} /></div>
                                                                        <div><img src={FlagIcon} alt="" style={{ width: "20px", height: "20px", marginLeft: "10px" }} /></div>
                                                                    </div> */}
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex" style={{ marginTop: "7px" }} >
                                                                    <div style={{ fontSize: "14px" }}><span style={{ fontWeight: 600 }}>{data?.bedroomCount} Bedroom Flat</span> in <span style={{ fontWeight: 600 }}> {data?.apartmentName}</span> for <span style={{ fontWeight: 600 }}>Rent</span> in <span style={{ fontWeight: 600 }}>{data?.location?.locality} </span></div>
                                                                    <div style={{ marginLeft: "10px" }}><img src={Reply} alt="" /></div>
                                                                </div>
                                                                <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px" }}>{data?.apartmentName} {data?.location?.locality}</div>
                                                                <div className='d-flex' style={{ marginTop: "10px", borderBottom: "1px solid #B7B7B7", paddingBottom: "5px", gap: '10px' }}>
                                                                    <div className='d-flex'>
                                                                        <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                        <div className="categroy-heading">Bedroom</div>
                                                                        <div className="category-value">{data?.bathroomCount}</div>
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                        <div  ><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                        <div className="categroy-heading">Bathroom</div>
                                                                        <div className="category-value">{data?.bedroomCount}</div>
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                        <div ><img src={Parking} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                        <div className="categroy-heading">Parking</div>
                                                                        <div className="category-value" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{data?.parkingType?.name?.length > 0 && getUpparCaseName(data?.parkingType?.name)} </div>
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                        <div ><img src={PropertySize} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                        <div className="category-value" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{data?.propertySize} sqft</div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <Row >
                                                                        <Col lg={3} style={{ borderRight: "1px solid #B7B7B7", height: "100px" }}>
                                                                            <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                                <div><img src={Clock} alt="" style={{ width: "15px", height: "15px" }} /></div>
                                                                                <div className="categroy-heading">Available from</div>
                                                                            </div>
                                                                            <div className="select-value-heading">{data?.subscription_details?.propertyAvailfrom !== null && moment.tz(data?.subscription_details?.propertyAvailfrom?.[0], data?.subscription_details?.propertyAvailfrom?.[0]).format("YYYY-MM-DD")}</div>
                                                                        </Col>
                                                                        <Col lg={4} style={{ borderRight: "1px solid #B7B7B7", height: "100px", marginLeft: "10px" }}>
                                                                            <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                                <div><img src={Home} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                                <div className="categroy-heading">Property Type</div>
                                                                            </div>
                                                                            <div className="select-value-heading">{data?.propertyType?.name}</div>
                                                                        </Col>
                                                                        <Col lg={4} style={{ marginLeft: "10px" }}>
                                                                            <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                                <div><img src={Furnishing} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                                <div className="categroy-heading">Furnishing</div>
                                                                            </div>
                                                                            <div className="select-value-heading">{data?.furnishType?.name?.length > 0 && getUpparCaseName(data?.furnishType?.name)}</div>
                                                                        </Col>
                                                                    </Row>
                                                                    <div className="d-flex justify-content-end" style={{ marginTop: "30px" }}>
                                                                        {/* <div>
                                                                        <ContinueButton text={'Rent Property'} color={'#fff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={'150px'} borderRadius={"4px"} fontWeight={400} onClick={() => handleRentProperty(data?.propertyMaster_id, data)} />
                                                                    </div>
                                                                    <div style={{ marginLeft: "7px" }}>
                                                                        <ContinueButton text={'Chat with Manager'} color={'#2F86D1'} backgroundColor={'#fff'} border={'2px solid #2F86D1'} borderRadius={"4px"} fontWeight={400} width={"160px"} onClick={() => handleChatWithManager(data?.propertyMaster_id, data?.bedroomCount, data?.bathroomCount, data?.propertyType?.name, data?.subscription_details?.price, data?.location?.locality, data?.subscription_details?.per_month, data?.propertySize)} />
                                                                    </div> */}
                                                                        <div class="d-flex align-items-center">
                                                                            <div className="d-flex align-items-center" style={{ marginLeft: "20px", cursor: "pointer" }}>
                                                                                <div className="view-heading" onClick={() => handleSearchDetail(data?.propertyMaster_id, data?.Property_owner)}>View Details</div>
                                                                                <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <Modal style={customStyles} isOpen={openRentModal} overlayClassName="myoverlay" >
                                                                        <div className="modal-container" >
                                                                            <div style={{ borderBottom: " 1px solid #EAEAEA", marginBottom: "10px" }} >
                                                                                <div className="modal-main-heading  calender_header" > <p style={{ fontSize: '24px', fontWeight: '600', fontFamily: 'Proxima_nova_reg', marginBottom: "0px" }}>Select date range</p>
                                                                                    <img src={close} onClick={handleCloseClick} style={{ cursor: 'pointer' }} alt='close' /></div>
                                                                                <div style={{ display: "flex" }}>
                                                                                    <div className='inputField_calender'>
                                                                                        <FormInput
                                                                                            type='text' name={'Start Date'} id={'name'}
                                                                                            value={selectedRentDate ? selectedRentDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }) : ''}
                                                                                            readOnly />
                                                                                    </div>
                                                                                    <img src={ArrowRight} alt='right' />
                                                                                    <div className='inputField_calender' style={{ position: "relative" }}>
                                                                                        <FormInput
                                                                                            type='text' name={'End Date'} id={'name'}
                                                                                            value={selectedRentEndDate ? selectedRentEndDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }) : ''}
                                                                                            readOnly={false} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='main_calender'>
                                                                                <DatePicker selected={selectedRentDate || selectedRentEndDate} onChange={handleMainCalenderDate} inline dateFormat="MMMM yyyy" dayClassName={(date) => {
                                                                                    let classes = '';
                                                                                    if (selectedRentDate && selectedRentEndDate) {
                                                                                        if (date.getTime() === new Date(selectedRentDate).getTime()) {
                                                                                            classes += 'start-date';
                                                                                        } else if (date.getTime() === new Date(selectedRentEndDate).getTime()) {
                                                                                            classes += 'end-date';
                                                                                        } else if (date > new Date(selectedRentDate) && date < new Date(selectedRentEndDate)) {
                                                                                            classes += 'range-selected';
                                                                                        }
                                                                                    }
                                                                                    return classes;
                                                                                }} renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                                                    <CustomHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />)}
                                                                                    locale={enGB}
                                                                                    formatWeekDayShort={(locale, dayOfWeek) => {
                                                                                        const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
                                                                                        return days[dayOfWeek];
                                                                                    }}
                                                                                    minDate={today}
                                                                                    maxDate={rentalDuration === "short term" ? dates : rentalDuration === "mid term" ? midDate : longDate}
                                                                                />
                                                                            </div>
                                                                            <div style={{ display: "flex", justifyContent: "space-between", padding: "20px 32px 20px 32px ", borderTop: "1px solid #EAEAEA", alignItems: "baseline" }}>
                                                                                <div className='calender_footer'>
                                                                                    <img src={slots} alt='Like' />
                                                                                    <p className='footer_para'>Available Slots</p>
                                                                                </div>
                                                                                <div>
                                                                                    <button className={selectedRentEndDate ? 'continue_button' : 'Disablecontine'} onClick={() => handleCloseRentClick(selectedRentDate, selectedRentEndDate)}>Continue to checkout</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Modal>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>

                                            )
                                        })}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ListNameDetail
